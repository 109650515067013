import React, { useState, useEffect, useRef } from 'react';
import './MessagesModal.scss';
import { useTranslation, Trans } from 'react-i18next';
import { CloseIcon } from '../common/CloseIcon';
import Conversation from './Conversation';
import ChatUserAvatar from './ChatUserAvatar';
import { getCookie } from '../utils/utils';
import MiniMenuIcon from '../FeedComments/MiniMenuIcon';
import TagManager from 'react-gtm-module';
type ConversationData = {
  id: string;
  otherUser: {
    name: string;
    avatar: string;
  };
  lastMessage: {
    text: string;
    timestamp: Date;
    firstMessage: boolean;
  };
  buildingInfo: {
    name: string;
    address: string;
  };
  unread: number;
  isEnded?: boolean;
  endedByUserId?: string;
  endedAt?: Date;
  otherUserHasSentMessage: boolean;
};

type MessagesModalProps = {
  isVisible: boolean;
  toggleMessagesModal: () => void;
  conversations: ConversationData[];
  selectedConversation: string | null;
  setSelectedConversation: (id: string | null) => void;
  refreshConversations: () => void;
};

const MessagesModal: React.FC<MessagesModalProps> = ({
  isVisible,
  toggleMessagesModal,
  conversations,
  selectedConversation,
  setSelectedConversation,
  refreshConversations,
}) => {
  const { t, i18n } = useTranslation();
  // const [activeTab, setActiveTab] = useState<'all' | 'support'>('all');
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const headerMenuRef = useRef<HTMLDivElement>(null);
  const [showEndChatConfirmation, setShowEndChatConfirmation] = useState(false);
  const [isEndingChat, setIsEndingChat] = useState(false);

  // Add a click outside handler for the header menu
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (headerMenuRef.current && !headerMenuRef.current.contains(event.target as Node)) {
        setShowHeaderMenu(false);
      }
    }

    if (showHeaderMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showHeaderMenu]);

  // Filter conversations based on active tab
  const filteredConversations = conversations.filter(conv => {
    return true;
    /*     if (activeTab === 'all') return true;
        if (activeTab === 'support' && conv.buildingInfo.name.includes('Support')) return true;
        return false; */
  });

  // Format timestamp to readable format
  const formatTimestamp = (timestamp: Date) => {
    // Check if timestamp is valid
    if (!timestamp || isNaN(timestamp.getTime())) {
      console.warn('Invalid timestamp in formatTimestamp:', timestamp);
      return '';
    }

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const messageDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());

    // Get user's locale from browser
    const userLocale = i18n.language || 'en-GB';

    // Check if the message is from today
    if (messageDate.getTime() === today.getTime()) {
      return new Intl.DateTimeFormat(userLocale, {
        hour: '2-digit',
        minute: '2-digit'
      }).format(timestamp);
    }

    // Check if the message is from within this week
    const dayDiff = Math.floor((today.getTime() - messageDate.getTime()) / (1000 * 60 * 60 * 24));
    if (dayDiff < 7) {
      return new Intl.DateTimeFormat(userLocale, {
        weekday: 'short'
      }).format(timestamp);
    }

    // For older messages, show date
    return new Intl.DateTimeFormat(userLocale, {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit'
    }).format(timestamp);
  };

  // Fetch messages for a conversation
  const fetchMessages = async (conversationId: string, showLoading = true): Promise<void> => {
    // Only show loading indicator on initial load or manual refresh, not during polling
    if (showLoading) {
      setIsLoadingMessages(true);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/conversations/${conversationId}/messages`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }

      const data = await response.json();

      // Transform the data to match your component's expected format
      const formattedMessages = data.map((msg: any) => {
        // Validate timestamp before creating Date object
        let timestamp;
        try {
          timestamp = new Date(msg.timestamp);
          // Check if the date is valid
          if (isNaN(timestamp.getTime())) {
            console.warn('Invalid timestamp in message:', msg);
            timestamp = new Date(); // Fallback to current date
          }
        } catch (error) {
          console.error('Error parsing timestamp:', error, msg);
          timestamp = new Date(); // Fallback to current date
        }

        return {
          id: msg.id || `temp-${Date.now()}-${Math.random()}`,
          sender: msg.sender,
          text: msg.text || '',
          timestamp
        };
      });

      // Use the functional form of setState to access the current messages
      setMessages(currentMessages => {
        // Compare the new messages with current messages
        const currentIds = new Set(currentMessages.map(msg => msg.id));
        const hasNewMessages = formattedMessages.some(msg => !currentIds.has(msg.id));

        // Only update if there are new messages
        if (hasNewMessages || currentMessages.length !== formattedMessages.length) {
          return formattedMessages;
        }
        return currentMessages; // Return the current messages to avoid re-render
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
      // Handle error (show notification, etc.)
    } finally {
      // Only update loading state if we set it to true initially
      if (showLoading) {
        setIsLoadingMessages(false);
      }
    }
  };

  // Then update the polling effect to pass false for the showLoading parameter
  useEffect(() => {
    if (selectedConversation) {
      const fetchData = async () => {
        // Initial fetch with loading indicator
        await fetchMessages(selectedConversation, true);
        refreshConversations();
      }
      fetchData();

      // Set up polling without loading indicator
      const interval = setInterval(() => {
        fetchMessages(selectedConversation, false);
      }, 5000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  // Send a new message
  const sendMessage = async () => {
    if (!newMessage.trim() || !selectedConversation) return;

    try {
      // Show some loading state if needed
      // setIsSending(true);

      // Send to your API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${selectedConversation}/messages`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify({ text: newMessage }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      // Parse the response
      const messageData = await response.json();

      // Add message to the local state
      setMessages([...messages, {
        id: messageData.id,
        sender: messageData.sender,
        text: messageData.text,
        timestamp: new Date(messageData.timestamp)
      }]);

      // Clear the input
      setNewMessage('');

      // After sending, refresh the conversations list to update last message
      setTimeout(() => {
        refreshConversations();
      }, 300);

      TagManager.dataLayer({
        dataLayer: {
          event: 'chat_message_sent',
          category: 'User Interaction',
          action: 'Click',
        },
      });
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error (show notification, etc.)
    } finally {
      // setIsSending(false);
    }
  };

  // Function to handle ending the chat
  const handleEndChat = () => {
    setShowHeaderMenu(false);
    setShowEndChatConfirmation(true);
  };

  // Function to confirm ending the chat
  const confirmEndChat = async () => {
    if (!selectedConversation) return;

    setIsEndingChat(true);
    try {
      // Call API to end the chat
      const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations/${selectedConversation}/end`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      });

      if (!response.ok) {
        throw new Error('Failed to end conversation');
      }

      // Go back to conversation list and refresh conversations
      setSelectedConversation(null);
      refreshConversations();

    } catch (error) {
      console.error('Error ending chat:', error);
      // Could add error handling UI here
    } finally {
      setIsEndingChat(false);
      setShowEndChatConfirmation(false);
    }
  };

  // Function to cancel ending the chat
  const cancelEndChat = () => {
    setShowEndChatConfirmation(false);
  };

  return (
    <div className="messages-modal-background">
      <div
        className="messages-modal"
        key={selectedConversation ? 'selected-conversation-open' : 'no-selected-conversation-open'}
      >
        <div className="messages-modal__header">
          <div className="messages-modal__header-menu-container" ref={headerMenuRef}>
            {selectedConversation && !conversations.find(c => c.id === selectedConversation)?.isEnded && (
              <button
                className="messages-modal__header-left"
                onClick={() => setShowHeaderMenu(!showHeaderMenu)}
              >
                <MiniMenuIcon color="#000" size={16} />
              </button>
            )}
            {showHeaderMenu && (
              <div className="messages-modal__header-menu">
                <button
                  className="messages-modal__header-menu-item messages-modal__header-menu-item--warning"
                  onClick={handleEndChat}
                >
                  <Trans i18nKey="messages.endChat">End chat</Trans>
                </button>
              </div>
            )}
          </div>

          <h2>
            <Trans i18nKey="messages.title" />
          </h2>
          {/*           <div className="messages-modal__search">
            <div className="messages-modal__search-icon">
              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ display: 'block', fill: 'none', height: '16px', width: '16px', stroke: 'currentcolor', strokeWidth: 3, overflow: 'visible' }}>
                <g fill="none">
                  <path d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"></path>
                </g>
              </svg>
            </div>
            <div className="messages-modal__filter">
              <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ display: 'block', height: '16px', width: '16px', fill: 'currentcolor' }}>
                <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
              </svg>
            </div>
          </div> */}
          <button className="messages-modal__close" onClick={toggleMessagesModal}>
            <CloseIcon size={20} />
          </button>
        </div>

        {/* <div className="messages-modal__tabs"> */}
        {/*           <div className={`messages-modal__tab`}>
            <Trans i18nKey="messages.tabs.inbox" />
          </div> */}
        {/*           <button 
            className={`messages-modal__tab ${activeTab === 'all' ? 'messages-modal__tab--active' : ''}`}
            onClick={() => setActiveTab('all')}
          >
            <Trans i18nKey="messages.tabs.all">All</Trans>
          </button> */}
        {/*           <button 
            className={`messages-modal__tab ${activeTab === 'traveling' ? 'messages-modal__tab--active' : ''}`}
            onClick={() => setActiveTab('traveling')}
          >
            <Trans i18nKey="messages.tabs.traveling">Traveling</Trans>
          </button> */}
        {/*           <button 
            className={`messages-modal__tab ${activeTab === 'support' ? 'messages-modal__tab--active' : ''}`}
            onClick={() => setActiveTab('support')}
          >
            <Trans i18nKey="messages.tabs.support">Support</Trans>
          </button> */}
        {/* </div> */}

        <div className="messages-modal__content">
          {!selectedConversation ? (
            // Conversation list view
            <div className="messages-modal__conversations">
              {filteredConversations.length === 0 ? (
                <div className="messages-modal__empty">
                  <Trans i18nKey="messages.empty">
                    No messages yet
                  </Trans>
                </div>
              ) : (
                filteredConversations.map(conversation => (
                  <div
                    key={conversation.id}
                    className={`messages-modal__conversation ${conversation.unread > 0 ? 'messages-modal__conversation--unread' : ''} ${conversation.isEnded ? 'messages-modal__conversation--ended' : ''}`}
                    onClick={() => {
                      setSelectedConversation(conversation.id);
                      fetchMessages(conversation.id);
                    }}
                  >
                    <div className="messages-modal__conversation-avatar">
                      {conversation.otherUser.avatar ? (
                        <img src={conversation.otherUser.avatar} alt={
                          conversation.otherUserHasSentMessage ? conversation.otherUser.name : t("messages.anonymousUser")
                        } />
                      ) : (
                        <ChatUserAvatar name={conversation.otherUserHasSentMessage ? conversation.otherUser.name : t("messages.anonymousUser")} />
                      )}
                    </div>
                    <div className="messages-modal__conversation-content">
                      <div className="messages-modal__conversation-header">
                        <h3>{conversation.otherUserHasSentMessage ? conversation.otherUser.name : <Trans i18nKey="messages.anonymousUser" />}</h3>
                        <span className="messages-modal__conversation-time">
                          {formatTimestamp(conversation.lastMessage.timestamp)}
                        </span>
                      </div>
                      <div className="messages-modal__conversation-content-bottom">
                        <div className="messages-modal__conversation-content-bottom-left">
                          <div className="messages-modal__conversation-building">
                            {conversation.buildingInfo.address}
                          </div>
                          <div className="messages-modal__conversation-preview">
                            {conversation.isEnded ? (
                              <span className="messages-modal__conversation-ended-tag">
                                <Trans i18nKey="messages.chatEnded">Chat ended</Trans>
                              </span>
                            ) : conversation.lastMessage.firstMessage ? (
                              <Trans i18nKey="messages.firstMessage" />
                            ) : (
                              conversation.lastMessage.text
                            )}
                          </div>
                        </div>
                        <div className="messages-modal__conversation-content-icon-open">
                          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={
                            {
                              display: 'block', fill: 'none', height: '16px', width: '16px', stroke: 'currentcolor', strokeWidth: 4, overflow: 'visible',
                              transform: 'rotate(180deg)'
                            }
                          }>
                            <path d="m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            // Individual conversation view
            <Conversation
              conversationId={selectedConversation}
              conversation={conversations.find(c => c.id === selectedConversation)!}
              messages={messages}
              isLoading={isLoadingMessages}
              newMessage={newMessage}
              otherUserHasSentMessage={conversations.find(c => c.id === selectedConversation)?.otherUserHasSentMessage}
              setNewMessage={setNewMessage}
              sendMessage={sendMessage}
              goBack={() => setSelectedConversation(null)}
            />
          )}
        </div>

        {showEndChatConfirmation && (
          <div className="end-chat-confirmation-overlay">
            <div className="end-chat-confirmation">
              <div className="end-chat-confirmation__header">
                <h3>
                  <Trans i18nKey="messages.endChatConfirmTitle">
                    End chat
                  </Trans>
                </h3>
                <button
                  className="end-chat-confirmation__close"
                  onClick={cancelEndChat}
                >
                  <CloseIcon size={16} />
                </button>
              </div>
              <div className="end-chat-confirmation__content">
                <p>
                  <Trans i18nKey="messages.endChatConfirmText">
                    Are you sure you want to end this conversation? Once ended, neither you nor the other person will be able to send messages in this chat again.
                  </Trans>
                </p>
              </div>
              <div className="end-chat-confirmation__actions">
                <button
                  className="end-chat-confirmation__button end-chat-confirmation__button--cancel"
                  onClick={cancelEndChat}
                  disabled={isEndingChat}
                >
                  <Trans i18nKey="messages.cancel">
                    Cancel
                  </Trans>
                </button>
                <button
                  className="end-chat-confirmation__button end-chat-confirmation__button--confirm"
                  onClick={confirmEndChat}
                  disabled={isEndingChat}
                >
                  {isEndingChat ? (
                    <span className="end-chat-confirmation__loading-dots">
                      <Trans i18nKey="messages.ending">
                        Ending
                      </Trans>
                    </span>
                  ) : (
                    <Trans i18nKey="messages.confirm">
                      End chat
                    </Trans>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesModal; 