import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import DOMPurify from 'dompurify';
import Linkify from 'linkify-react';

import { BuildingWithConvertedAddresses, Comment, OffMarketWillingnessEvent as OffMarketWillingnessEventType } from '../../utils/types';
import { getCookie } from '../../utils/utils';
import SendIcon from './SendIcon';
import BackIcon from '../Icons/BackIcon';
import { getTimeAgo } from '../Report.helpers';
import CameraIcon from './CameraIcon';
import TrashIcon from './TrashIcon';
import ImageModal from '../ImageModal/ImageModal';
import ZoomIcon from './ZoomIcon';
import LoadingSpinner from '../../common/LoadingSpinner';
import MiniMenuIcon from '../../FeedComments/MiniMenuIcon';
import DownArrowAlternativeIcon from '../../FeedComments/DownArrowAlternativeIcon';
import '../Report.scss';
import './Comments.scss';
import { Trans, useTranslation } from 'react-i18next';
import { OffMarketWillingnessEvent } from '../../FeedComments/OffMarketWillingnessEvent';

type Props = {
  selectedBuilding: BuildingWithConvertedAddresses;
  isLoggedIn: boolean;
  showImageModal: string | null;
  setLoginModalVisible: (visible: boolean) => void;
  refreshFeed: () => void;
  setShowImageModal: (imageUrl: string | null) => void;
  isLoadingComments: boolean;
  comments: Comment[];
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
  fetchComments: (isVoteRefresh: boolean) => Promise<void>;
  setNotificationsSubscriptionsToggle: (toggle: boolean) => void;
};

const Comments = (props: Props) => {
  const {
    selectedBuilding,
    isLoggedIn,
    showImageModal,
    setLoginModalVisible,
    refreshFeed,
    setShowImageModal,
    isLoadingComments,
    comments,
    setComments,
    fetchComments,
    setNotificationsSubscriptionsToggle,
  } = props;
  const { t } = useTranslation();
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>('');
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>('');
  const commentFormRef = useRef<HTMLDivElement | null>(null);
  const [showPhotoButtons, setShowPhotoButtons] = useState<boolean>(false);
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [showCommentControlsMenu, setShowCommentControlsMenu] = React.useState<
    string | null
  >(null);
  const commentControlsRemoveCommentRef =
    React.useRef<HTMLButtonElement | null>(null);
  const commentControlsMenuButtonRef = React.useRef<HTMLButtonElement | null>(
    null,
  );

  const [addCommentFormHeightStyle, setAddCommentFormHeightStyle] = useState<
    string | null
  >('');
  const [photoPreviewBottomStyle, setPhotoPreviewBottomStyle] = useState<
    string | null
  >('');
  const [isSubmittingComment, setIsSubmittingComment] =
    useState<boolean>(false);

  const reportRef = useRef<HTMLDivElement | null>(null);
  const photoButtonsRef = useRef<HTMLDivElement>(null);
  const showPhotoButtonsButtonRef = useRef<HTMLButtonElement>(null);

  const handleSubmitComment = () => {
    setIsSubmittingComment(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'submit_comment_button_clicked',
        category: 'User Interaction',
        action: 'Click',
        label: 'Submit Comment Button',
      },
    });
    // Create form data for multipart submission
    const formData = new FormData();

    formData.append('comment', commentText);
    formData.append('selected_building', JSON.stringify(selectedBuilding));

    // If an image file has been selected, append it
    if (selectedImageFile) {
      formData.append('image', selectedImageFile);
    }

    // Fire off the request
    fetch(`${process.env.REACT_APP_API_URL}/comment`, {
      method: 'POST',
      credentials: 'include',
      // Omit "Content-Type": "application/json" because fetch
      // will auto-set the correct boundary for multipart/form-data
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        // Handle success, e.g. updating the comments list:
        setComments((prevComments) => [
          ...prevComments,
          {
            _id: data.id,
            content: data.content,
            timestamp: data.timestamp,
            commenterOrderNumber: data.commenterOrderNumber,
            isAdminComment: data.isAdminComment,
            address: {
              country: selectedBuilding?.addresses?.[0]?.country || '',
              region: selectedBuilding?.addresses?.[0]?.region || '',
              city: selectedBuilding?.addresses?.[0]?.city || '',
              postcode: selectedBuilding?.addresses?.[0]?.postcode || '',
              street: selectedBuilding?.addresses?.[0]?.street || '',
              houseNumber: selectedBuilding?.addresses?.[0]?.houseNumber || '',
              coordinates: {
                lat: selectedBuilding?.addresses?.[0]?.coordinates?.lat || 0,
                lng: selectedBuilding?.addresses?.[0]?.coordinates?.lng || 0,
              },
            },
            buildingId: selectedBuilding.id,
            images: data.images,
            isUserOwnComment: data.isUserOwnComment,
          },
        ]);

        // Reset the comment input & the selected image
        setCommentText('');
        setSelectedImageFile(null);
        setImagePreviewUrl('');
        setIsAddingComment(false);
        setImageDimensions(null);
        setNotificationsSubscriptionsToggle(data.is_subscribed);
        // Scroll to the bottom of the report component
        if (reportRef.current) {
          reportRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
          window.scrollTo({
            top: reportRef.current.offsetTop,
            behavior: 'smooth',
          });
        }

        // Possibly refresh the feed or do any other updates
        refreshFeed();
        setIsSubmittingComment(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsSubmittingComment(false);
      });
  };

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (isIOS) {
      document.body.classList.add('ios');
    } else {
      document.body.classList.remove('ios');
    }
  }, []);

  useLayoutEffect(() => {
    if (isAddingComment && commentFormRef.current) {
      commentFormRef.current.scrollIntoView();
    }
  }, [isAddingComment]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImageFile(file);
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
      setShowPhotoButtons(false);

      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.height / img.width;
        const containerWidth = window.innerWidth; // Assuming full width
        const calculatedHeight = containerWidth * aspectRatio;
        setImageDimensions({ width: containerWidth, height: calculatedHeight });
      };
      img.src = previewUrl;
    }
  };

  const removeSelectedImage = () => {
    setSelectedImageFile(null);
    setImagePreviewUrl('');
    setImageDimensions(null);
  };

  const handleToggleShowPhotoButtons = () => {
    setShowPhotoButtons(!showPhotoButtons);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        photoButtonsRef.current &&
        !photoButtonsRef.current.contains(event.target as Node) &&
        showPhotoButtonsButtonRef.current &&
        !showPhotoButtonsButtonRef.current.contains(event.target as Node)
      ) {
        setShowPhotoButtons(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [photoButtonsRef, showPhotoButtonsButtonRef]);

  useEffect(() => {
    const updateStyle = () => {
      const width = window.innerWidth;
      if (imagePreviewUrl && width > 768) {
        setAddCommentFormHeightStyle('800px');
        setPhotoPreviewBottomStyle('90px');
      } else {
        setAddCommentFormHeightStyle(null);
        setPhotoPreviewBottomStyle(null);
      }
    };

    updateStyle(); // Set initial style
    window.addEventListener('resize', updateStyle);

    return () => {
      window.removeEventListener('resize', updateStyle);
    };
  }, [imagePreviewUrl]);

  useEffect(() => {
    // TODO: Fix this. The screen is not scrolling to the bottom when comments are updated.
    // Scroll to the bottom of the report component after comments are updated
    if (reportRef.current) {
      const { offsetTop } = reportRef.current;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  }, [comments]); // Ensure this effect runs when comments change

  const handleUpvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'up' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to upvote');
      }
      // Optionally parse response if you want returned data:
      // const data = await response.json();

      fetchComments(true);
      // Refresh feed or update local state so UI matches the server
      refreshFeed();
    } catch (error) {
      console.error('Error upvoting:', error);
    }
  };

  const handleDownvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'down' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to downvote');
      }
      // Optionally parse response if you want returned data:
      // const data = await response.json();

      fetchComments(true);
      // Refresh feed or update local state so UI matches the server
      refreshFeed();
    } catch (error) {
      console.error('Error downvoting:', error);
    }
  };

  const handleRemoveUpvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'none' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to remove upvote');
      }
      fetchComments(true);
      refreshFeed();
    } catch (error) {
      console.error('Error removing upvote:', error);
    }
  };

  const handleRemoveDownvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'none' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to remove downvote');
      }
      fetchComments(true);
      refreshFeed();
    } catch (error) {
      console.error('Error removing downvote:', error);
    }
  };

  const handleRemoveComment = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to remove comment');
      }
      fetchComments(true);
      refreshFeed();
      setShowCommentControlsMenu(null);
    } catch (error) {
      console.error('Error removing comment:', error);
    }
  };

  return (
    <div className="comments">
      {isLoadingComments ? (
        <div className="comments__loading-spinner">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {comments.length > 0 && !isAddingComment && (
            <div className="comments__comments-list">
              {comments.map((comment) => {
                const commenterOrderNumber = comment.commenterOrderNumber;
                const isOffMarketWillingnessEvent =
                  comment.feed_event_type === 'OFF_MARKET_WILLINGNESS';

                return (
                  isOffMarketWillingnessEvent ? (
                    <OffMarketWillingnessEvent
                      key={comment._id}
                      comment={comment as unknown as OffMarketWillingnessEventType}
                    />
                  ) : (
                    <div
                      className={`comments__comment comments__comment--${commenterOrderNumber <= 10 ? commenterOrderNumber : '11'
                        }${comment.isAdminComment
                          ? ' comments__comment--jaakko-comment'
                          : ''
                        }`}
                      key={comment._id}
                      onClick={() => {
                        if (showCommentControlsMenu) {
                          setShowCommentControlsMenu(null);
                        }
                      }}
                    >
                      <div className="comments__comment__header-and-content-wrapper">
                        <div className="comments__comment__commenter-order-number-and-timestamp-wrapper">
                          <div className="comments__comment__commenter-order-number">
                            {comment.isAdminComment ? (
                              <div className="comments__comment__commenter-order-number__jaakko-comment">
                                <img
                                  src="/assets/naama5.png?v=1"
                                  alt="Jaakko"
                                  height="35px"
                                  className="comments__comment__commenter-order-number__jaakko-comment__jaakko-head"
                                  loading="lazy"
                                />
                                <span> Jaakko</span>
                              </div>
                            ) : comment.commenterOrderNumber === 1 ? (
                              t('building.comments.commenterOrderNumberFirst')
                            ) : (
                              `${comment.commenterOrderNumber}.`
                            )}
                          </div>
                          <div className="comments__comment__timestamp">
                            {getTimeAgo(comment.timestamp)}
                          </div>
                        </div>
                        <div className="comments__comment__content">
                          {comment.images && comment.images.length > 0 && (
                            <div
                              className={`comments__comment__content__images${comment.content
                                ? ' comments__comment__content__images--with-text'
                                : ''
                                }`}
                            >
                              {comment.images.map((image, idx) => (
                                <div
                                  key={idx}
                                  className="comments__comment__content__images__image-wrapper"
                                >
                                  <img
                                    src={image.url}
                                    alt="Comment"
                                    loading="lazy"
                                    onClick={() => setShowImageModal(image.url)}
                                  />
                                  <div className="comments__comment__content__images__image-wrapper__expand-button-wrapper">
                                    <button
                                      className="comments__comment__content__images__image-wrapper__expand-button-wrapper__button"
                                      onClick={() => setShowImageModal(image.url)}
                                    >
                                      <ZoomIcon />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          <Linkify
                            options={{
                              attributes: {
                                rel: 'noopener noreferrer',
                                target: '_blank',
                              },
                            }}
                          >
                            {DOMPurify.sanitize(comment.content)}
                          </Linkify>
                        </div>
                      </div>
                      <div className="comments__comment__footer">
                        <div className="comments__comment__footer__vote-controls">
                          <button
                            className="comments__comment__footer__vote-controls__upvote"
                            disabled={comment.userVote === 'up'}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (showCommentControlsMenu) {
                                setShowCommentControlsMenu(null);
                              }
                              if (!isLoggedIn) {
                                setLoginModalVisible(true);
                              } else if (comment.userVote === 'down') {
                                handleRemoveUpvote(comment._id);
                              } else {
                                handleUpvote(comment._id);
                              }
                            }}
                          >
                            <DownArrowAlternativeIcon />
                          </button>
                          <div className="comments__comment__footer__vote-controls__vote-count">
                            {(comment.upvoteCount || 0) -
                              (comment.downvoteCount || 0)}
                          </div>
                          <button
                            className="comments__comment__footer__vote-controls__downvote"
                            disabled={comment.userVote === 'down'}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (showCommentControlsMenu) {
                                setShowCommentControlsMenu(null);
                              }
                              if (!isLoggedIn) {
                                setLoginModalVisible(true);
                              } else if (comment.userVote === 'up') {
                                handleRemoveDownvote(comment._id);
                              } else {
                                handleDownvote(comment._id);
                              }
                            }}
                          >
                            <DownArrowAlternativeIcon />
                          </button>
                        </div>
                        {showCommentControlsMenu === comment._id && (
                          <div className="comments__comment__footer__menu-wrapper">
                            <div
                              className="comments__comment__footer__menu"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <button
                                disabled={isLoggedIn && !comment.isUserOwnComment}
                                className={`comments__comment__footer__menu__remove-comment-button${!isLoggedIn
                                  ? ' comments__comment__footer__menu__remove-comment-button--disabled'
                                  : ''
                                  }`}
                                ref={commentControlsRemoveCommentRef}
                                onClick={() => {
                                  if (!isLoggedIn) {
                                    setLoginModalVisible(true);
                                  } else if (comment.isUserOwnComment) {
                                    handleRemoveComment(comment._id);
                                  }
                                }}
                              >
                                <TrashIcon color="#202125" />
                              </button>
                            </div>
                          </div>
                        )}
                        {!comment.isAdminComment && (
                          <button
                            className="comments__comment__footer__menu__show-menu-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (showCommentControlsMenu === comment._id) {
                                setShowCommentControlsMenu(null);
                              } else {
                                setShowCommentControlsMenu(comment._id);
                              }
                            }}
                            ref={commentControlsMenuButtonRef}
                          >
                            <MiniMenuIcon color="#FFF" size={16} />
                          </button>
                        )}
                      </div>
                    </div>)
                );
              })}
              {showImageModal && (
                <ImageModal
                  closeImageModal={() => setShowImageModal(null)}
                  imageUrl={showImageModal}
                />
              )}
            </div>
          )}
        </>
      )}
      {!comments.length && !isAddingComment && !isLoadingComments && (
        <div className="comments__empty-state">
          <div className="comments__empty-state__text">
            <Trans i18nKey="building.comments.emptyState">
              <span>Would you like to share your thoughts or ask a question anonymously?</span>
              <span>Be the first to talk about this property! 🥳</span>
            </Trans>
          </div>
        </div>
      )}
      {isAddingComment ? (
        <>
          <div
            style={{
              height:
                imagePreviewUrl && addCommentFormHeightStyle
                  ? addCommentFormHeightStyle
                  : imageDimensions
                    ? `${imageDimensions.height + 336}px`
                    : '336px',
            }}
            className={`comments__add-comment-form`}
            ref={commentFormRef}
          >
            <div className="comments__add-comment-form__textarea-wrapper">
              <textarea
                className="comments__add-comment-form__textarea-wrapper__textarea"
                maxLength={250}
                placeholder={t('building.comments.placeholder')}
                onChange={(e) => {
                  setCommentText(e.target.value);
                }}
              />
            </div>
            {selectedImageFile && (
              <>
                <div
                  style={{
                    bottom:
                      imageDimensions && photoPreviewBottomStyle
                        ? photoPreviewBottomStyle
                        : imageDimensions
                          ? `${126}px`
                          : '',
                  }}
                  className="comments__add-comment-form__photo-preview"
                >
                  <img src={imagePreviewUrl} alt="Preview" />
                </div>
                <div className="comments__add-comment-form__photo-preview__remove-button-wrapper">
                  <button onClick={removeSelectedImage} type="button">
                    <TrashIcon />
                  </button>
                </div>
              </>
            )}
          </div>
          <div
            className={`comments__add-comment-form__photo-buttons-wrapper
                        ${showPhotoButtons
                ? ' comments__add-comment-form__photo-buttons-wrapper--show'
                : ''
              }`}
          >
            <div
              className="comments__add-comment-form__photo-buttons-wrapper__buttons"
              ref={photoButtonsRef}
            >
              <label
                htmlFor="upload-photo"
                className="comments__add-comment-form__photo-buttons-wrapper__add-photo-button"
              >
                <input
                  type="file"
                  id="upload-photo"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Trans i18nKey="building.comments.pickPhoto">
                  Pick photo
                </Trans>
              </label>

              <label
                htmlFor="take-photo"
                className="comments__add-comment-form__photo-buttons-wrapper__take-photo-button"
              >
                <input
                  type="file"
                  id="take-photo"
                  accept="image/*"
                  capture="environment"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Trans i18nKey="building.comments.takePhoto">
                  Take photo
                </Trans>
              </label>
            </div>
          </div>
          <div className="comments__add-comment-form__buttons">
            <div className="comments__add-comment-form__buttons__photo-button">
              <button
                className={`comments__add-comment-form__buttons__photo-button__button${showPhotoButtons
                  ? ' comments__add-comment-form__buttons__photo-button__button--show-photo-buttons'
                  : ''
                  }`}
                onClick={handleToggleShowPhotoButtons}
                ref={showPhotoButtonsButtonRef}
              >
                <CameraIcon />
              </button>
            </div>
            <div className="comments__send-or-return-comment-button-wrapper">
              <button
                className="comments__add-comment-form__back-button"
                onClick={() => {
                  setIsAddingComment(false);
                  removeSelectedImage();
                  setImageDimensions(null);
                  setImagePreviewUrl(null);
                }}
              >
                <span>
                  <Trans i18nKey="building.comments.back">
                    Back
                  </Trans>
                </span>
                <BackIcon />
              </button>
              <button
                className="comments__add-comment-form__send-button"
                disabled={
                  (!commentText && !selectedImageFile) || isSubmittingComment
                }
                onClick={handleSubmitComment}
              >
                {isSubmittingComment ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <span>
                      <Trans i18nKey="building.comments.send">
                        Send
                      </Trans>
                    </span>
                    <SendIcon />
                  </>
                )}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div
          className={`comments__add-comment-button-wrapper${comments.length === 0
            ? ' comments__add-comment-button-wrapper--no-comments'
            : ''
            }${isLoadingComments
              ? ' comments__add-comment-button-wrapper--loading-comments'
              : ''
            }`}
        >
          <button
            className="comments__add-comment-button-wrapper__button"
            onClick={() => {
              if (showCommentControlsMenu) {
                setShowCommentControlsMenu(null);
              }
              if (isLoggedIn) {
                setIsAddingComment(true);
              } else {
                setLoginModalVisible(true);
              }
            }}
          >
            <div className="comments__add-comment-button-wrapper__button__plus-sign">
              +
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default Comments;
