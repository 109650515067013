export const fi = {
  "common": {
    "showMore": "Näytä lisää",
    "showLess": "Näytä vähemmän"
  },
  "feed": {
    "title": "Fiidi",
    "button": {
      "showFeed": "Fiidi",
      "hideFeed": "Sulje"
    },
    "likeBuildingEvent": {
      "text": "Käyttäjä tykkäsi talosta!"
    },
    "offMarketWillingnessEvent": {
      "sell": {
        "text": "Hiljaisessa myynnissä!"
      },
      "buy": {
        "text": "Kiinnostunut ostaja!"
      }
    }
  },
  "map": {
    "showMap": "Näytä kartta",
    "showFeed": "Näytä fiidi"
  },
  "navbar": {
    "login": "Kirjaudu",
    "loggedIn": "Kirjautunut",
    "messages": "Viestit"
  },
  "searchInput": {
    "placeholder": "Etsi talo - myy, osta ja tutki"
  },
  "mainMenu": {
    "whatIsJaakko": "Mikä Jaakko.com on?",
    "contact": "Yhteystiedot",
    "termsConditions": "Käyttöehdot",
    "privacyPolicy": "Tietosuoja",
    "cookies": "Evästeet"
  },
  "timeAgo": {
    "justNow": "Nyt",
    "minutes": "{{count}} min",
    "hours": "{{count}} h",
    "days": "{{count}} vrk"
  },
  "building": {
    "controls": {
      "follow": "Seuraa",
      "share": "Jaa",
      "like": "Tykkää"
    },
    "generalInfo": {
      "property": "Kohde",
      "plot": "Tontti",
      "redemptionClause": {
        "title": "Lunastuspykälä",
        "yes": "Kyllä",
        "no": "Ei"
      },
      "plotOwnership": {
        "title": "Tontti",
        "owned": "Oma",
        "rented": "Vuokra"
      }
    },
    "apartments": {
      "title": "Huoneistot",
      "units": {
        "count": "{{count}} kpl",
        "countEmpty": "- kpl"
      },
      "roomCount": "{{count}}h",
      "fourPlusRooms": "4h+"
    },
    "offMarket": {
      "couldSell": "Voisin myydä",
      "couldBuy": "Voisin ostaa",
      "count_one": "{{count}} kpl",
      "count_other": "{{count}} kpl",
      "infoButton": "Mitä nämä ovat",
      "infoModalText": "Voisitko myydä tai haluaisitko ostaa täältä? Ilmaise kiinnostuksesi napin painalluksella – helpommin kuin koskaan! Yhdistämme sinut vastapuolen kiinnostuneisiin täysin anonyymisti ja ilman sitoumuksia. Keskustelu välillänne aukeaa suoraan Viestit-osiossa ja sähköpostiosoitteesi näytetään toiselle osapuolelle vain, jos päätät lähettää viestin tai vastata saamaasi tiedusteluun. Voit myös poistaa kiinnostuksesi milloin tahansa. <1>Lue lisää...</1>",
      "toast": {
        "couldSellNoBuyers": "Hienoa! Kun kiinnostunut ostaja löytyy, saat tiedon sähköpostiisi.",
        "couldSellAndBuyers": "Myös ostajat ovat kiinnostuneita, voitte nyt keskustella Viestit-osiossa!",
        "couldBuyNoSellers": "Hienoa! Kun kiinnostunut omistaja löytyy, saat tiedon sähköpostiisi.",
        "couldBuyAndSellers": "Myös omistajat ovat kiinnostuneita, voitte nyt keskustella Viestit-osiossa!",
        "userSellerAndBuyer": "Et voi olla sekä myyjä että ostaja samanaikaisesti."
      },
      "modal": {
        "sell": {
          "title": "Vahvista myyntikiinnostus",
          "description": "Hienoa! Vahvistamalla valintasi yhdistämme sinut kiinnostuneisiin ostajiin anonyymisti. Voit poistaa myyntikiinnostuksesi milloin tahansa."
        },
        "buy": {
          "title": "Vahvista ostokiinnostus",
          "description": "Hienoa! Vahvistamalla valintasi yhdistämme sinut kiinnostuneisiin myyjiin anonyymisti. Voit poistaa ostokiinnostuksesi milloin tahansa."
        },
        "confirm": "Vahvista",
        "cancel": "Peruuta",
        "detailsOptional": "Asunnon tiedot (Valinnainen)",
        "description": "Kuvaus",
        "buyingInterestDescriptionPlaceholder": "Lisää tietoja asunnoista, joista olet kiinnostunut...",
        "sellingInterestDescriptionPlaceholder": "Lisää tietoja asunnosta, jonka voisit myydä...",
        "additionalDetails": "Anna lisätietoja (valinnainen)",
        "size": "Koko (m²)",
        "sizePlaceholder": "esim. 65",
        "sizeClass": "Kokoluokka (m²)",
        "rooms": "Huonetta",
        "rooms_one": "Huone",
        "rooms_other": "Huonetta",
        "roomsLabel": "Huoneita",
        "selectRooms": "Valitse huoneiden määrä",
        "floor": "Kerros",
        "floorPlaceholder": "esim. 3",
        "renovation": "Remonttitilanne",
        "selectRenovation": "Valitse kunto",
        "recentlyRenovated": "Äskettäin remontoitu",
        "goodCondition": "Hyvä kunto",
        "needsRenovation": "Tarvitsee remonttia",
        "hasElevator": "Hissi",
        "hasBalcony": "Parveke/terassi"
      }
    },
  "housingCompanyHoldings": {
    "title": "Taloyhtiön omistukset",
    "apartments": "Huoneistoja",
    "commercialSpaces": "Liiketiloja",
    "units": "kpl"
  },
  "jaakko": {
    "sendFeedback": "Lähetä palautetta"
  },
  "housingCompanyInfo": {
    "title": "Taloyhtiön lisätiedot"
  },
  "comments": {
    "title": "Keskustelu",
    "emptyState": "<0>Haluatko kertoa tai kysyä talosta jotain?</0><1>Aloita anonyymi keskustelu! 🥳</1>",
    "placeholder": "Jaa kokemuksesi talosta, lisää kuva tai kysy jotain.",
    "send": "Lähetä",
    "back": "Takaisin",
    "pickPhoto": "Valitse kuva",
    "takePhoto": "Ota kuva",
    "commenterOrderNumberFirst": "Eka."
  },
  "filesDropBox": {
    "title": "Lataa yhtiöjärjestys, tilinpäätös, kunnossapitotarveselvitys tai muita liittyviä dokumentteja."
  },
  "documentDropzone": {
    "clickToUpload": "<1>Valitse tiedosto</1> <2>tai pudota tähän</2>",
    "termsAgreement": "Lähettämällä dokumentteja sitoudut noudattamaan palveluidemme <1>käyttöehtoja</1>, sekä ilmoitat lukeneesi ja hyväksyväsi Jaakkocom Oy:n <3>yleisen tietosuojakuvauksen</3>.",
    "buttonText": "Analysoi",
    "loading": {
      "title": "Koostan raporttia tietojen pohjalta.",
      "text": "Tässä voi mennä muutama minuutti..."
    }
  },
  "buildingInfoBox": {
    "title": "Taloyhtiö"
  },
  "financialsInfoBox": {
    "title": "Talous"
  },
  "renovationsInfoBox": {
    "title": "Remontit"
  },
  "maintenanceNeedsAssessmentPlaceHolderText": "Syötä taloyhtiön kunnossapitotarveselvitys, tai PTS -suunnitelma, saadaksesi analyysin",
  "articlesOfAssociationPlaceHolderText": "Syötä taloyhtiön yhtiöjärjestys, saadaksesi analyysin",
  "financialStatementPlaceHolderText": "Syötä taloyhtiön tilinpäätös, tai talousarvio, saadaksesi analyysin",
  "streetView": {
    "title": "Katunäkymä"
  }
},
"mainMenuPages": {
  "faq": {
    "title": "Usein kysytyt kysymykset",
      "whatIsJaakko": {
      "title": "Mikä Jaakko.com on?",
        "paragraphs": {
        "first": "Jaakko.com on uudenlainen palvelu, joka yhdistää ostajat ja myyjät toisiinsa helpommin kuin koskaan, ilman sitoutumuksia. Jos et löydä kiinnostavaa kohdetta nykyisistä palveluista tai harkitset myymistä ilman raskasta ja monimutkaista prosessia, Jaakko.com tarjoaa yksinkertaisen ja tehokkaan tavan uudenlaiselle kaupankäynnille.",
          "second": "Käyttäjät voivat myös keskustella, jakaa tietoa ja kuvia sekä esittää kysymyksiä mistä tahansa rakennuksesta. Keskustelun avulla voi oppia lisää eri kohteista ja saada arvokasta näkemystä muilta käyttäjiltä.",          
        }
    },
    "sellingAndBuying": {
      "title": "Ostaminen ja myyminen Jaakko.comin kautta",
        "howItWorks": {
        "title": "Miten se toimii?",
          "bulletPoints": {
          "express": "Valitse talo kartalta ja ilmaise kiinnostuksesi ostamiseen tai myymiseen yhdellä napin painalluksella.",
            "connect": "Kun kiinnostus on molemminpuolista, saat tiedon sähköpostiisi, ja osapuolille avautuu suora keskustelumahdollisuus palvelussa.",
              "anonymous": "Kaikki on aluksi täysin anonyymiä – sähköpostiosoitteesi jaetaan vasta, jos päätät vastata vastapuolen yhteydenottoon tai lähettää itse viestin.",
                "information": "Voit aloittaa keskustelun esimerkiksi kertomalla, mikä sinua kiinnostaa, kuten \"Voisin myydä 2h\" tai \"Etsin perheasuntoa talosta\".",
                  "removeinterest": "Voit koska tahansa poistaa kiinnostuksesi yhdellä napin painalluksella, jolloin uusia yhteydenottoja ei enää tule.",
                    "removechat": "Voit poistaa minkä tahansa keskustelun koska tahansa. Tällöin et enää saa viestejä kyseiseltä vastapuolelta."
        },
      },
      "safety": {
        "title": "Huomioithan turvallisuuden ja hyvät tavat:",
          "bulletPoints": {
          "first": "Jaakko.com on tarkoitettu yhteyksien luomiseen – palvelu ei osallistu varsinaiseen kaupankäyntiin eikä varmista osapuolten henkilöllisyyttä.",
            "second": "Varmista aina vastapuolen henkilöllisyys ennen päätöksiä tai maksuja. Tämä voi tapahtua esimerkiksi tapaamalla kasvotusten tai pyytämällä virallisia asiakirjoja.",
              "third": "Käytä tarvittaessa asiantuntijoita, kuten välittäjää tai juristia, varmistaaksesi turvallisen kaupanteon.",
                "fourth": "Viesteissä ei saa esittää loukkaavia, laittomia, häiritseviä tai valheellisia väitteitä, eikä mitään sisältöä, joka rikkoo lakeja tai hyviä tapoja.",
                  "fifth": "Jaakko.com pidättää oikeuden poistaa keskusteluja ja käyttäjiä, jotka rikkovat palvelun sääntöjä tai hyviä tapoja.",
                    "sixth": "Noudattamalla näitä ohjeita varmistat, että palvelun käyttö on turvallista ja mukavaa kaikille osapuolille."
        }
      }
    },
    "benefits": {
      "title": "Mitä hyötyä Jaakko-tunnuksesta on?",
        "paragraphs": {
        "first": "Voit selata rakennuksia ilman tunnusta, mutta Jaakko-tunnuksella saat käyttöösi lisäominaisuuksia:",
          "second": "Jaakko-tunnuksen luominen on maksutonta ja vie vain minuutin!"
      },
      "bulletPoints": {
        "first": "Ilmaise kiinnostuksesi myymiseen tai ostamiseen missä tahansa rakennuksessa.",
          "second": "Osallistu keskusteluun ja jaa kokemuksia.",
            "third": "Pyydä apua asiakirjoihin – Jaakko auttaa tulkitsemaan taloyhtiödokumentteja.",
              "fourth": "Seuraa kiinnostavia kohteita ja saa ilmoituksia uusista tiedoista.",
                "fifth": "Jaa ja tykkää kiinnostavia taloja."
      }
    },
    "howToParticipate": {
      "title": "Miten osallistun keskusteluun?",
        "paragraph": "Keskustelu ja kuvien jakaminen palvelussa on anonyymiä, mutta edellyttää Jaakko-tunnusta. Keskusteluissa tulee noudattaa hyviä tapoja, eikä niissä saa mainita omia tai muiden nimiä tai muita yhteystietoja. Lisäksi keskusteluissa ei saa esittää loukkaavia, laittomia, häiritseviä tai valheellisia väitteitä, eikä mitään sisältöä, joka rikkoo lakeja tai hyviä tapoja."
    },
    "documentAnalysis": {
      "title": "Jaakko auttaa myös taloyhtiön dokumenttien tulkitsemisessa – mitä se tarkoittaa?",
        "paragraphs": {
        "first": "Kun lataat taloyhtiötä koskevat dokumentit, Jaakko analysoi ne ja tarjoaa tiivistetyt havainnot keskeisistä asioista, kuten taloyhtiön taloudesta, omistusrakenteesta ja remonttihistoriasta. Dokumenttianalyysi tapahtuu reaaliaikaisesti ja tulokset ovat käytössä muutamassa minuutissa.",
          "second": "Jaakon havainnoissa hyödynnetään generatiivista tekoälyä, mikä mahdollistaa analyysien kustannustehokkaan ja puolueettoman toteutuksen. Vaikka virheitä voi esiintyä, tarjoaa analyysi erinomaista tukea ostopäätösten teossa. Tärkeiden asioiden tarkistus on kuitenkin aina käyttäjän vastuulla, joka viime kädessä tekee päätökset. Jaakon tavoitteena on nostaa esiin kaikki olennaiset tekijät, joista kannattaa kysyä lisää. tarkistus on kuitenkin aina käyttäjän vastuulla, joka viime kädessä tekee päätökset. Jaakon tavoitteena on nostaa esiin kaikki olennaiset tekijät, joista kannattaa kysyä lisää."
      }
    },
    "typesOfRealEstate": {
      "title": "Minkälaisia kohteita Jaakko kattaa?",
        "paragraph": "Karttapohjainen käyttöliittymä mahdollistaa tutustumisen rakennuksiin, keskustelun ja osto- ja myyntikiinnostuksen osoittamisen kaikkialla maailmassa. Dokumenttianalyysi toimii Suomessa sijaitsevissa asunto-osakeyhtiöissä."
    },
    "payments": {
      "title": "Maksullisuus",
        "paragraph": "Palvelun perustoiminnot ovat veloituksettomia, mutta tietyistä ominaisuuksista ja lisäpalveluista voidaan tulevaisuudessa periä maksu. Palvelun veloitukseton käyttö ei velvoita jatkossa mihinkään."
    },
    "feedback": {
      "title": "Miten voin antaa palautetta?",
        "paragraph": "Kaikki palaute on tervetullutta. Voit lähettää palautteesi osoitteeseen support@jaakko.com. Kehitämme palvelua jatkuvasti ja arvostamme käyttäjien näkemyksiä."
    }
  },
  "contactUs": {
    "title": "Yhteystiedot",
      "customerSupport": "Asiakaspalvelu:",
        "businessId": "Y-tunnus:",
          "country": "Suomi"
  }
},
"signupLoginModal": {
  "loginOrSignup": "Kirjaudu tai luo tunnus",
    "continueWithGoogle": "Jatka Googlella",
      "orContinueWithEmail": "tai jatka sähköpostilla",
        "loginOrSignupButton": "Seuraava",
          "login": "Kirjaudu",
            "passwordPlaceholder": "Salasana",
              "loginButton": "Seuraava",
                "logout": "Kirjaudu ulos",
                  "logoutButton": "Jatka",
                    "emailPlaceholder": "Sähköposti",
                      "infoBox": {
    "title": "Aloita Jaakon käyttö",
      "bulletPoints": {
      "first": "Laita asuntosi hiljaiseen myyntiin tai ilmoita ostokiinnostuksesi helposti yhdellä napin painalluksella",
        "second": "Keskustele mielenkiintoisista taloyhtiöistä",
          "third": "Saat sähköpostin seuraamiesi talojen tapahtumista"
    }
  },
  "registerText": {
    "first": "Rekisteröitymällä luot Jaakko-tunnuksen ja sitoudut noudattamaan palveluidemme ",
      "second": "käyttöehtoja",
        "third": ", sekä ilmoitat lukeneesi ja hyväksyväsi Jaakkocom Oy:n ",
          "fourth": "yleisen tietosuojakuvauksen."
  },
  "passwordRequirements": {
    "length": "Salasanan täytyy olla vähintään 8 merkkiä pitkä.",
      "uppercase": "Salasanan täytyy sisältää vähintään yksi iso kirjain.",
        "lowercase": "Salasanan täytyy sisältää vähintään yksi pieni kirjain.",
          "number": "Salasanan täytyy sisältää vähintään yksi numero."
  },
  "checkPassword": "Tarkista salasana.",
    "unexpectedError": "Hups! Jotain meni pieleen.",
      "signup": {
    "title": "Luo tunnus",
      "accountExists": "Näyttää siltä, että sinulla on jo tunnus. Kirjaudu sisään.",
        "signupButton": "Seuraava"
  },
  "emailVerification": {
    "title": "Vahvista sähköpostiosoite",
      "firstParagraph": "Anna vahvistuskoodi",
        "secondParagraph": "Anna koodi, joka lähetettiin sähköpostiosoitteeseesi {{email}} (jos et näe sähköpostia, tarkista roskapostikansio)",
          "codePlaceholder": "Vahvistuskoodi"
  },
  "forgotPassword": {
    "linkText": "Unohditko salasanasi?",
    "title": "Uusi salasana",
    "subtitle": "Syötä sähköpostiosoitteesi, niin lähetämme sinulle linkin salasanan uusimiseksi.",
    "submitMessage": "Jos tällä sähköpostiosoitteella on luotu tunnus, sinne on lähetetty salasanan uusimislinkki.",
    "sendLinkButton": "Lähetä uusimislinkki",
    "backToLogin": "Takaisin kirjautumiseen"
  },
  "resetPasswordPage": {
    "title": "Aseta uusi salasana",
    "newPasswordPlaceholder": "Uusi salasana",
    "confirmPasswordPlaceholder": "Vahvista uusi salasana",
    "submitButton": "Aseta uusi salasana",
    "passwordMismatch": "Salasanat eivät täsmää.",
    "successMessage": "Salasanasi on nollattu onnistuneesti. Voit nyt kirjautua sisään uudella salasanallasi.",
    "invalidLink": "Virheellinen tai vanhentunut salasanan nollauslinkki.",
    "tokenMissing": "Salasanan nollaustunniste puuttuu.",
    "goToLogin": "Siirry kirjautumiseen"
  }
},
"hints": {
  "clickBuilding": "Klikkaa mitä tahansa rakennusta",
    "zoomInToSeeHouseNumbers": "Zoomaa nähdäksesi talonumerot",
      "expressInterest": "Osoita myynti- tai ostokiinnostuksesi"
},
"metaTags": {
  "title": "Jaakko.com | Asuntokartta & hiljainen myynti",
    "description": "Voisitko myydä asuntosi, tai haluaisitko ostaa? Oman asunnon klikkaaminen hiljaiseen myyntiin, tai seuraavan unelmakodin etsiminen, on nyt helpompaa kuin koskaan.",
      "ogTitle": "Jaakko.com | Asuntokartta & hiljainen myynti",
        "ogDescription": "Voisitko myydä asuntosi, tai haluaisitko ostaa? Oman asunnon klikkaaminen hiljaiseen myyntiin, tai seuraavan unelmakodin etsiminen, on nyt helpompaa kuin koskaan.",
          "building": {
    "title": "Talosivu {{address}} - Jaakko.com",
      "description": "Tutustu {{address}}. Kerro myynti- tai ostokiinnostuksesta, keskustele tai seuraa.",
        "ogTitle": "Tutustu mielenkiintoiseen taloon Jaakko.com:issa",
          "ogDescription": "Tutustu {{address}}. Kerro myynti- tai ostokiinnostuksesta, keskustele tai seuraa."
  }
},
"shareButton": {
  "title": "Tutustu mielenkiintoiseen taloon Jaakko.com:issa",
    "text": "Tutustu {{address}}. Kerro myynti- tai ostokiinnostuksesta, keskustele tai seuraa.",
      "linkCopied": "Linkki taloon kopioitu leikepöydälle.",
        "linkCopiedError": "Linkin kopiointi epäonnistui. Kopioi linkki manuaalisesti."
},
"messages": {
  "title": "Viestit",
    "empty": "Kun aloitat keskustelun ostajien tai myyjien kanssa, löydät kaikki viestit täältä.",
      "send": "Lähetä",
        "tabs": {
    "inbox": "Viestit",
      "all": "Kaikki",
        "support": "Tuki"
  },
  "firstMessage": "Keskustelunne alkaa tästä.",
    "welcomeTitle": "Keskustelunne alkaa tästä",
      "welcomeText": "Keskustelusi käyttäjän {{ email }} kanssa alkaa tästä. Voit keskustella asunnon yksityiskohdista ja esimerkiksi sopia näytön ajankohdan.",
        "welcomeTextOtherUserHasNotSentMessage": "Keskustelusi toisen osapuolen kanssa alkaa tästä. Voit keskustella asunnon yksityiskohdista ja esimerkiksi sopia näytön ajankohdan.",
          "welcomeTextEmailDisclaimer": "Huomioithan, että sähköpostiosoitteesi jaetaan vastapuolen kanssa, mikäli päätät aloittaa keskustelun tai vastata sinulle lähetettyihin viesteihin. Vastaavasti saat tietää vastapuolen sähköpostiosoitteen saadessasi ensimmäisen viestin.",
            "welcomeTips": "Vinkkejä onnistuneeseen neuvotteluun:",
              "tipPolite": "Ole kohtelias ja kunnioittava",
                "tipCaution": "Älä jaa arkaluontoisia tietoja ennen kuin olet varma vastapuolen luotettavuudesta",
                  "tipDetails": "Voit lopettaa keskustelun milloin tahansa, jolloin vastapuolen viestit eivät enää tule sinulle",
                    "writePlaceholder": "Kirjoita viesti...",
                      "chatEndedPlaceholder": "Tämä keskustelu on päättynyt",
                        "chatEndedMessage": "Tämä keskustelu on päättynyt. Uusia viestejä ei voi lähettää.",
                          "chatEnded": "Keskustelu on päättynyt",
                            "endChat": "Lopeta keskustelu",
                              "endChatConfirmTitle": "Lopeta keskustelu",
                                "endChatConfirmText": "Haluatko varmasti lopettaa tämän keskustelun? Lopettamisen jälkeen kumpikaan osapuoli ei voi enää lähettää viestejä tässä keskustelussa.",
                                  "confirm": "Lopeta keskustelu",
                                    "cancel": "Peruuta",
                                      "ending": "Lopetetaan",
                                        "today": "Tänään",
                                          "yesterday": "Eilen",
                                            "anonymousUser": "Anonyymi käyttäjä"
}
};
