import React, { useState } from 'react';
import { OffMarketWillingnessEvent as OffMarketWillingnessEventType } from '../utils/types';
import OffMarketSellIcon from './OffMarketSellIcon';
import OffMarketBuyIcon from './OffMarketBuyIcon';
import { getTimeAgo } from '../BuildingModal/Report.helpers';
import { Trans, useTranslation } from 'react-i18next';
import './FeedComments.scss';
import Linkify from 'linkify-react';
import DOMPurify from 'dompurify';
type OffMarketWillingnessEventProps = {
  comment: OffMarketWillingnessEventType;
  handleFeedEventClick?: (comment: OffMarketWillingnessEventType) => void;
};

export const OffMarketWillingnessEvent = ({
  comment,
  handleFeedEventClick,
}: OffMarketWillingnessEventProps) => {
  const { t } = useTranslation();
  const isBuildingModal = handleFeedEventClick === undefined;
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const isCouldSellMessage = comment.last_modified_value === 'COULD_SELL';
  const shortAddress =
    comment.address.street +
    (comment.address.houseNumber === 'Unknown'
      ? ''
      : ' ' + comment.address.houseNumber) +
    ', ' +
    comment.address.city;

  // Check if there are any property details to display
  const hasPropertyDetails = comment.property_description ||
    comment.property_size ||
    comment.property_rooms ||
    comment.property_floor ||
    comment.property_has_elevator === true ||
    comment.property_has_balcony === true ||
    comment.property_renovation_status;

  // Function to render renovation status as readable text
  const getRenovationStatusText = (status: string) => {
    switch (status) {
      case 'recently_renovated':
        return t('building.offMarket.modal.recentlyRenovated');
      case 'good_condition':
        return t('building.offMarket.modal.goodCondition');
      case 'needs_renovation':
        return t('building.offMarket.modal.needsRenovation');
      default:
        return status;
    }
  };

  return (
    <div
      className={`feed-comments__comment feed-comments__comment--off-market-willingness ${!handleFeedEventClick ? 'feed-comments__comment--is-not-clickable' : ''}`}
      onClick={() => handleFeedEventClick?.(comment)}
    >
      <div className="feed-comments__comment__header-and-content-wrapper">
        <div
          className="feed-comments__comment__commenter-order-number-and-timestamp-wrapper 
                        feed-comments__comment__commenter-order-number-and-timestamp-wrapper--no-text 
                         feed-comments__comment__commenter-order-number-and-timestamp-wrapper--could-sell
                        "
        >
          <div className="feed-comments__comment__commenter-order-number">
            <div className="feed-comments__comment__commenter-order-number__off-market-willingness-comment">
              {isCouldSellMessage ? (
                <div className="feed-comments__comment__commenter-order-number__off-market-willingness-comment__could-sell-icon">
                  <OffMarketSellIcon />
                </div>
              ) : (
                <div className="feed-comments__comment__commenter-order-number__off-market-willingness-comment__could-buy-icon">
                  <OffMarketBuyIcon />
                </div>
              )}
              <span>{shortAddress}</span>
            </div>
          </div>
          <div className="feed-comments__comment__timestamp">
            {getTimeAgo(comment.updated_at)}
          </div>
        </div>
        <div className="feed-comments__comment__content">
          <div
            className={`feed-comments__comment__content__text feed-comments__comment__content__text--off-market-willingness${isCouldSellMessage
              ? ''
              : ' feed-comments__comment__content__text--off-market-willingness--could-buy'
              }`}
          >
            {isCouldSellMessage ? (
              <>
                <Trans i18nKey="feed.offMarketWillingnessEvent.sell.text" />
              </>
            ) : (
              <>
                <Trans i18nKey="feed.offMarketWillingnessEvent.buy.text" />
              </>
            )}
          </div>

          {hasPropertyDetails && (
            <div className="feed-comments__comment__content__property-details">
              {comment.property_description && (
                <div className="feed-comments__comment__content__property-details__description">
                  {isBuildingModal || isDescriptionExpanded || (comment.property_description.length <= 110)
                    ?
                    <Linkify
                      options={{
                        attributes: {
                          rel: 'noopener noreferrer',
                          target: '_blank',
                        },
                      }}
                    >
                      {DOMPurify.sanitize(comment.property_description)}
                    </Linkify>
                    : <span className="feed-comments__comment__content__property-details__description-text">{
                      <Linkify
                        options={{
                          attributes: {
                            rel: 'noopener noreferrer',
                            target: '_blank',
                          },
                        }}
                      >
                        {DOMPurify.sanitize(comment.property_description.substring(0, 110))}
                      </Linkify>

                    }...</span>
                  }
                  {(!isBuildingModal && (comment.property_description.length > 110)) && (
                    <button
                      className="feed-comments__comment__content__property-details__show-toggle"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation(); // Prevent triggering parent click event
                        setIsDescriptionExpanded(!isDescriptionExpanded);
                      }}
                    >
                      {isDescriptionExpanded ? t('common.showLess', 'Show less') : t('common.showMore', 'Show more')}
                    </button>
                  )}
                </div>
              )}

              <div className="feed-comments__comment__content__property-details__specs">
                {comment.property_rooms && (
                  <div className="feed-comments__comment__content__property-details__spec">
                    <span className="feed-comments__comment__content__property-details__spec-icon">🏠</span>
                    <span>{comment.property_rooms} {t('building.offMarket.modal.rooms', { count: Number(comment.property_rooms) })}</span>
                  </div>
                )}

                {comment.property_size && (
                  <div className="feed-comments__comment__content__property-details__spec">
                    <span className="feed-comments__comment__content__property-details__spec-icon">📏</span>
                    <span>{comment.property_size} m²</span>
                  </div>
                )}

                {comment.property_floor && (
                  <div className="feed-comments__comment__content__property-details__spec">
                    <span className="feed-comments__comment__content__property-details__spec-icon">🔝</span>
                    <span>{comment.property_floor} {t('building.offMarket.modal.floor')}</span>
                  </div>
                )}

                {comment.property_renovation_status && (
                  <div className="feed-comments__comment__content__property-details__spec">
                    <span className="feed-comments__comment__content__property-details__spec-icon">🔨</span>
                    <span>{getRenovationStatusText(comment.property_renovation_status)}</span>
                  </div>
                )}

                {comment.property_has_elevator && (
                  <div className="feed-comments__comment__content__property-details__spec">
                    <span className="feed-comments__comment__content__property-details__spec-icon">⬆️</span>
                    <span>{t('building.offMarket.modal.hasElevator')}</span>
                  </div>
                )}

                {comment.property_has_balcony && (
                  <div className="feed-comments__comment__content__property-details__spec">
                    <span className="feed-comments__comment__content__property-details__spec-icon">🏞️</span>
                    <span>{t('building.offMarket.modal.hasBalcony')}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
