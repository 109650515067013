import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppContent from './components/AppContent/AppContent';
import FAQ from './components/MainMenu/MainMenuPages/FAQ';
import ContactUs from './components/MainMenu/MainMenuPages/ContactUs';
import PrivacyPolicyRedirect from './PrivacyPolicyRedirect';
import CookiePolicyRedirect from './CookiePolicyRedirect';
import TermsConditionsRedirect from './TermsConditionsRedirect';
import { GoogleMapsApiProvider } from './GoogleMapsApiContext';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';

const App = () => {
  return (
    <GoogleMapsApiProvider>
      <div className="App-wrapper">
      <Router>
        <div className="App-content">
          <Switch>
            <Route exact path="/" render={() => <AppContent />} />
            <Route path="/faq" component={FAQ} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/terms-conditions" component={TermsConditionsRedirect} />
            <Route path="/cookie-policy" component={CookiePolicyRedirect} />
            <Route path="/privacy-policy" component={PrivacyPolicyRedirect} />
            <Route path="/reset-password/:token" component={ResetPasswordPage} />
          </Switch>
          </div>
        </Router>
      </div>
    </GoogleMapsApiProvider>
  );
};

export default App;
