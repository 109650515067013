import React from 'react';
import './MainMenuPages.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import MainMenu from '../MainMenu';
import './Page.scss';
import { Trans } from 'react-i18next';

const FAQ = () => {
  const history = useHistory();
  return (
    <Container className="p-0 policy-page-container">
      <MainMenu isMainMenuPage />
      <div className="closeMenuView" onClick={() => history.push('/')} />
      <div className="policy-page-content">
        <Link to="/">
          <div className="flatsonBlueText">Jaakko.com</div>
        </Link>
        <Row>
          <p className="page-name">
            <Trans i18nKey="mainMenuPages.faq.title">
              Frequently asked questions
            </Trans>
          </p>
        </Row>
        <Row>
          <Col>
            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.whatIsJaakko.title">
                  Mikä Jaakko.com on?
                </Trans>
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.whatIsJaakko.paragraphs.first" />
              <br />
              <br />
              <Trans i18nKey="mainMenuPages.faq.whatIsJaakko.paragraphs.second" />
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.title">
                  Ostaminen ja myyminen Jaakko.comin kautta
                </Trans>
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.title">
                Miten se toimii?
              </Trans>
              <ul>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.bulletPoints.express" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.bulletPoints.connect" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.bulletPoints.anonymous" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.bulletPoints.information" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.bulletPoints.removeinterest" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.howItWorks.bulletPoints.removechat" />
                </li>
              </ul>
              <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.title">
                Huomioithan turvallisuuden:
              </Trans>
              <ul>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.bulletPoints.first" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.bulletPoints.second" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.bulletPoints.third" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.bulletPoints.fourth" />
                </li>
                  <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.bulletPoints.fifth" />
                </li>
                  <li>
                  <Trans i18nKey="mainMenuPages.faq.sellingAndBuying.safety.bulletPoints.sixth" />
                </li>
              </ul>
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.benefits.title">
                  Mitä hyötyä Jaakko-tunnuksesta on?
                </Trans>
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.benefits.paragraphs.first" />
              <ul>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.benefits.bulletPoints.first" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.benefits.bulletPoints.second" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.benefits.bulletPoints.third" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.benefits.bulletPoints.fourth" />
                </li>
                <li>
                  <Trans i18nKey="mainMenuPages.faq.benefits.bulletPoints.fifth" />
                </li>
              </ul>
              <Trans i18nKey="mainMenuPages.faq.benefits.paragraphs.second" />
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.howToParticipate.title" />
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.howToParticipate.paragraph" />
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.documentAnalysis.title" />
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.documentAnalysis.paragraphs.first" />
              <br />
              <br />
              <Trans i18nKey="mainMenuPages.faq.documentAnalysis.paragraphs.second" />
            </p>
            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.typesOfRealEstate.title" />
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.typesOfRealEstate.paragraph" />
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.payments.title" />
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.payments.paragraph" />
            </p>

            <p className="small-text">
              <b className="paragraph-title">
                <Trans i18nKey="mainMenuPages.faq.feedback.title" />
              </b>
              <br />
              <Trans i18nKey="mainMenuPages.faq.feedback.paragraph" />
            </p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default FAQ;
