import React from 'react';

const ExpanderArrow: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => (
  <svg
    className={`report__content__section__expander__button__arrow${
      isExpanded ? ' report__content__section__expander__button__arrow--up' : ''
    }`}
    width="21.75"
    height="12.75"
    viewBox="0 0 21.75 12.75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.2858 0.0758528L0.375 1.986645L11.0625 12.674175L21.75 1.986645L19.839225 0.075852L11.0625 8.85255L2.2858 0.0758528Z"
      fill="#202125"
      fillOpacity="0.54"
    />
  </svg>
);

export default ExpanderArrow;
