import React, { useEffect, useState } from 'react';
import './NavbarLanguageSelector.scss';
import { useTranslation } from 'react-i18next';
import LanguageSelectorIcon from './LanguageSelectorIcon';

const NavbarLanguageSelector = () => {
    const { t, i18n } = useTranslation('navbar');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const currentLanguage = i18n.language;
    const languageToShow = currentLanguage === 'fi' ? 'fi' : 'en';
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // TODO: On click outside of dropdown, close it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isDropdownOpen && !(event.target as HTMLElement).closest('.navbar-language-selector')) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <div className="navbar-language-selector"
        onClick={toggleDropdown}
        >
            <button
                className="navbar-language-selector__button"
            >
                <LanguageSelectorIcon />
                <span>
                    {languageToShow}
                </span>
            </button>
            {isDropdownOpen && (
                <div className="navbar-language-selector__dropdown">
                    <div
                        className={`navbar-language-selector__dropdown-item ${currentLanguage.startsWith('en') ? 'navbar-language-selector__dropdown-item--active' : ''}`}
                        onClick={() => {
                            // TODO: Enforce i18n language change to also update localStorage's localStorage.i18nextLng
                            i18n.changeLanguage('en');
                            localStorage.setItem('i18nextLng', 'en');
                            setIsDropdownOpen(false);
                        }}
                    >
                        <span>{t('en')}</span>
                    </div>
                    <div
                        className={`navbar-language-selector__dropdown-item ${currentLanguage === 'fi' ? 'navbar-language-selector__dropdown-item--active' : ''}`}
                        onClick={() => {
                            // TODO: Enforce i18n language change to also update localStorage's localStorage.i18nextLng
                            i18n.changeLanguage('fi');
                            localStorage.setItem('i18nextLng', 'fi');
                            setIsDropdownOpen(false);
                        }}
                    >
                        <span>{t('fi')}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavbarLanguageSelector;