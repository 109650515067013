import React, { useEffect, useRef } from 'react';
import { BuildingWithConvertedAddresses } from '../../utils/types';
import './StreetView.scss';
import LoadingSpinner from '../../common/LoadingSpinner';

interface StreetViewProps {
  selectedBuilding: BuildingWithConvertedAddresses;
}

const StreetView: React.FC<StreetViewProps> = ({ selectedBuilding }) => {
  const streetViewRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    // Initialize Street View when the component mounts
    const initStreetView = () => {
      if (!streetViewRef.current) return;
      
      try {
        if (!selectedBuilding.centroid) {
          setError('Building location not available');
          setIsLoading(false);
          return;
        }

        // Use coordinates from the first address instead of building centroid
        const addressCoordinates = selectedBuilding.addresses[0]?.coordinates;
        const { lat, lng } = addressCoordinates || selectedBuilding.centroid;
        
        // Create a new Street View panorama
        // eslint-disable-next-line
        const panorama = new google.maps.StreetViewPanorama(streetViewRef.current, {
          position: { lat, lng },
          pov: { heading: 0, pitch: 0 },
          zoom: 1,
          addressControl: true,
          fullscreenControl: true,
          motionTracking: false,
          motionTrackingControl: false,
          showRoadLabels: true,
          visible: true,
        });

        // Check if Street View is available at this location
        const streetViewService = new google.maps.StreetViewService();
        streetViewService.getPanorama({ 
          location: { lat, lng }, 
          radius: 100,
          sources: [google.maps.StreetViewSource.OUTDOOR]
        }, (data, status) => {
          if (status === google.maps.StreetViewStatus.OK) {
            // Official Google Street View is available
            panorama.setPano(data.location.pano);
            setIsLoading(false);
          } else {
            // No official Street View available
            // 1. Show an error
            setError('Official Street View not available at this location');
            setIsLoading(false);
            
            // 2. Fall back to including user photos
            streetViewService.getPanorama({ 
              location: { lat, lng }, 
              radius: 50,
              // No source restriction
            }, (fallbackData, fallbackStatus) => {
              if (fallbackStatus === google.maps.StreetViewStatus.OK) {
                // User-contributed content is available
                // eslint-disable-next-line
                const panorama = new google.maps.StreetViewPanorama(streetViewRef.current, {
                  position: { lat, lng },
                  pov: { heading: 0, pitch: 0 },
                  zoom: 1,
                  addressControl: true,
                  fullscreenControl: true,
                  motionTracking: false,
                  motionTrackingControl: false,
                  showRoadLabels: true,
                  visible: true,
                });
                setIsLoading(false);
              } else {
                // No Street View available at all
                setError('Street View not available at this location');
                setIsLoading(false);
              }
            });
          }
        });
      } catch (err) {
        console.error('Error initializing Street View:', err);
        setError('Failed to load Street View');
        setIsLoading(false);
      }
    };

    // Check if Google Maps API is loaded
    if (window.google && window.google.maps) {
      initStreetView();
    } else {
      // Load Google Maps API if not already loaded
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&callback=initStreetView`;
      script.async = true;
      script.defer = true;
      
      // Define the callback function
      window.initStreetView = initStreetView;
      
      document.head.appendChild(script);
      
      return () => {
        // Clean up
        if (window.initStreetView) {
          delete window.initStreetView;
        }
        document.head.removeChild(script);
      };
    }
  }, [selectedBuilding]);

  return (
    <div className="street-view-container">
      {isLoading && (
        <div className="street-view-loading">
          <LoadingSpinner />
        </div>
      )}
      {error && (
        <div className="street-view-error">
          <p>{error}</p>
        </div>
      )}
      <div 
        ref={streetViewRef} 
        className="street-view-panorama"
        style={{ display: isLoading || error ? 'none' : 'block' }}
      />
    </div>
  );
};

export default StreetView; 