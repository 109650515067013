import React from 'react';

export const CouldBuyIcon = ({ selected }: { selected: boolean }) => {
  const strokeColor = selected ? '#ff5e5e' : '#000';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      viewBox="0 0 512.002 512.002"
      xmlSpace="preserve"
      width="16"
      height="16"
    >
      <g>
        <path
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          d="M413.001,420.001c0,27.036,0,52.927,0,77.001l-40-14.999l-40,14.999l-40-14.999l-40,14.999l-40-14.999l-40,14.999l-40-14.999l-40,14.999V53"
        />

        <path
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          d="M55.001,15h318.997c21.482,0,39.003,17.521,39.003,39.003c0,42.559,0,90.798,0,140.998"
        />

        <path
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          d="M54.001,15c21.45,0,39,17.55,39,39v117h-78V54C15.001,32.55,32.551,15,54.001,15z"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="153.001"
          y1="135.002"
          x2="274.001"
          y2="135.002"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="334.001"
          y1="135.002"
          x2="353.001"
          y2="135.002"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="153.001"
          y1="195.003"
          x2="274.001"
          y2="195.003"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="153.001"
          y1="255.002"
          x2="274.001"
          y2="255.002"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="153.001"
          y1="315.002"
          x2="264.001"
          y2="315.002"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="153.001"
          y1="75.001"
          x2="274.001"
          y2="75.001"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="334.001"
          y1="75.001"
          x2="353.001"
          y2="75.001"
        />

        <line
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          x1="153.001"
          y1="375.001"
          x2="274.001"
          y2="375.001"
        />

        <ellipse
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -105.4033 360.5338)"
          className="transition-stroke"
          style={{
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: 'none',
            stroke: strokeColor,
            strokeWidth: 29.9999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 22.9256,
          }}
          cx="382.501"
          cy="307.5"
          rx="114.5"
          ry="114.5"
        />
        <g>
          <g>
            <path
              className="transition-stroke"
              style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: 'none',
                stroke: strokeColor,
                strokeWidth: 29.9999,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 22.9256,
              }}
              d="M399.512,260.864h-18.183c-11.962,0-21.706,9.724-21.706,21.782c0,8.426,4.462,15.646,11.999,19.414l23.197,11.599"
            />

            <path
              className="transition-stroke"
              style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: 'none',
                stroke: strokeColor,
                strokeWidth: 29.9999,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 22.9256,
              }}
              d="M365.49,354.135h18.183c11.962,0,21.706-9.724,21.706-21.782c0-8.426-4.462-15.646-11.998-19.414l-23.198-11.599"
            />
          </g>

          <line
            className="transition-stroke"
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              fill: 'none',
              stroke: strokeColor,
              strokeWidth: 29.9999,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 22.9256,
            }}
            x1="382.501"
            y1="260.866"
            x2="382.501"
            y2="247.961"
          />

          <line
            className="transition-stroke"
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              fill: 'none',
              stroke: strokeColor,
              strokeWidth: 29.9999,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 22.9256,
            }}
            x1="382.501"
            y1="367.04"
            x2="382.501"
            y2="354.135"
          />
        </g>
      </g>
    </svg>
  );
};
