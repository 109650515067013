import React from 'react';

interface ChatUserAvatarProps {
    name: string;
    height?: number;
    width?: number;
    /*     avatar: string;
        isActive: boolean; */
}

const ChatUserAvatar: React.FC<ChatUserAvatarProps> = ({
    /*     avatar, 
        isActive  */
    height = 36,
    width = 36
}) => {
    return (
        <svg
            height={height}
            width={width}
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <circle
                    cx="32"
                    cy="32"
                    fill="#e6ecff"
                    r="31"
                />
                <g
                    fill="#4294ff"
                ><path
                        d="m56.877 50.4748a31.0647 31.0647 0 0 0 -49.7651-.0156 30.9669 30.9669 0 0 0 49.7651.0156z"
                    /><circle
                        cx="32"
                        cy="22"
                        r="12"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ChatUserAvatar;