import { getCookie } from '../utils/utils';
import { ArticlesOfAssociationBaseInfo } from '../utils/types';
import { BuildingWithConvertedAddresses } from '../utils/types';
import i18n from '../../i18n';

interface SubmitPdfAsBase64Props {
  selectedBuilding: BuildingWithConvertedAddresses;
  base64EncodedArticlesOfAssociation: string | null;
  base64EncodedFinancialStatement: string | null;
  base64EncodedMaintenanceNeedsAssessment: string | null;
  setIsUploading: (isUploading: boolean) => void;
  setAnalyzedArticlesOfAssociationBaseInfo: (
    info: ArticlesOfAssociationBaseInfo,
  ) => void;
  setAnalyzedArticlesOfAssociation: (apartments: any) => void;
  setAnalyzedArticlesOfAssociationApartments: (apartments: any) => void;
  setAnalyzedFinancialStatement: (statement: any) => void;
  setAnalyzedMaintenanceNeedsAssessment: (assessment: any) => void;
}

export const submitPdfAsBase64 = ({
  selectedBuilding,
  base64EncodedArticlesOfAssociation,
  base64EncodedFinancialStatement,
  base64EncodedMaintenanceNeedsAssessment,
  setIsUploading,
  setAnalyzedArticlesOfAssociationBaseInfo,
  setAnalyzedArticlesOfAssociationApartments,
  setAnalyzedArticlesOfAssociation,
  setAnalyzedFinancialStatement,
  setAnalyzedMaintenanceNeedsAssessment,
}: SubmitPdfAsBase64Props) => {
  setIsUploading(true);

  const requests = [];

  if (base64EncodedArticlesOfAssociation) {
    requests.push(
      fetch(
        `${process.env.REACT_APP_API_URL}/report?document=articles_of_association_base_info`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({
            selected_building: selectedBuilding,
            pdf_base64_encoded: base64EncodedArticlesOfAssociation,
          }),
        },
      ),
    );
    requests.push(
      fetch(
        `${process.env.REACT_APP_API_URL}/report?document=articles_of_association`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          credentials: 'include',
          body: JSON.stringify({
            selected_building: selectedBuilding,
            pdf_base64_encoded: base64EncodedArticlesOfAssociation,
          }),
        },
      ),
    );
    requests.push(
      fetch(
        `${process.env.REACT_APP_API_URL}/report?document=articles_of_association_apartments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          credentials: 'include',
          body: JSON.stringify({
            selected_building: selectedBuilding,
            pdf_base64_encoded: base64EncodedArticlesOfAssociation,
          }),
        },
      ),
    );
  }

  if (base64EncodedFinancialStatement) {
    requests.push(
      fetch(
        `${process.env.REACT_APP_API_URL}/report?document=financial_statement`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          credentials: 'include',
          body: JSON.stringify({
            selected_building: selectedBuilding,
            pdf_base64_encoded: base64EncodedFinancialStatement,
          }),
        },
      ),
    );
  }

  if (base64EncodedMaintenanceNeedsAssessment) {
    requests.push(
      fetch(
        `${process.env.REACT_APP_API_URL}/report?document=maintenance_needs_assessment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          credentials: 'include',
          body: JSON.stringify({
            selected_building: selectedBuilding,
            pdf_base64_encoded: base64EncodedMaintenanceNeedsAssessment,
          }),
        },
      ),
    );
  }

  Promise.all(requests)
    .then((responses) => Promise.all(responses.map((res) => res.json())))
    .then((dataArray) => {
      setIsUploading(false);
      dataArray.forEach((data) => {
        if (data.analyzed_document) {
          if (data.document_type === 'articles_of_association_base_info') {
            setAnalyzedArticlesOfAssociationBaseInfo(data.analyzed_document);
          } else if (data.document_type === 'articles_of_association') {
            setAnalyzedArticlesOfAssociation(data.analyzed_document.analyysi);
          } else if (
            data.document_type === 'articles_of_association_apartments'
          ) {
            setAnalyzedArticlesOfAssociationApartments(data.analyzed_document);
          } else if (data.document_type === 'financial_statement') {
            setAnalyzedFinancialStatement(data.analyzed_document.analyysi);
          } else if (data.document_type === 'maintenance_needs_assessment') {
            setAnalyzedMaintenanceNeedsAssessment(
              data.analyzed_document.analyysi,
            );
          }
        }
      });
    })
    .catch((error) => {
      setIsUploading(false);
      console.error('Error:', error);
    });
};

export function getTimeAgo(timestamp: string): string {
  const now = new Date();
  const commentDate = new Date(timestamp);
  const diffMs = now.getTime() - commentDate.getTime();
  const diffMinutes = Math.floor(diffMs / (1000 * 60));

  if (diffMinutes < 1) {
    return i18n.t('timeAgo.justNow');
  }

  if (diffMinutes < 60) {
    return i18n.t('timeAgo.minutes', { count: diffMinutes });
  }

  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return i18n.t('timeAgo.hours', { count: diffHours });
  }

  const diffDays = Math.floor(diffHours / 24);
  return i18n.t('timeAgo.days', { count: diffDays });
}
