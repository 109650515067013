import React, { useState, useRef, useEffect } from 'react';
import './SignupLoginModal.scss';
import { getCookie } from '../utils/utils';
import TagManager from 'react-gtm-module';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  email: string;
  loginError: string;
  toggleSignupLoginModalVisible: () => void;
  onLoginSuccess: (expiresIn: number) => void; // Add this new prop
  setLoginError: (error: string) => void;
  validatePassword: (password: string) => string;
  onForgotPasswordClick: () => void; // Add this prop
};

const Login = (props: Props) => {
  const {
    email,
    loginError,
    toggleSignupLoginModalVisible,
    onLoginSuccess,
    setLoginError,
    validatePassword,
    onForgotPasswordClick, // Destructure the new prop
  } = props;
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [passwordError, setPasswordError] = useState(''); // Add this line

  // Add these references
  const loginPasswordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (loginPasswordInputRef.current) {
      loginPasswordInputRef.current.focus();
    }
  }, []);

  const handleSubmitLogin = async () => {
    setIsLoggingIn(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'login_button_clicked',
        category: 'User Interaction',
        action: 'Click',
        label: 'Login Button',
      },
    });
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify({ email: email.toLowerCase(), password }),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setLoginError(''); // Clear any previous error message
        onLoginSuccess(data.expires_in); // Call this function with the expires_in value
        TagManager.dataLayer({
          dataLayer: {
            event: 'login_success',
            category: 'User Interaction',
            action: 'Success',
            label: 'Login Success',
            userId: email,
          },
        });
        toggleSignupLoginModalVisible(); // Close the modal after successful login
      } else {
        // Handle error response
        console.error('Login failed');
        setLoginError(t('signupLoginModal.checkPassword')); // Set error message
      }
    } catch (error) {
      console.error('Error:', error);
      setLoginError(t('signupLoginModal.unexpectedError')); // Set error message for unexpected errors
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <>
      <div className="signup-login-modal__content">
        <div className="signup-login-modal__content__title">
          <Trans i18nKey="signupLoginModal.login" />
        </div>
      </div>
      <form
        className="signup-login-modal__content__form"
        onSubmit={(e) => {
          e.preventDefault();
          const passwordValidationError = validatePassword(password);
          setPasswordError(passwordValidationError); // Validate password on submit
          if (!passwordValidationError) {
            handleSubmitLogin();
          }
        }}
      >
        <input
          type="password"
          placeholder={t("signupLoginModal.passwordPlaceholder")}
          className="signup-login-modal__content__input"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required // Add this line
          ref={loginPasswordInputRef} // Add this line
          autoComplete="currentPassword" // Add this line
        />
        {passwordError && (
          <div className="signup-login-modal__content__error">
            {passwordError}
          </div>
        )}
        {loginError && (
          <div className="signup-login-modal__content__error">{loginError}</div>
        )}
        <button
          className="signup-login-modal__content__button"
          disabled={isLoggingIn}
          type="submit"
        >
          {isLoggingIn ? (
            <span className="loading-dots">
              <span></span>
            </span>
          ) : (
            <Trans i18nKey="signupLoginModal.loginButton" />
          )}
        </button>
        {/* Add Forgot Password button */}
        <button
          type="button"
          onClick={onForgotPasswordClick}
          className="signup-login-modal__forgot-password-button"
        >
          <Trans i18nKey="signupLoginModal.forgotPassword.linkText" />
        </button>
      </form>
    </>
  );
};

export default Login;
