import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import loadGoogleMapsApi from './loadGoogleMapsApi';

interface GoogleMapsApiContextType {
  isLoaded: boolean;
  loadError: Error | null;
}

const GoogleMapsApiContext = createContext<GoogleMapsApiContextType>({
  isLoaded: false,
  loadError: null,
});

export const useGoogleMapsApi = () => useContext(GoogleMapsApiContext);

interface GoogleMapsApiProviderProps {
  children: ReactNode;
}

export const GoogleMapsApiProvider: React.FC<GoogleMapsApiProviderProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState<Error | null>(null);

  useEffect(() => {
    loadGoogleMapsApi()
      .then(() => {
        console.log('Google Maps API loaded successfully');
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('Failed to load Google Maps API:', error);
        setLoadError(error instanceof Error ? error : new Error(String(error)));
      });
  }, []);

  return (
    <GoogleMapsApiContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsApiContext.Provider>
  );
};
