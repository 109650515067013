import React from 'react';

const StyledHeartIcon: React.FC = () => (
  <svg
    enableBackground="new 0 0 256 256"
    viewBox="0 0 256 256"
    width="26"
    height="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m198 32.5c-3.4-1.1-7-1.8-10.7-2.2-47.2-4.8-59.3 40.2-59.3 40.2s-12.1-45-59.2-40.2c-43.8 4.4-66.8 59.6-38.1 106 8.2 13.3 21 27.6 34.7 40.9 27.5 26.7 58.5 48.9 62.6 48.9 6.2 0 72.5-49.7 97.3-89.7 26.3-42.5 9.3-92.4-27.3-103.9z"
      fill="#fc4f4f"
    />
    <path
      d="m225.3 136.3c26.3-42.4 9.3-92.3-27.3-103.8 0 0 36.5 35.2 15.5 71.4s-91.2 81.2-101.1 82.8c-8.2 1.3-29-.6-47-9.4 27.5 26.7 58.5 48.9 62.6 48.9 6.2-.1 72.5-49.8 97.3-89.9z"
      opacity=".15"
    />
    <ellipse
      cx="50.6"
      cy="65.5"
      fill="#fff"
      opacity=".3"
      rx="24.9"
      ry="12.6"
      transform="matrix(.645 -.764 .764 .645 -32.075 61.903)"
    />
  </svg>
);

export default StyledHeartIcon;
