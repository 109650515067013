import React from 'react';
import FilesDropBox, { DocumentAndFileName } from './FilesDropBox';
import LoadingSpinner from '../common/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';

type DocumentDropzoneProps = {
  documentsAndFileNames: DocumentAndFileName[];
  setDocumentsAndFileNames: (files: DocumentAndFileName[]) => void;
  handleSubmitReportDocuments: () => void;
  setLoginModalVisible: (visible: boolean) => void;
  isUploading: boolean;
  isLoggedIn: boolean;
};

const DocumentDropzone: React.FC<DocumentDropzoneProps> = ({
  documentsAndFileNames,
  setDocumentsAndFileNames,
  handleSubmitReportDocuments,
  setLoginModalVisible,
  isUploading,
  isLoggedIn,
}) => {
  const { t } = useTranslation();
  const buttonText = t('building.documentDropzone.buttonText');

  return (
    <div className="report__dropzone-wrapper">
      <div className="report__dropzone-content">
        <FilesDropBox
          documentsAndFileNames={documentsAndFileNames}
          setDocumentsAndFileNames={setDocumentsAndFileNames}
          isLoggedIn={isLoggedIn}
          setLoginModalVisible={setLoginModalVisible}
        />
        <div className="report__submit-button-wrapper">
          <div className="report__submit-button-wrapper__text">
            <Trans i18nKey="building.documentDropzone.termsAgreement">
              <span>
                By uploading documents, you agree to comply with our{' '}
              </span>
              <a
                href="/terms-conditions"
                target="_blank"
                rel="noreferrer"
                className="report__submit-button-wrapper__text__link"
              >
                terms of service
              </a>
              <span>
                , and acknowledge that you have read and accept Jaakkocom Oy's{' '}
              </span>
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="report__submit-button-wrapper__text__link"
              >
                privacy policy
              </a>
              .
            </Trans>
          </div>
          {isUploading && (
            <div className="report__submit-button-wrapper__loading">
              <div className="report__submit-button-wrapper__loading-spinner">
                <LoadingSpinner />
              </div>
              <div>
                <div>
                  <Trans i18nKey="building.documentDropzone.loading.title">
                    Koostan raporttia tietojen pohjalta.
                  </Trans>
                </div>
                <div className="report__submit-button-wrapper__loading-text">
                  <Trans i18nKey="building.documentDropzone.loading.text">
                    Tässä voi mennä muutama minuutti...
                  </Trans>
                </div>
              </div>
            </div>
          )}
          {!isUploading && (
            <button
              onClick={handleSubmitReportDocuments}
              className="report__submit-button-wrapper__submit-button"
              disabled={
                isUploading || documentsAndFileNames.length === 0 || !isLoggedIn
              }
            >
              {isUploading ? <LoadingSpinner /> : buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentDropzone;
