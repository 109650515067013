import React from 'react';

export const CouldSellIcon = ({
  selected,
  customFillColor,
}: {
  selected?: boolean;
  customFillColor?: string;
}) => {
  const fillColor = selected ? '#ff5e5e' : '#000';

  return (
    <svg
      enableBackground="new 0 0 512 512"
      height="16"
      viewBox="0 0 512 512"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="transition-fill"
        fill={customFillColor ? customFillColor : fillColor}
        d="m498.147 222.58-57.298-57.298v-150.282c0-8.284-6.716-15-15-15h-64.267c-8.284 0-15 6.716-15 15v56.017l-57.174-57.174c-8.926-8.927-20.794-13.843-33.418-13.843-12.625 0-24.494 4.916-33.42 13.843l-208.738 208.739c-18.428 18.427-18.428 48.411 0 66.838 8.927 8.927 20.795 13.843 33.419 13.843 2.645 0 5.253-.229 7.812-.651v154.223c0 30.419 24.748 55.166 55.167 55.166h97.561c8.284 0 15-6.716 15-15v-113.534h66.4v113.533c0 8.284 6.716 15 15 15h97.56c30.419 0 55.166-24.747 55.166-55.166v-154.223c2.558.423 5.165.651 7.81.651h.003c12.622 0 24.49-4.916 33.419-13.844 8.926-8.926 13.842-20.794 13.843-33.418-.002-12.624-4.918-24.493-13.845-33.42zm-121.564-192.58h34.267v105.283l-34.267-34.268zm25.167 452h-82.56v-113.533c0-8.284-6.716-15-15-15h-96.4c-8.284 0-15 6.716-15 15v113.533h-82.561c-13.877 0-25.167-11.289-25.167-25.166v-171.809l170.928-170.924 170.926 170.926v171.808c0 13.876-11.289 25.165-25.166 25.165zm75.186-213.795c-3.263 3.262-7.598 5.058-12.208 5.058-4.609-.001-8.944-1.796-12.204-5.055-.001-.001-.002-.002-.004-.004l-185.923-185.923c-5.856-5.859-15.354-5.857-21.213 0l-185.925 185.922c-.002.002-.003.003-.005.005-3.26 3.26-7.593 5.055-12.203 5.055s-8.945-1.795-12.206-5.056c-6.73-6.73-6.73-17.682 0-24.412l208.738-208.739c3.26-3.26 7.596-5.056 12.207-5.056 4.61 0 8.945 1.796 12.205 5.056l82.781 82.78 125.958 125.957c6.731 6.73 6.731 17.683.002 24.412z"
      />
    </svg>
  );
};
