import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import './ResetPasswordPage.scss'; // We'll create this for styling

// Password validation function (copied from SignupLoginModal for now,
// consider moving to a shared utils file)
const validatePassword = (password: string, t: (key: string) => string) => {
    if (password.length < 8) {
      return t('signupLoginModal.passwordRequirements.length');
    }
    if (!/[A-Z]/.test(password)) {
      return t('signupLoginModal.passwordRequirements.uppercase');
    }
    if (!/[a-z]/.test(password)) {
      return t('signupLoginModal.passwordRequirements.lowercase');
    }
    if (!/[0-9]/.test(password)) {
      return t('signupLoginModal.passwordRequirements.number');
    }
    return '';
  };

const ResetPasswordPage = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const newPasswordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus the first input field on mount
    if (newPasswordRef.current) {
      newPasswordRef.current.focus();
    }
    if (!token) {
        setApiError(t('signupLoginModal.resetPasswordPage.tokenMissing'));
    }

    // Add the class to body when component mounts
    document.body.classList.add('reset-password-page-wrapper');

    // Remove the class when component unmounts
    return () => {
      document.body.classList.remove('reset-password-page-wrapper');
    };
  }, [token, t]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordError('');
    setConfirmPasswordError('');
    setApiError('');

    if (!token) {
        setApiError(t('signupLoginModal.resetPasswordPage.tokenMissing'));
        return;
    }

    // Frontend validation
    const passwordValidationError = validatePassword(newPassword, t);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(t('signupLoginModal.resetPasswordPage.passwordMismatch'));
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, new_password: newPassword }),
        }
      );

      if (response.ok) {
        setIsSuccess(true);
      } else {
        const errorData = await response.json();
        if (response.status === 400 && (errorData.error === 'password_reset_link_expired' || errorData.error === 'invalid_password_reset_link')) {
            setApiError(t('signupLoginModal.resetPasswordPage.invalidLink'));
        } else {
            console.error('Password reset failed:', errorData);
            setApiError(t('signupLoginModal.unexpectedError'));
        }
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setApiError(t('signupLoginModal.unexpectedError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoToLogin = () => {
    // Navigate to the home page, which should trigger the login modal if needed
    // Or navigate directly to a login page if you have one
    history.push('/');
    // Consider if you need to explicitly open the login modal here
  };

  if (isSuccess) {
    return (
      <div className="reset-password-page reset-password-page--success">
        <h2><Trans i18nKey="signupLoginModal.resetPasswordPage.title" /></h2>
        <p className="reset-password-page__message reset-password-page__message--success">
          <Trans i18nKey="signupLoginModal.resetPasswordPage.successMessage" />
        </p>
        <button onClick={handleGoToLogin} className="reset-password-page__button">
          <Trans i18nKey="signupLoginModal.resetPasswordPage.goToLogin" />
        </button>
      </div>
    );
  }

  return (
    <div className="reset-password-page">
      <div className="reset-password-page__logo">
        <img src="/assets/jaakko_head.png" alt="Jaakko Logo" height="78px" />
      </div>
      <h2><Trans i18nKey="signupLoginModal.resetPasswordPage.title" /></h2>
      {apiError && (
        <p className="reset-password-page__message reset-password-page__message--error">
          {apiError}
        </p>
      )}
      {/* Show link to login only if there's an API error (like invalid token) */} 
      {apiError && apiError !== t('signupLoginModal.unexpectedError') && (
         <button onClick={handleGoToLogin} className="reset-password-page__link-button">
           <Trans i18nKey="signupLoginModal.resetPasswordPage.goToLogin" />
         </button>
      )}
      {!apiError && (
          <form onSubmit={handleSubmit} className="reset-password-page__form">
            <div className="reset-password-page__form-group">
              <label htmlFor="newPassword"><Trans i18nKey="signupLoginModal.resetPasswordPage.newPasswordPlaceholder" /></label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => {
                    setNewPassword(e.target.value);
                    if(passwordError) setPasswordError('');
                }}
                required
                ref={newPasswordRef}
                className={passwordError ? 'input-error' : ''}
                aria-describedby="passwordError"
              />
              {passwordError && (
                <p id="passwordError" className="reset-password-page__error-text">
                  {passwordError}
                </p>
              )}
            </div>

            <div className="reset-password-page__form-group">
              <label htmlFor="confirmPassword"><Trans i18nKey="signupLoginModal.resetPasswordPage.confirmPasswordPlaceholder" /></label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if(confirmPasswordError) setConfirmPasswordError('');
                }}
                required
                className={confirmPasswordError ? 'input-error' : ''}
                aria-describedby="confirmPasswordError"
              />
              {confirmPasswordError && (
                <p id="confirmPasswordError" className="reset-password-page__error-text">
                  {confirmPasswordError}
                </p>
              )}
            </div>

            <button type="submit" className="reset-password-page__button" disabled={isLoading || !token}>
              {isLoading ? (
                 // Simple loading text for now
                'Loading...'
              ) : (
                <Trans i18nKey="signupLoginModal.resetPasswordPage.submitButton" />
              )}
            </button>
          </form>
      )}
    </div>
  );
};

export default ResetPasswordPage; 