import { getCookie } from '../utils/utils';
import {
  ArticlesOfAssociationApartments,
  ArticlesOfAssociationBaseInfo,
  ArticlesOfAssociationCommercialSpaces,
} from '../utils/types';
import { BuildingWithConvertedAddresses } from '../utils/types';

interface FetchExistingReportProps {
  selectedBuilding: BuildingWithConvertedAddresses;
  setAnalyzedArticlesOfAssociationBaseInfo: (
    info: ArticlesOfAssociationBaseInfo | null,
  ) => void;
  setAnalyzedArticlesOfAssociation: (data: string | null) => void;
  setAnalyzedArticlesOfAssociationApartments: (
    data: ArticlesOfAssociationApartments | null,
  ) => void;
  setAnalyzedArticlesOfAssociationCommercialSpaces: (
    data: ArticlesOfAssociationCommercialSpaces | null,
  ) => void;
  setAnalyzedFinancialStatement: (data: string | null) => void;
  setAnalyzedMaintenanceNeedsAssessment: (data: string | null) => void;
  setIsLoadingExistingReport: (isLoading: boolean) => void;
  setEmptyReport: (data: any) => void;
  setIsLoadingEmptyReport: (isLoading: boolean) => void;
  language: string;
}

export const fetchExistingReport = ({
  selectedBuilding,
  setAnalyzedArticlesOfAssociationBaseInfo,
  setAnalyzedArticlesOfAssociation,
  setAnalyzedArticlesOfAssociationApartments,
  setAnalyzedArticlesOfAssociationCommercialSpaces,
  setAnalyzedFinancialStatement,
  setAnalyzedMaintenanceNeedsAssessment,
  setIsLoadingExistingReport,
  setIsLoadingEmptyReport,
  setEmptyReport,
  language,
}: FetchExistingReportProps) => {
  setIsLoadingExistingReport(true);
  setIsLoadingEmptyReport(true);

  fetch(
    `${process.env.REACT_APP_API_URL}/report?building-id=${selectedBuilding.id}`,
    {
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
    },
  )
    .then((res) => {
      // if (res.status === 404) {
      /*         setIsLoadingEmptyReport(true);
              fetchEmptyReport(
                selectedBuilding,
                setEmptyReport,
                setIsLoadingEmptyReport,
                setIsLoadingExistingReport,
                setLikeCount,
                setUserHasLiked
              ); */
      // return null;
      // }
      return res.json();
    })
    .then((res) => {
      // Building exists but no report
      /*       if (
              res &&
              res.data &&
              (res.data.report_is_empty || res.data.properties.report_is_empty)
            ) {
              setIsLoadingEmptyReport(true);
              fetchEmptyReport(
                selectedBuilding,
                setEmptyReport,
                setIsLoadingEmptyReport,
                setIsLoadingExistingReport,
                setLikeCount,
                setUserHasLiked
              );
              return null;
            } */
      return res;
    })
    .then((res) => {
      if (res && res.data) {
        const data = res.data;
        setAnalyzedArticlesOfAssociationBaseInfo(
          data.articles_of_association_base_info,
        );
        setAnalyzedArticlesOfAssociation(data.articles_of_association);
        setAnalyzedArticlesOfAssociationApartments(
          data.articles_of_association_apartments,
        );
        setAnalyzedArticlesOfAssociationCommercialSpaces(
          data.articles_of_association_commercial_spaces,
        );
        setAnalyzedFinancialStatement(data.financial_statement);
        setAnalyzedMaintenanceNeedsAssessment(
          data.maintenance_needs_assessment,
        );
        /*         if (data.empty_report && data.empty_report.analyysi) {
                  setEmptyReport(data.empty_report);
                } */
      }
      setIsLoadingExistingReport(false);
      fetchEmptyReport(
        selectedBuilding,
        setEmptyReport,
        setIsLoadingEmptyReport,
        language,
      );
    })
    .catch((error) => {
      console.error('Error fetching existing report:', error);
      setIsLoadingExistingReport(false);
    });
};

interface FetchJaakkoCommentProps {
  selectedBuilding: BuildingWithConvertedAddresses;
  setEmptyReport: (data: any) => void;
  setIsLoadingEmptyReport: (isLoading: boolean) => void;
  language: string;
}

export const fetchJaakkoComment = ({
  selectedBuilding,
  setIsLoadingEmptyReport,
  setEmptyReport,
  language,
}: FetchJaakkoCommentProps) => {
  setIsLoadingEmptyReport(true);
  fetchEmptyReport(
    selectedBuilding,
    setEmptyReport,
    setIsLoadingEmptyReport,
    language,
  );
};

export const fetchEmptyReport = (
  selectedBuilding: BuildingWithConvertedAddresses,
  setEmptyReport: (data: any) => void,
  setIsLoadingEmptyReport: (isLoading: boolean) => void,
  language: string,
) => {
  // Normalize language code by taking only the primary part (e.g., 'fi' from 'fi-FI')
  const primaryLanguage = language.split('-')[0];
  
  fetch(`${process.env.REACT_APP_API_URL}/generate-empty-report`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCookie('csrf_access_token'),
    },
    body: JSON.stringify({
      selected_building: selectedBuilding,
      language: primaryLanguage,
    }),
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.empty_report) {
        setEmptyReport(res.empty_report);
      }
    })
    .catch((error) => {
      console.error('Error fetching empty report:', error);
    })
    .finally(() => {
      setIsLoadingEmptyReport(false);
    });
};
