import React from 'react';

const ZoomIcon: React.FC = () => (
  <svg
    height="22"
    viewBox="0 0 128 128"
    width="22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon">
      <path
        fill="#fff"
        id="XMLID_231_"
        d="m122.732 10.606-.002 26.701c0 2.949-2.39 5.338-5.338 5.338-2.949 0-5.337-2.389-5.337-5.339v-13.8l-31.019 31.046c-1.044 1.045-2.412 1.564-3.779 1.564-1.365 0-2.73-.52-3.773-1.563-2.085-2.083-2.087-5.463-.003-7.548l31.029-31.059h-13.797c-2.949 0-5.339-2.391-5.339-5.341 0-2.948 2.391-5.339 5.339-5.339h26.679c1.417 0 2.774.563 3.776 1.566 1.002 1.001 1.564 2.361 1.564 3.774zm-5.339 74.746c-2.95 0-5.338 2.394-5.338 5.341v13.8l-30.499-30.239c-2.087-2.085-5.61-2.087-7.695-.002-2.086 2.082-2.158 5.464-.075 7.548l30.191 30.254h-13.812-.011c-2.949 0-5.342 2.389-5.343 5.339-.001 2.946 2.385 5.338 5.335 5.338l26.675.003c1.418 0 3.062-.563 4.061-1.564 1.001-1.001 1.851-2.359 1.851-3.774v-26.704c-.001-2.947-2.392-5.34-5.34-5.34zm-70.951-11.333-30.496 30.476v-13.803c0-2.949-2.343-5.334-5.291-5.334h.022c-2.949 0-5.328 2.388-5.328 5.334l.002 26.7c0 1.417.565 2.775 1.566 3.776 1.003 1.002 2.363 1.564 3.779 1.564h26.68c2.949 0 5.339-2.391 5.339-5.339 0-2.951-2.391-5.341-5.339-5.341h-13.793l30.458-30.486c2.087-2.084 2.06-5.469-.025-7.551s-5.488-2.08-7.574.004zm-22.953-57.592h13.795c2.949 0 5.338-2.388 5.338-5.336 0-2.95-2.39-5.341-5.338-5.341l-26.677-.002h-.001c-1.417 0-2.773.56-3.776 1.561-1 1.001-1.563 2.358-1.563 3.777l.003 26.702c0 2.945 2.39 5.339 5.338 5.339 2.949 0 5.338-2.394 5.338-5.339v-13.802l30.776 30.801c1.043 1.044 2.411 1.566 3.778 1.566 1.364 0 2.731-.52 3.773-1.563 2.086-2.083 2.089-5.466.003-7.553z"
      />
    </g>
  </svg>
);

export default ZoomIcon;
