import React from 'react';

const RefreshIcon: React.FC = () => (
  <svg
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
  >
    <g id="timeline" />
    <g id="navigation" />
    <g id="align_center" />
    <g id="align_left_1_" />
    <g id="align_left" />
    <g id="more" />
    <g id="share" />
    <g id="music" />
    <g id="camera" />
    <g id="print" />
    <g id="wifi_1_" />
    <g id="setting" />
    <g id="composed" />
    <g id="password" />
    <g id="list" />
    <g id="voice" />
    <g id="open_folder" />
    <g id="refresh">
      <path d="m19.6025 12.6348c-.5586-.085-1.0547.2979-1.1348.8438-.2012 1.3711-.834 2.6221-1.8301 3.6182-2.5352 2.5352-6.6572 2.5332-9.1914 0-2.5337-2.5342-2.5337-6.6577 0-9.1914.9531-.9526 2.1563-1.5737 3.5029-1.7998.5791-.1099 1.2017-.1289 1.8477-.0557.887.1021 1.7126.3964 2.466.8285l-1.3019.2223c-.5439.0933-.9102.6099-.8164 1.1543.083.4873.5059.8315.9844.8315.0557 0 .1123-.0044.1699-.0142l3.4902-.5967c.2607-.0449.4941-.1914.6475-.4082.1533-.2163.2139-.4849.1689-.7466l-.5977-3.4897c-.0918-.5439-.6016-.9082-1.1543-.8169-.5439.0933-.9102.6104-.8164 1.1548l.1573.9185c-.9679-.543-2.0356-.8943-3.17-1.0249-.8496-.0967-1.6738-.0698-2.4282.0747-1.7368.291-3.3149 1.105-4.564 2.354-3.3135 3.3135-3.3135 8.7051 0 12.0195 1.6567 1.6572 3.8335 2.4854 6.0098 2.4854 2.1768 0 4.3525-.8281 6.0098-2.4854 1.3018-1.3018 2.1299-2.9414 2.3945-4.7412.0802-.5469-.2978-1.0548-.8437-1.1348z" />
    </g>
    <g id="link" />
    <g id="attach" />
    <g id="email" />
    <g id="bookmark" />
    <g id="folder" />
    <g id="file" />
    <g id="notification" />
    <g id="galery" />
    <g id="delete_1_" />
    <g id="update" />
    <g id="upload" />
    <g id="download" />
    <g id="menu" />
    <g id="down" />
    <g id="up" />
    <g id="backward" />
    <g id="forward" />
    <g id="zoom_out" />
    <g id="zoom_in" />
    <g id="search" />
    <g id="home" />
    <g id="user" />
    <g id="guide" />
  </svg>
);

export default RefreshIcon;
