import React from 'react';

interface OffMarketBuyIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const OffMarketBuyIcon = ({ width = '32px', height = '32px', className }: OffMarketBuyIconProps) => (
  <svg
    id="Layer_1"
    enableBackground="new 0 0 512 512"
    viewBox="0 0 512 512"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g>
      <path
        d="m386.23 483.09c69.27 0 125.77-56.42 125.77-125.61s-56.49-125.64-125.77-125.64-125.8 56.45-125.8 125.64 56.53 125.61 125.8 125.61z"
        fill="#ffb125"
      />
      <path
        d="m386.23 456.08c-54.52 0-98.72-44.14-98.72-98.59s44.2-98.59 98.72-98.59 98.72 44.14 98.72 98.59-44.2 98.59-98.72 98.59z"
        fill="#ffe177"
      />
      <path
        d="m386.32 349.34c-11.15-.12-18.06-4.71-18.02-12 .04-6.94 6.51-14.31 18.44-14.31 8.24 0 15.53 5.52 17.73 13.43 1.21 4.34 5.7 6.87 10.04 5.66s6.87-5.7 5.66-10.04c-3.46-12.42-13.56-21.72-25.79-24.5v-7.48c0-4.5-3.65-8.15-8.15-8.15s-8.15 3.65-8.15 8.15v7.51c-16.41 3.5-26.02 16.75-26.08 29.63-.07 13.5 10.59 28.14 34.15 28.39 11.15.12 18.05 4.71 18.02 12-.04 6.94-6.52 14.32-18.47 14.32-8.22 0-15.5-5.52-17.7-13.43-1.21-4.34-5.7-6.87-10.04-5.67-4.34 1.21-6.87 5.7-5.67 10.04 3.46 12.43 13.56 21.73 25.79 24.5v7.47c0 4.5 3.65 8.15 8.15 8.15s8.15-3.65 8.15-8.15v-7.52c16.41-3.51 26.01-16.75 26.08-29.63.07-13.49-10.58-28.13-34.14-28.37z"
        fill="#ffb125"
      />
      <path
        d="m386.23 258.9h-.01v197.18h.01c54.52 0 98.72-44.14 98.72-98.59s-44.2-98.59-98.72-98.59z"
        fill="#ffd064"
      />
      <g fill="#ff901d">
        <path d="m394.38 414.87v-7.52c16.41-3.51 26.01-16.75 26.08-29.63.07-13.5-10.59-28.14-34.15-28.38-.03 0-.07 0-.1 0v16.3c11.11.13 17.99 4.73 17.95 11.99-.04 6.84-6.34 14.08-17.95 14.3v31.08h.02c4.5.01 8.15-3.64 8.15-8.14z" />
        <path d="m394.38 307.58v-7.48c0-4.5-3.65-8.15-8.15-8.15-.01 0-.01 0-.02 0v31.08c.18 0 .34-.02.52-.02 8.24 0 15.53 5.52 17.73 13.43 1.21 4.34 5.7 6.87 10.04 5.66s6.87-5.7 5.66-10.04c-3.44-12.4-13.54-21.7-25.78-24.48z" />
      </g>
      <path
        d="m386.23 231.85h-.01v27.05h.01c54.52 0 98.72 44.14 98.72 98.59s-44.2 98.59-98.72 98.59h-.01v27.02h.01c69.27 0 125.77-56.42 125.77-125.61s-56.49-125.64-125.77-125.64z"
        fill="#ff901d"
      />
      <g>
        <path
          d="m270.13 124.95-130.1-94.84c-2.2-1.6-5.18-1.6-7.38 0l-130.08 94.84c-2.8 2.04-3.41 5.96-1.37 8.76l15.05 20.6c2.04 2.79 5.96 3.4 8.75 1.37l15.72-11.46v131.4c0 3.46 2.8 6.26 6.26 6.26h178.69c3.46 0 6.26-2.8 6.26-6.26v-131.43l15.76 11.49c2.79 2.04 6.71 1.43 8.75-1.37l15.05-20.6c2.05-2.79 1.44-6.72-1.36-8.76z"
          fill="#e1effb"
        />
        <path
          d="m103.2 281.88h66.27v-96.07c0-3.46-2.8-6.26-6.26-6.26h-53.74c-3.46 0-6.26 2.8-6.26 6.26v96.07z"
          fill="#f1ba84"
        />
        <path
          d="m163.21 179.55h-33.14c3.46 0 6.26 2.8 6.26 6.26v96.07h33.14v-96.07c0-3.46-2.8-6.26-6.26-6.26z"
          fill="#de9252"
        />
        <path
          d="m132.65 30.11-130.08 94.84c-2.8 2.04-3.41 5.96-1.37 8.76l15.05 20.6c2.04 2.79 5.96 3.4 8.75 1.37l111.33-81.19 111.36 81.19c2.79 2.04 6.71 1.43 8.75-1.37l15.05-20.6c2.04-2.8 1.43-6.72-1.37-8.76l-130.09-94.84c-2.2-1.61-5.18-1.61-7.38 0z"
          fill="#ff4c4c"
        />
        <path
          d="m270.13 124.95-2.35-1.71-11.35 15.54c-2.04 2.79-5.96 3.4-8.75 1.37l-111.36-81.2-111.33 81.19c-2.79 2.04-6.71 1.43-8.75-1.37l-11.34-15.51-2.33 1.7c-2.8 2.04-3.41 5.96-1.37 8.76l15.05 20.6c2.04 2.79 5.96 3.4 8.75 1.37l111.33-81.19 111.36 81.19c2.79 2.04 6.71 1.43 8.75-1.37l15.05-20.6c2.05-2.8 1.44-6.73-1.36-8.77z"
          fill="#f33939"
        />
        <path
          d="m136.34 74.49-7.34 5.35 88.26 64.35v131.42c0 3.46-2.8 6.26-6.26 6.26h14.69c3.46 0 6.26-2.8 6.26-6.26v-131.42z"
          fill="#ccdfed"
        />
      </g>
      <g>
        <g fill="#2d5871">
          <path d="m211.08 462.51h-59.89c-23.6 0-42.79-19.17-42.79-42.73v-37.63l19.96 19.94c1.56 1.56 3.61 2.34 5.65 2.34 2.05 0 4.1-.78 5.66-2.35 3.12-3.13 3.12-8.19-.01-11.31l-33.62-33.57c-3.12-3.12-8.19-3.12-11.31 0l-33.59 33.57c-3.12 3.12-3.13 8.19 0 11.31 3.12 3.12 8.19 3.13 11.31 0l19.93-19.93v37.62c0 32.38 26.37 58.73 58.79 58.73h59.89c4.42 0 8-3.58 8-8s-3.56-7.99-7.98-7.99z" />
          <path d="m461.24 109.95c-3.12-3.13-8.19-3.13-11.31-.01l-19.94 19.91v-37.63c0-32.38-26.37-58.73-58.79-58.73h-59.86c-4.42 0-8 3.58-8 8s3.58 8 8 8h59.86c23.6 0 42.79 19.17 42.79 42.73v37.63l-19.94-19.91c-3.13-3.12-8.19-3.12-11.31.01s-3.12 8.19.01 11.31l33.59 33.55c1.56 1.56 3.61 2.34 5.65 2.34 2.05 0 4.09-.78 5.65-2.34l33.59-33.55c3.13-3.12 3.13-8.18.01-11.31z" />
        </g>
      </g>
    </g>
  </svg>
);

export default OffMarketBuyIcon;
