import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory or useNavigate based on your React Router version

const PrivacyPolicyRedirect = () => {
  const history = useHistory(); // For React Router v5 (or use useNavigate for v6)
  const [popupBlocked, setPopupBlocked] = useState(false);
  const googleDocsUrl = 'https://docs.google.com/document/d/11s7hgEa1fcw0WOKYB3aBDb-H_xnJqHJ4-Ni1m83jsVI/edit?usp=sharing';
  
  useEffect(() => {
    // Try to open the Google Doc in a new tab
    const newWindow = window.open(googleDocsUrl, '_blank', 'noopener,noreferrer');
    
    // Check if popup was blocked
    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      console.warn('Popup was blocked or failed to open');
      setPopupBlocked(true);
    } else {
      // Give the new window a moment to open before redirecting
      const timeoutId = setTimeout(() => {
        history.push('/');
      }, 500);
      
      // Return cleanup function
      return () => {
        // Cleanup if component unmounts before timeout completes
        clearTimeout(timeoutId);
      };
    }
  }, [history, googleDocsUrl]);

  // If popup is blocked, show a message with a manual link
  if (popupBlocked) {
    return (
      <div className="redirect-container">
        <p>Your browser blocked the automatic redirect. Please click the link below:</p>
        <a 
          href={googleDocsUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setTimeout(() => history.push('/'), 100)}
        >
          Open Privacy Policy
        </a>
      </div>
    );
  }

  // Default loading state
  return (
    <div className="redirect-container">
      <p>Redirecting to Privacy Policy...</p>
    </div>
  );
};

export default PrivacyPolicyRedirect;