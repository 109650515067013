import React from 'react';

interface OffMarketSellIconProps {
  width?: string;
  height?: string;
  className?: string;
}

const OffMarketSellIcon = ({ width = '31px', height = '31px', className }: OffMarketSellIconProps) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width={width}
    height={height}
    className={className}
  >
    <g>
      <g>
        <path
          style={{ fill: '#FDBEB9' }}
          d="M448.181,222.014H391.5V144.04c0-9.537,7.731-17.268,17.268-17.268h22.144 c9.537,0,17.268,7.731,17.268,17.268V222.014z"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="459.281,245.562 459.281,502 127.337,502 127.337,248.105 293.309,151.151"
        />
        <path
          style={{ fill: '#D2DCFD' }}
          d="M197.332,311.224c0-44.356,11.378-86.054,31.375-122.335l-101.37,59.217V502h156.399 C230.767,455.484,197.332,387.258,197.332,311.224z"
        />
        <path
          style={{ fill: '#FD6050' }}
          d="M468.666,289.271c-5.553,0-11.157-1.675-16.006-5.154L293.309,169.78l-159.35,114.336 c-12.284,8.816-29.419,6.046-38.272-6.185c-8.853-12.229-6.071-29.289,6.213-38.103L277.28,113.99 c9.573-6.87,22.486-6.87,32.059,0l175.38,125.839c12.284,8.814,15.066,25.874,6.213,38.103 C485.573,285.333,477.18,289.27,468.666,289.271z"
        />
        <path
          style={{ fill: '#DB4B86' }}
          d="M231.599,146.766l-129.7,93.062c-12.284,8.814-15.066,25.874-6.213,38.103 c8.853,12.232,25.987,15.001,38.272,6.185l41.651-29.885C184.661,213.394,204.341,176.555,231.599,146.766z"
        />
        <rect
          x="175.209"
          y="350.718"
          style={{ fill: '#67CCD1' }}
          width="92.941"
          height="75.722"
        />
        <path
          style={{ fill: '#27A2CC' }}
          d="M200.387,350.718h-25.178v75.722h49.721C212.999,403.061,204.585,377.588,200.387,350.718z"
        />
        <ellipse
          style={{ fill: '#FDCB50' }}
          cx="115.074"
          cy="108.524"
          rx="99.181"
          ry="98.524"
        />
        <path
          style={{ fill: '#FD8F01' }}
          d="M165.382,181.698c-54.776,0-99.181-44.111-99.181-98.525c0-28.195,11.923-53.624,31.033-71.583 c-46.261,8.347-81.34,48.571-81.34,96.934c0,54.413,44.405,98.524,99.181,98.524c26.393,0,50.378-10.241,68.149-26.942 C177.435,181.152,171.473,181.698,165.382,181.698z"
        />
        <rect
          x="317.187"
          y="350.718"
          style={{ fill: '#FDBEB9' }}
          width="94.222"
          height="151.282"
        />
        <ellipse
          style={{ fill: '#BC9AF4' }}
          cx="293.309"
          cy="269.854"
          rx="34.281"
          ry="34.13"
        />
        <path
          style={{ fill: '#27A2CC' }}
          d="M133.774,457.984c0.471-2.275,0.72-4.631,0.72-7.045c0-19.073-15.461-34.534-34.534-34.534 c-19.073,0-34.534,15.461-34.534,34.534c0,1.947,0.161,3.857,0.471,5.716H54.557c-12.522,0-22.673,10.151-22.673,22.673v0 c0,12.522,10.151,22.672,22.673,22.672h71.551c12.522,0,22.673-10.151,22.673-22.672v0 C148.781,469.496,142.523,461.127,133.774,457.984z"
        />
      </g>
      <g>
        <g>
          <g>
            <ellipse
              style={{
                fill: 'none',
                stroke: '#052A75',
                strokeWidth: 20,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
                strokeMiterlimit: 10,
              }}
              cx="115.074"
              cy="108.524"
              rx="99.181"
              ry="98.524"
            />
            <g>
              <path
                style={{
                  fill: 'none',
                  stroke: '#FFFFFF',
                  strokeWidth: 20,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                }}
                d="M131.545,80.43c-3.712-5.255-13.048-9.161-25.249-5.51c-11.203,3.353-16.224,21.162-1.611,28.007 c2.293,1.074,8.168,3.367,17.033,6.48c19.841,6.967,20.493,32.853-3.055,34.896c-7.846,0.681-19.036-1.415-24.989-8.757"
              />
              <line
                style={{
                  fill: 'none',
                  stroke: '#FFFFFF',
                  strokeWidth: 20,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                }}
                x1="114.105"
                y1="145.119"
                x2="114.105"
                y2="151.151"
              />
              <line
                style={{
                  fill: 'none',
                  stroke: '#FFFFFF',
                  strokeWidth: 20,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                }}
                x1="114.105"
                y1="65.898"
                x2="114.105"
                y2="73.069"
              />
            </g>
          </g>
        </g>
        <path
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          d="M391.5,169.894V144.04c0-9.537,7.731-17.268,17.268-17.268h22.144c9.537,0,17.268,7.731,17.268,17.268v65.173"
        />
        <g>
          <path
            style={{
              fill: 'none',
              stroke: '#052A75',
              strokeWidth: 20,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
            }}
            d="M468.666,289.271c-5.553,0-11.157-1.675-16.006-5.154L293.309,169.78l-159.35,114.336c-12.284,8.816-29.419,6.046-38.272-6.185 c-8.853-12.229-6.071-29.289,6.213-38.103L277.28,113.99c9.573-6.87,22.486-6.87,32.059,0l175.38,125.839 c12.284,8.814,15.066,25.874,6.213,38.103C485.573,285.333,477.18,289.27,468.666,289.271z"
          />
        </g>
        <polyline
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          points="317.187,495 317.187,350.718 411.409,350.718 411.409,495"
        />
        <rect
          x="175.209"
          y="350.718"
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          width="92.941"
          height="75.722"
        />
        <ellipse
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          cx="293.309"
          cy="269.854"
          rx="34.281"
          ry="34.13"
        />
        <path
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          d="M133.774,457.984c0.471-2.275,0.72-4.631,0.72-7.045c0-19.073-15.461-34.534-34.534-34.534c-19.073,0-34.534,15.461-34.534,34.534 c0,1.947,0.161,3.857,0.471,5.716H54.557c-12.522,0-22.673,10.151-22.673,22.673v0c0,12.522,10.151,22.672,22.673,22.672h71.551 c12.522,0,22.673-10.151,22.673-22.672v0C148.781,469.496,142.523,461.127,133.774,457.984z"
        />
        <line
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          x1="127.337"
          y1="427.596"
          x2="127.337"
          y2="291.255"
        />
        <polyline
          style={{
            fill: 'none',
            stroke: '#052A75',
            strokeWidth: 20,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          points="459.281,289.271 459.281,502 127.337,502"
        />
      </g>
    </g>
  </svg>
);

export default OffMarketSellIcon;
