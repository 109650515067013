// Function to load Google Maps API dynamically
const loadGoogleMapsApi = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    // Check if the API is already loaded
    if (window.google && window.google.maps && window.google.maps.places) {
      resolve();
      return;
    }

    // Create the script element
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    // Set up callbacks
    script.onload = () => {
      // Add a small delay to ensure the API is fully initialized
      setTimeout(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
          resolve();
        } else {
          reject(new Error('Google Maps API loaded but places library not available'));
        }
      }, 100);
    };
    
    script.onerror = () => {
      reject(new Error('Google Maps API failed to load'));
    };

    // Append the script to the document
    document.head.appendChild(script);
  });
};

export default loadGoogleMapsApi;