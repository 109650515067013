// Utility function to get a cookie by name
export function getCookie(name: string) {
    const cookieStr = document.cookie;
    const cookies = cookieStr.split(';');
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }