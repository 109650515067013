import React from 'react';

const MiniMenuIcon = ({
  size = 42.667,
  color = 'black',
}: {
  size?: number;
  color?: string;
}) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 426.667 426.667"
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <g>
        <g>
          <circle cx="42.667" cy="213.333" r="42.667" fill={color} />
        </g>
      </g>
      <g>
        <g>
          <circle cx="213.333" cy="213.333" r="42.667" fill={color} />
        </g>
      </g>
      <g>
        <g>
          <circle cx="384" cy="213.333" r="42.667" fill={color} />
        </g>
      </g>
    </svg>
  );
};

export default MiniMenuIcon;
