import React from 'react';

const ApartmentIcon: React.FC = () => (
  <svg
    width="51.75"
    height="51.75"
    viewBox="0 0 51.75 51.75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6903 42.3191H18.2282M32.4293 42.3191H39.6685C40.4969 42.3191 41.1685 41.6475 41.1685 40.8191V17.3617M41.1685 8.6808V2.58508C41.1685 1.75669 40.4969 1.08508 39.6685 1.08508H2.25C1.42161 1.08508 0.75 1.75669 0.75 2.58508V40.8191C0.75 41.6475 1.42161 42.3191 2.25 42.3191H7.30435"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14648 30.4628C9.14648 29.6344 9.81809 28.9628 10.6465 28.9628H14.8856C15.714 28.9628 16.3856 29.6344 16.3856 30.4628V38.9841C16.3856 39.8125 15.714 40.4841 14.8856 40.4841H10.6465C9.81809 40.4841 9.14648 39.8125 9.14648 38.9841V30.4628Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <rect
      x="25.125"
      y="1.5"
      width="10.4362"
      height="19.2554"
      rx="1.5"
      transform="rotate(90 25.125 1.5)"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <rect
      x="25.125"
      y="1.5"
      width="10.4362"
      height="5.05435"
      rx="1.5"
      transform="rotate(90 25.125 1.5)"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <rect
      x="10.9241"
      y="1.5"
      width="10.4362"
      height="5.05435"
      rx="1.5"
      transform="rotate(90 10.9241 1.5)"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <rect
      x="25.125"
      y="6.51068"
      width="19.2554"
      height="5.01064"
      rx="1.5"
      transform="rotate(-180 25.125 6.51068)"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <path
      d="M32.4295 43.2837V43.2837C31.7329 47.205 28.6164 50.2472 24.6794 50.8489L23.6903 51L23.6903 42.3191"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.1975 17.3615V17.3615C36.2501 16.6696 33.182 13.5401 32.5687 9.57968L32.4295 8.68058L41.1686 8.68058"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.1687 5.42551H49.5002C50.3286 5.42551 51.0002 6.09712 51.0002 6.92551V24C51.0002 24.8284 50.3286 25.5 49.5002 25.5H41.1687"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.55438 34.7234C6.55438 35.503 5.91674 36.1436 5.1196 36.1436C4.32246 36.1436 3.68482 35.503 3.68482 34.7234C3.68482 33.9438 4.32246 33.3032 5.1196 33.3032C5.91674 33.3032 6.55438 33.9438 6.55438 34.7234Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <path
      d="M21.8481 34.7234C21.8481 35.503 21.2105 36.1436 20.4134 36.1436C19.6162 36.1436 18.9786 35.503 18.9786 34.7234C18.9786 33.9438 19.6162 33.3032 20.4134 33.3032C21.2105 33.3032 21.8481 33.9438 21.8481 34.7234Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
    <line
      x1="26.625"
      y1="23.1223"
      x2="40.4185"
      y2="23.1223"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1.5"
      y1="23.1223"
      x2="7.64678"
      y2="23.1223"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="35.3642"
      y1="23.5372"
      x2="35.3642"
      y2="41.5691"
      stroke="black"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6628 17.9042C19.6628 20.1821 17.8025 22.0373 15.497 22.0373C13.1915 22.0373 11.3312 20.1821 11.3312 17.9042C11.3312 15.6263 13.1915 13.7711 15.497 13.7711C17.8025 13.7711 19.6628 15.6263 19.6628 17.9042Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
    />
  </svg>
);

export default ApartmentIcon;
