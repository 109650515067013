import React from 'react';

const CommercialSpaceIcon: React.FC = () => (
  <svg
    width="45.75"
    height="41.25"
    viewBox="0 0 45.75 41.25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.20215"
      y="12.4688"
      width="39.3461"
      height="22.9688"
      stroke="black"
      strokeWidth="1"
    />
    <path
      d="M6.63 3.75C6.38239 3.75 6.15075 3.87221 6.01096 4.07657L0.880957 11.5766C0.723897 11.8062 0.706841 12.1039 0.836647 12.3499C0.966453 12.5959 1.2218 12.75 1.5 12.75H44.25C44.5282 12.75 44.7835 12.5959 44.9133 12.3499C45.0431 12.1039 45.0261 11.8062 44.869 11.5766L39.739 4.07657C39.5992 3.87221 39.3676 3.75 39.12 3.75H6.63Z"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M13.4136 35.25H42.5482V40.5H13.4136V35.25Z"
      stroke="black"
      strokeWidth="1"
    />
    <path
      d="M10.5 0.75H35.25V6H10.5V0.75Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M3.20215 35.25H13.6156V40.5H3.20215V35.25Z"
      stroke="black"
      strokeWidth="1"
    />
    <path
      d="M1.5 12.4688H7.65863V17.1707C7.65863 18.8713 6.27999 20.25 4.57933 20.25C2.87867 20.25 1.5 18.8713 1.5 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M13.4136 12.4688H19.5722V17.1707C19.5722 18.8713 18.1936 20.25 16.4929 20.25C14.7923 20.25 13.4136 18.8713 13.4136 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M25.3271 12.4688H31.4857V17.1707C31.4857 18.8713 30.1071 20.25 28.4064 20.25C26.7058 20.25 25.3271 18.8713 25.3271 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M7.45679 12.4688H13.6154V17.1707C13.6154 18.8713 12.2368 20.25 10.5361 20.25C8.83547 20.25 7.45679 18.8713 7.45679 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M19.3703 12.4688H25.5289V17.1707C25.5289 18.8713 24.1502 20.25 22.4496 20.25C20.7489 20.25 19.3703 18.8713 19.3703 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M31.2839 12.4688H37.4425V17.1707C37.4425 18.8713 36.0638 20.25 34.3632 20.25C32.6625 20.25 31.2839 18.8713 31.2839 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <path
      d="M38.0911 12.4688H44.2497V17.1707C44.2497 18.8713 42.871 20.25 41.1704 20.25C39.4697 20.25 38.0911 18.8713 38.0911 17.1707V12.4688Z"
      fill="white"
      stroke="black"
      strokeWidth="1"
      strokeLinejoin="round"
    />
    <line
      x1="13.4136"
      y1="19.3125"
      x2="13.4136"
      y2="35.3438"
      stroke="black"
      strokeWidth="1"
    />
  </svg>
);

export default CommercialSpaceIcon;
