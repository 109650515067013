import React from 'react';
import { Comment } from '../utils/types';
import { getTimeAgo } from '../BuildingModal/Report.helpers';
import StyledHeartIcon from './StyledHeartIcon';
import { Trans } from 'react-i18next';
type LikeBuildingEventProps = {
  comment: Comment;
  handleFeedEventClick: (comment: Comment) => void;
};

export const LikeBuildingEvent = ({
  comment,
  handleFeedEventClick,
}: LikeBuildingEventProps) => {
  const shortAddress =
    comment.address.street +
    ' ' +
    (comment.address.houseNumber === 'Unknown' ? '' : comment.address.houseNumber) +
    ', ' +
    comment.address.city;
  const isLongAddress = shortAddress.length > 32;

  return (
    <div
      className={`feed-comments__comment feed-comments__comment--like-building`}
      onClick={() => handleFeedEventClick(comment)}
    >
      <div className="feed-comments__comment__header-and-content-wrapper">
        <div
          className="feed-comments__comment__commenter-order-number-and-timestamp-wrapper 
                        feed-comments__comment__commenter-order-number-and-timestamp-wrapper--no-text 
                         feed-comments__comment__commenter-order-number-and-timestamp-wrapper--building-like
                        "
        >
          <div className="feed-comments__comment__commenter-order-number">
            <div className="feed-comments__comment__commenter-order-number__off-market-willingness-comment">
              <div className="feed-comments__comment__like-icon">
                <StyledHeartIcon />
              </div>
              <span>{shortAddress}</span>
            </div>
          </div>
          <div className="feed-comments__comment__timestamp">
            {getTimeAgo(comment.timestamp)}
          </div>
        </div>
        <div
          className={`feed-comments__comment__content feed-comments__comment__content--building-like
                        ${
                          isLongAddress
                            ? ' feed-comments__comment__content--building-like-long-address'
                            : ''
                        }`}
        >
          <div className="feed-comments__comment__content__text feed-comments__comment__content__text--building-like">
            <Trans i18nKey="feed.likeBuildingEvent.text">
              User liked building!
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
};
