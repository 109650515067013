import React from 'react';
import './NavbarMessages.scss';
import MessagesIcon from './MessagesIcon';
import { Trans } from 'react-i18next';

type NavbarMessagesProps = {
    toggleMessagesModal: (event: React.MouseEvent<HTMLDivElement>) => void;
    setLoginModalVisible: (visible: boolean) => void;
    newMessagesCount: number;
    isLoggedIn: boolean;
};

const NavbarMessages = ({
    toggleMessagesModal,
    setLoginModalVisible,
    newMessagesCount,
    isLoggedIn,
}: NavbarMessagesProps) => (
    <div className="navbar-messages-wrapper"
        onClick={(event) => {
            if (!isLoggedIn) {
                setLoginModalVisible(true);
            } else {
                toggleMessagesModal(event);
            }
        }}
    >
        <div className="navbar-messages">
            <MessagesIcon newMessagesCount={newMessagesCount} />
            <div className={`navbar-messages__text`}>
                <span>
                    <Trans i18nKey="navbar.messages">
                        Messages
                    </Trans>
                </span>
            </div>
        </div>
    </div>
);

export default NavbarMessages;