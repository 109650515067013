import { en } from './en/translation';
import { fi } from './fi/translation';

export const resources = {
  en: {
    translation: en
  },
  fi: {
    translation: fi
  }
};