import React, { useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import './SearchInputBox.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useGoogleMapsApi } from '../../GoogleMapsApiContext';

type SearchInputProps = {
  setTriggeredFromSearch: (triggeredFromSearch: boolean) => void;
  setMapCenter: (mapCenter: { lat: number; lng: number }) => void;
  setCountryOfFeedContent: (country: string) => void;
  countryOfFeedContent: string;
  feedHidden: boolean;
};

type Results = {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  place_id: string;
  types: string[];
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: object;
    location_type: string;
  };
}[];

const SearchInput = (props: SearchInputProps) => {
  const { t } = useTranslation();
  const language = i18n.language;
  const isEnglish = language.startsWith('en');
  const [address, setAddress] = useState('');
  const searchInputRef = useRef(null);
  const { isLoaded, loadError } = useGoogleMapsApi();

  const handleChange = (address: string) => {
    setAddress(address);
  };

  const handleSelect = (address: string) => {
    // TODO: Use coordinates from results data in combination with mapbox in the map component to highlight the building on the map
    geocodeByAddress(address)
      .then(async (results: Results) => {
        if (results.length === 0) {
          console.warn('No results found for the given address.');
          return;
        }
        props.setTriggeredFromSearch(true);
        const latLng = await getLatLng(results[0]);
        props.setMapCenter(latLng);
        for (const component of results[0].address_components) {
          if (component.types.includes('country')) {
            if (component.long_name !== props.countryOfFeedContent) {
              props.setCountryOfFeedContent(component.long_name);
            }
          }
        }
      })
      .catch((error: any) => {
        console.error('Error', error);
      });
    searchInputRef.current.blur();
  };

  if (loadError) {
    return <div className="search-input-error">Error loading map services: {loadError.message}</div>;
  }

  if (!isLoaded) {
    return <div className="search-input-loading">Loading map services...</div>;
  }

  return (
    <Form className={`search-input ${isEnglish ? 'search-input--english' : ''}
    ${props.feedHidden ? ' search-input--feed-hidden' : ''}`}>
      <Form.Row>
        <Col>
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  ref={searchInputRef}
                  {...getInputProps({
                    placeholder: t('searchInput.placeholder'),
                    className: 'location-search-input',
                    style: { backgroundColor: '#ffffff', cursor: 'pointer' },
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    const suggestionProps = getSuggestionItemProps(
                      suggestion,
                      {
                        className,
                        style,
                      },
                    );
                    return (
                      <div
                        key={suggestion.placeId} // Use a unique identifier for the key
                        {...suggestionProps}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default SearchInput;