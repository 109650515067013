import { LatLng } from "../utils/types";

export const getUserCountry = async (userCoordinates?: LatLng): Promise<string> => {
  try {
    if (!userCoordinates) {
      // If user denies permission or geolocation fails, use IP-based location
      const ipResponse = await fetch('https://ipapi.co/json/');
      const ipData = await ipResponse.json();
      if (ipData && ipData.country_name) {
        return ipData.country_name;
      }
      return 'Finland'; // Default to Finland if IP geolocation fails
    }
    const { lat, lng } = userCoordinates;

    // Use Mapbox's reverse geocoding to get the country
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}&types=country`
    );

    const data = await response.json();

    if (data.features && data.features.length > 0) {
      // Extract country code from the result
      const countryCode = data.features[0].text || 'Finland'; // Default to Finland if not found
      return countryCode;
    }

    return 'Finland'; // Default to Finland
  } catch (error) {
    console.error('Error getting user country:', error);
    return 'Finland'; // Default to Finland on error
  }
};