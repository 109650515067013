import React from 'react';

const QuestionMarkIcon: React.FC<{ color: string }> = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_301_14978)">
      <path
        d="M18.011 36C8.06858 36 0 27.9314 0 18.011C0 8.09063 8.06858 0 18.011 0C27.9535 0 36.022 8.06858 36.022 18.011C36.022 27.9535 27.9535 36.022 18.011 36.022V36ZM18.011 4.40906C10.5156 4.40906 4.40906 10.5156 4.40906 18.011C4.40906 25.5064 10.5156 31.613 18.011 31.613C25.5064 31.613 31.613 25.5064 31.613 18.011C31.613 10.5156 25.5064 4.40906 18.011 4.40906Z"
        fill={color}
      />
      <path
        d="M15.2333 21.0535C15.2333 19.2899 15.9828 18.2317 17.3276 17.3058L18.7826 16.2697C19.5321 15.7185 19.951 15.1674 19.951 14.3076C19.951 13.2715 19.2455 12.4558 17.8567 12.4558C16.4678 12.4558 15.7624 13.3817 15.7403 14.484H12.0146C12.0146 11.3977 14.0428 8.81836 17.9007 8.81836C21.7587 8.81836 24.0073 11.1552 24.0073 14.2856C24.0073 16.5122 22.5303 17.9892 21.1194 18.9151L19.7746 19.841C19.2014 20.2599 18.8928 20.7449 18.8928 21.4503V22.1337H15.2112V21.0315L15.2333 21.0535ZM14.8144 25.8373C14.8144 24.6028 15.8285 23.6328 17.063 23.6328C18.4078 23.6328 19.3116 24.5808 19.3116 25.8373C19.3116 27.0939 18.4078 28.0419 17.063 28.0419C15.8285 28.0419 14.8144 27.138 14.8144 25.8373Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_301_14978">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default QuestionMarkIcon;
