import React from 'react';
type MessagesIconProps = {
  height?: number;
  width?: number;
  newMessagesCount?: number;
};

const MessagesIcon: React.FC<MessagesIconProps> = ({ height = 24, width = 24, newMessagesCount = 0 }) => (
  <div className="messages-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height={height}
      width={width}
    >
      <path
        d="M4,17.5H8.75l2.8501,3.7998a.4995.4995,0,0,0,.7998,0L15.25,17.5H20A2.50263,2.50263,0,0,0,22.5,15V5A2.50263,2.50263,0,0,0,20,2.5H4A2.50294,2.50294,0,0,0,1.5,5V15A2.50294,2.50294,0,0,0,4,17.5ZM2.5,5A1.50164,1.50164,0,0,1,4,3.5H20A1.50164,1.50164,0,0,1,21.5,5V15A1.50164,1.50164,0,0,1,20,16.5H15a.50135.50135,0,0,0-.40039.2002L12,20.1665,9.3999,16.7002A.49973.49973,0,0,0,9,16.5H4A1.50164,1.50164,0,0,1,2.5,15Z"
      />
    </svg>
    {newMessagesCount > 0 && (
      <div className="messages-icon__circle">
        <div className="messages-icon__circle__text">{newMessagesCount}</div>
      </div>
    )}
  </div>
);

export default MessagesIcon;
