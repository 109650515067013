import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './MainMenu.scss';
import TagManager from 'react-gtm-module';
import { CloseIcon } from '../common/CloseIcon';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

type MainMenuProps = {
  isMainMenuPage?: boolean;
};

const MainMenu = ({ isMainMenuPage }: MainMenuProps) => {
  const { i18n } = useTranslation('mainMenu');
  const [wrapper, setWrapper] = useState('wrapper');
  const [mainMenuVisibility, setMainMenuVisibility] = useState(false);
  const languageIsEnglish = i18n.language.startsWith('en');

  const handleOpenClick = () => {
    setMainMenuVisibility(true);
  };

  const handleCloseClick = () => {
    setMainMenuVisibility(false);
  };

  useEffect(() => {
    if (mainMenuVisibility) {
      setWrapper(wrapper + ' open');
    } else {
      setWrapper('wrapper');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMenuVisibility]);

  return (
    <div>
      <div
        className={`mainMenuOverlay ${mainMenuVisibility ? 'mainMenuOverlay--visible' : ''
          }`}
        onClick={() => handleCloseClick()}
      />

      <div className="main-menu-wrapper">
        <Nav defaultActiveKey="/home" className={`${wrapper} flex-column`}>
          <div className="closeMenu" onClick={() => handleCloseClick()}>
            <CloseIcon size={24} />
          </div>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/faq"
            onClick={() => {
              handleCloseClick();
              TagManager.dataLayer({
                dataLayer: {
                  event: 'what_is_jaakko_faq_clicked',
                  category: 'User Interaction',
                  action: 'Click',
                  label: 'What is Jaakko.com FAQ Clicked',
                },
              });
            }}
          >
            <Trans i18nKey="mainMenu.whatIsJaakko">
              What is Jaakko.com?
            </Trans>
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            as={Link}
            to="/contact"
            onClick={() => handleCloseClick()}
          >
            <Trans i18nKey="mainMenu.contact">Contact</Trans>
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            href={languageIsEnglish ? "https://docs.google.com/document/d/1ySW3hj00N9y9dfmp2RSF5Xgi-Zs2Up_Up8IKKc0vvdY/edit?usp=sharing" : "https://docs.google.com/document/d/1uIQz5jA5Ond_ig4vHs6lYZfvrvFoK-vYC6r97h5a3Tg/edit?usp=sharing"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleCloseClick()}
          >
            <Trans i18nKey="mainMenu.termsConditions">Terms & Conditions</Trans>
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            href={languageIsEnglish ? "https://docs.google.com/document/d/11s7hgEa1fcw0WOKYB3aBDb-H_xnJqHJ4-Ni1m83jsVI/edit?usp=sharing" : "https://docs.google.com/document/d/1iNs5ROM1OH5mxX1lvxo6HfsF128cMP2Lx5eONHIN-NU/edit?usp=sharing"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleCloseClick()}
          >
            <Trans i18nKey="mainMenu.privacyPolicy">Privacy Policy</Trans>
          </Nav.Link>
          <Nav.Link
            className="menuElement"
            href={languageIsEnglish ? "https://docs.google.com/document/d/1aE5lNf1c4sHWYWpG9dhD82kqlydKvoRRHy6yEx5_ADU/edit?usp=sharing" : "https://docs.google.com/document/d/1wdZ24dGXzE2T6I3V22sZCSpckzaPSIK9ICOlW2-oe4k/edit?usp=sharing"}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleCloseClick()}
          >
            <Trans i18nKey="mainMenu.cookies">Cookies</Trans>
          </Nav.Link>
        </Nav>
      </div>
      <div
        className={`menuBurger ${mainMenuVisibility ? 'menuBurger--open' : ''
          } ${isMainMenuPage ? 'menuBurger--isMainMenuPage' : ''}`}
        onClick={() =>
          mainMenuVisibility ? handleCloseClick() : handleOpenClick()
        }
      >
        <div className="burger-line"></div>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
      </div>
    </div>
  );
};

export default MainMenu;
