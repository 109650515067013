import React, { useState, useEffect, useRef } from 'react';
import './SignupLoginModal.scss';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  email: string;
  setEmail: (email: string) => void;
  validateEmail: (email: string) => string;
  setShowForgotPasswordView: (show: boolean) => void;
  setShowLoginView: (show: boolean) => void; // To go back
};

const ForgotPassword = (props: Props) => {
  const {
    email: initialEmail, // Rename prop to avoid conflict with state
    setEmail: setParentEmail, // Rename prop
    validateEmail,
    setShowForgotPasswordView,
    setShowLoginView,
  } = props;
  const { t, i18n } = useTranslation();
  const [currentEmail, setCurrentEmail] = useState(initialEmail); // Local state for email
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [submitMessage, setSubmitMessage] = useState(''); // For success/general messages
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus the email input when the component mounts
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
    // Update local email state if the parent email prop changes
    setCurrentEmail(initialEmail);
  }, [initialEmail]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value);
    setParentEmail(e.target.value); // Keep parent state in sync if needed elsewhere
    if (emailError) {
      setEmailError(''); // Clear error when user types
    }
    if (submitMessage) {
      setSubmitMessage(''); // Clear submit message when user types
    }
  };

  const handleSubmit = async () => {
    setSubmitMessage(''); // Clear previous messages
    const validationError = validateEmail(currentEmail);
    setEmailError(validationError);

    if (validationError) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/request-password-reset`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // No CSRF needed usually for this type of request, but include if your setup requires it
            // 'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ 
            email: currentEmail.toLowerCase(),
            language: i18n.language
          }),
        },
      );

      // Always show a generic success message as per backend logic to prevent email enumeration
      // Use a translation key for the message
      setSubmitMessage(t('signupLoginModal.forgotPassword.submitMessage'));

      // Backend doesn't send specific errors here, just logs them.
      // We just show the generic message.
      if (!response.ok) {
        // Log potential issues for debugging, but don't show specific errors to user
        console.error('Request password reset failed with status:', response.status);
        try {
          const errorData = await response.json();
          console.error('Error details:', errorData);
        } catch (e) {
          console.error('Could not parse error response body');
        }
      }

    } catch (error) {
      console.error('Error requesting password reset:', error);
      // Show a generic error message in case of network or unexpected issues
      setSubmitMessage(t('signupLoginModal.unexpectedError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="signup-login-modal__content">
        <div className="signup-login-modal__content__title">
          <Trans i18nKey="signupLoginModal.forgotPassword.title" />
        </div>
      </div>
      <div className="signup-login-modal__content__subtitle">
        <Trans i18nKey="signupLoginModal.forgotPassword.subtitle" />
      </div>
      <form
        className="signup-login-modal__content__form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <input
          type="email"
          placeholder={t('signupLoginModal.emailPlaceholder')}
          className="signup-login-modal__content__input"
          value={currentEmail}
          onChange={handleEmailChange}
          required
          autoComplete="email"
          ref={emailInputRef}
          disabled={isLoading}
        />
        {emailError && (
          <div className="signup-login-modal__content__error">
            {emailError}
          </div>
        )}
        {submitMessage && (
          // Use a different class for general messages vs errors if desired
          <div className="signup-login-modal__content__message">
            {submitMessage}
          </div>
        )}
        <button
          className="signup-login-modal__content__button"
          disabled={isLoading || !!submitMessage} // Disable button after successful submission
          type="submit"
        >
          {isLoading ? (
            <span className="loading-dots">
              <span></span>
            </span>
          ) : (
            <Trans i18nKey="signupLoginModal.forgotPassword.sendLinkButton" />
          )}
        </button>
        {/* Optional: Add a button to go back to the login view */}
        {!isLoading && !submitMessage && (
          <button
            type="button"
            onClick={() => {
              setShowForgotPasswordView(false);
              setShowLoginView(true);
            }}
            className="signup-login-modal__back-button" // Use a distinct class
          >
            <Trans i18nKey="signupLoginModal.forgotPassword.backToLogin" />
          </button>
        )}
      </form>
    </>
  );
};

export default ForgotPassword; 