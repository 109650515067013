import React from 'react';
import { ArticlesOfAssociationApartments } from '../utils/types';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  analyzedArticlesOfAssociationApartments: ArticlesOfAssociationApartments | null;
};

// Add this mock data object near the top of your component file
const mockApartmentData = {
  apartments: [
    { roomCount: 0, squareMeters: 0 },
    { roomCount: 0, squareMeters: 0 },
    ...Array(1).fill({ roomCount: 0, squareMeters: 0 }), // Fill the rest with average
    { roomCount: 0, squareMeters: 0 },
    { roomCount: 0, squareMeters: 0 },
    ...Array(1).fill({ roomCount: 0, squareMeters: 0 }),
    { roomCount: 0, squareMeters: 0 },
    { roomCount: 0, squareMeters: 0 },
    ...Array(1).fill({ roomCount: 0, squareMeters: 0 }),
    { roomCount: 0, squareMeters: 0 },
    { roomCount: 0, squareMeters: 0 },
  ],
};

const ApartmentCounts = (props: Props) => {
  const { analyzedArticlesOfAssociationApartments } = props;
  const { t } = useTranslation();

  // In your component, update the data processing logic:
  const apartmentData =
    analyzedArticlesOfAssociationApartments || mockApartmentData;

  function getApartmentCountByRoomCount(roomCount: number) {
    return apartmentData.apartments.filter((apartment) =>
      roomCount === 4
        ? apartment.roomCount >= 4
        : apartment.roomCount === roomCount,
    ).length;
  }

  const apartmentCounts = {
    1: getApartmentCountByRoomCount(1),
    2: getApartmentCountByRoomCount(2),
    3: getApartmentCountByRoomCount(3),
    4: getApartmentCountByRoomCount(4),
  };

  function getSmallestApartmentByRoomCount(roomCount: number) {
    return apartmentData.apartments
      .filter((apartment) =>
        roomCount === 4
          ? apartment.roomCount >= 4
          : apartment.roomCount === roomCount,
      )
      .sort((a, b) => a.squareMeters - b.squareMeters)[0];
  }

  const smallestApartments = {
    1: getSmallestApartmentByRoomCount(1),
    2: getSmallestApartmentByRoomCount(2),
    3: getSmallestApartmentByRoomCount(3),
    4: getSmallestApartmentByRoomCount(4),
  };

  function getLargestApartmentByRoomCount(roomCount: number) {
    return apartmentData.apartments
      .filter((apartment) =>
        roomCount === 4
          ? apartment.roomCount >= 4
          : apartment.roomCount === roomCount,
      )
      .sort((a, b) => b.squareMeters - a.squareMeters)[0];
  }

  const largestApartments = {
    1: getLargestApartmentByRoomCount(1),
    2: getLargestApartmentByRoomCount(2),
    3: getLargestApartmentByRoomCount(3),
    4: getLargestApartmentByRoomCount(4),
  };

  return (
    <div className="report__apartment-counts-wrapper">
      <div className="report__quantitative_content__sub-title">
        <Trans
          i18nKey="building.apartments.title"
        >
          Flats
        </Trans>
      </div>
      <div className="apartment-distribution-wrapper">
        <div
          className={`apartment-distribution${
            analyzedArticlesOfAssociationApartments
              ? ''
              : ' apartment-distribution--blurred'
          }`}
        >
          {Array.from({ length: 100 }).map((_, index) => {
            const totalApartments = Object.values(apartmentCounts).reduce(
              (sum, count) => sum + (count || 0),
              0,
            );
            const threshold1h =
              ((apartmentCounts[1] || 0) / totalApartments) * 100;
            const threshold2h =
              threshold1h + ((apartmentCounts[2] || 0) / totalApartments) * 100;
            const threshold3h =
              threshold2h + ((apartmentCounts[3] || 0) / totalApartments) * 100;

            let squareClass = 'apartment-distribution__square';
            if (index < threshold1h)
              squareClass += ' apartment-distribution__square--1h';
            else if (index < threshold2h)
              squareClass += ' apartment-distribution__square--2h';
            else if (index < threshold3h)
              squareClass += ' apartment-distribution__square--3h';
            else squareClass += ' apartment-distribution__square--4h';

            // Add corner classes
            if (index === 0)
              squareClass += ' apartment-distribution__square--top-left';
            if (index === 9)
              squareClass += ' apartment-distribution__square--top-right';
            if (index === 90)
              squareClass += ' apartment-distribution__square--bottom-left';
            if (index === 99)
              squareClass += ' apartment-distribution__square--bottom-right';

            return <div key={index} className={squareClass}></div>;
          })}
        </div>
        <div className="report__quantitative_content__apartments-info-box">
          {[1, 2, 3, 4].map((roomCount) => (
            <div
              key={roomCount}
              className={`apartment-row${
                analyzedArticlesOfAssociationApartments
                  ? ''
                  : ' apartment-row--blurred'
              }`}
            >
              <span
                className={`apartment-indicator apartment-indicator--${roomCount}h`}
              ></span>
              {roomCount === 4 
                ? t('building.apartments.fourPlusRooms', { count: 4 }) 
                : t('building.apartments.roomCount', { count: roomCount })
              } (
              {smallestApartments[roomCount]
                ? smallestApartments[roomCount].squareMeters
                : ''}
              -
              {largestApartments[roomCount]
                ? `${largestApartments[roomCount].squareMeters} m2`
                : ''}
              ):{' '}
              {typeof apartmentCounts[roomCount] === 'number' &&
              analyzedArticlesOfAssociationApartments
                ? t('building.apartments.units.count', { count: apartmentCounts[roomCount] })
                : t('building.apartments.units.countEmpty')}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApartmentCounts;
