import React from 'react';
import './MainMenuPages.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import MainMenu from '../MainMenu';
import './Page.scss';
import { Trans } from 'react-i18next';

const ContactUs = () => {
  const history = useHistory();
  return (
    <Container className="p-0 policy-page-container">
      <MainMenu isMainMenuPage />
      <div className="closeMenuView" onClick={() => history.push('/')} />
      <div className="policy-page-content">
        <Link to="/">
          <div className="flatsonBlueText">Jaakko.com</div>
        </Link>
        <Row>
          <p className="page-name">
            <Trans i18nKey="mainMenuPages.contactUs.title" />
          </p>
        </Row>
        <Col>
          <p className="small-text">
            Jaakkocom Oy
            <br />
            Maria 01
            <br />
            Lapinlahdenkatu 16
            <br />
            00180 Helsinki
            <br />
            <Trans i18nKey="mainMenuPages.contactUs.country" />
            <br />
            <Trans i18nKey="mainMenuPages.contactUs.businessId" /> 3385985-8
            <br />
            <br />
            <Trans i18nKey="mainMenuPages.contactUs.customerSupport" />{' '}
            <a href="mailto:support@jaakko.com">support@jaakko.com</a>
          </p>
        </Col>
      </div>
    </Container>
  );
};

export default ContactUs;
