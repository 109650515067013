import React, { useEffect, useCallback } from 'react';
import { Comment, OffMarketWillingnessEvent as OffMarketWillingnessEventType } from '../utils/types';
import Linkify from 'linkify-react';
import DOMPurify from 'dompurify';
import { getTimeAgo } from '../BuildingModal/Report.helpers';
import LoadingSpinner from '../common/LoadingSpinner';
import LocationIcon from './LocationIcon';
import MiniMenuIcon from './MiniMenuIcon';
import DownArrowAlternativeIcon from './DownArrowAlternativeIcon';
import TrashIcon from '../BuildingModal/Comments/TrashIcon';
import './FeedComments.scss';
import { OffMarketWillingnessEvent } from './OffMarketWillingnessEvent';
import { LikeBuildingEvent } from './LikeBuildingEvent';
import { getCookie } from '../utils/utils';

type FeedCommentsProps = {
  feedComments: Comment[];
  handleFeedCommentClick: (comment: Comment | OffMarketWillingnessEventType) => void;
  handleRefreshFeedClick: () => void;
  isRefreshingFeed: boolean;
  isLoggedIn: boolean;
  isLoadingFeed: boolean;
  setLoginModalVisible: (visible: boolean) => void;
  handleLoadMoreFeed: () => void;
  isLoadingMore: boolean;
  hasMoreFeed: boolean;
}
const FeedComments = ({
  feedComments,
  handleFeedCommentClick,
  handleRefreshFeedClick,
  isRefreshingFeed,
  isLoggedIn,
  isLoadingFeed,
  setLoginModalVisible,
  handleLoadMoreFeed,
  isLoadingMore,
  hasMoreFeed,
}: FeedCommentsProps) => {
  const [startY, setStartY] = React.useState<number | null>(null);
  const [currentY, setCurrentY] = React.useState<number | null>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [showCommentControlsMenu, setShowCommentControlsMenu] = React.useState<
    string | null
  >(null);
  const commentControlsRemoveCommentRef =
    React.useRef<HTMLButtonElement | null>(null);
  const commentControlsMenuButtonRef = React.useRef<HTMLButtonElement | null>(
    null,
  );

  /**
   * This is our threshold. The user needs to pull down at least this many pixels
   * for it to trigger a refresh.
   */
  const PULL_DOWN_THRESHOLD = 60;

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    // Only start tracking if we're scrolled to the top
    if (scrollContainerRef.current?.scrollTop === 0) {
      setStartY(e.touches[0].clientY);
    }
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startY !== null) {
      setCurrentY(e.touches[0].clientY);
    }
  };

  const onTouchEnd = () => {
    if (startY !== null && currentY !== null) {
      const pulledDownDistance = currentY - startY;
      // If we pulled down enough, trigger handleRefreshFeedClick
      if (pulledDownDistance >= PULL_DOWN_THRESHOLD) {
        handleRefreshFeedClick();
      }
    }
    // Reset
    setStartY(null);
    setCurrentY(null);
  };

  // Handle scroll event to implement infinite scrolling
  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current || !hasMoreFeed || isLoadingMore || isLoadingFeed || isRefreshingFeed) return;
    
    const container = scrollContainerRef.current;
    const { scrollTop, scrollHeight, clientHeight } = container;
    
    // If we've scrolled to near the bottom (within 200px), load more
    if (scrollHeight - scrollTop - clientHeight < 200) {
      handleLoadMoreFeed();
    }
  }, [hasMoreFeed, isLoadingMore, isLoadingFeed, isRefreshingFeed, handleLoadMoreFeed]);

  // Add scroll event listener for infinite scrolling
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const handleUpvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'up' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to upvote');
      }
      // Optionally parse response if you want returned data:
      // const data = await response.json();

      // Refresh feed or update local state so UI matches the server
      handleRefreshFeedClick();
    } catch (error) {
      console.error('Error upvoting:', error);
    }
  };

  const handleDownvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'down' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to downvote');
      }
      // Optionally parse response if you want returned data:
      // const data = await response.json();

      // Refresh feed or update local state so UI matches the server
      handleRefreshFeedClick();
    } catch (error) {
      console.error('Error downvoting:', error);
    }
  };

  const handleRemoveUpvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'none' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to remove upvote');
      }
      handleRefreshFeedClick();
    } catch (error) {
      console.error('Error removing upvote:', error);
    }
  };

  const handleRemoveDownvote = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/vote`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
          body: JSON.stringify({ voteType: 'none' }),
        },
      );
      if (!response.ok) {
        throw new Error('Failed to remove downvote');
      }
      handleRefreshFeedClick();
    } catch (error) {
      console.error('Error removing downvote:', error);
    }
  };

  const handleRemoveComment = async (commentId: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}`,
        {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to remove comment');
      }
      handleRefreshFeedClick();
      setShowCommentControlsMenu(null);
    } catch (error) {
      console.error('Error removing comment:', error);
    }
  };

  const handleFeedEventClick = (comment: Comment | OffMarketWillingnessEventType) => {
    if (showCommentControlsMenu) {
      setShowCommentControlsMenu(null);
    } else {
      handleFeedCommentClick(comment);
    }
  };

  return (
    <div
      className="feed-comments__comments-list"
      ref={scrollContainerRef}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {!isLoadingFeed && (feedComments.length > 0 ? (
        <>
          {feedComments.map((comment, index) => {
            const commentColorIndex = (index % 9) + 1;
            const commentAddress =
              comment.address.street +
              (comment.address.houseNumber === 'Unknown'
                ? ''
                : ' ' + comment.address.houseNumber) +
              ', ' +
              comment.address.city;
            const shortAddress =
              comment.address.street +
              (comment.address.houseNumber === 'Unknown'
                ? ''
                : ' ' + comment.address.houseNumber) +
              ', ' +
              comment.address.city;
            const isOffMarketWillingnessEvent =
              typeof comment.could_sell === 'boolean';
            const isBuildingLikeEvent = comment.feed_event_type === 'BUILDING_LIKE';

            return isBuildingLikeEvent ? (
              <LikeBuildingEvent
                key={comment._id}
                comment={comment}
                handleFeedEventClick={handleFeedEventClick}
              />
            ) : isOffMarketWillingnessEvent ? (
              <OffMarketWillingnessEvent
                key={comment._id}
                comment={comment as unknown as OffMarketWillingnessEventType}
                handleFeedEventClick={handleFeedEventClick}
              />
            ) : (
              <div
                className={`feed-comments__comment feed-comments__comment--${commentColorIndex}${comment.isAdminComment
                  ? ' feed-comments__comment--jaakko-comment'
                  : ''
                  }`}
                key={comment._id}
                onClick={() => handleFeedEventClick(comment)}
              >
                <div className="feed-comments__comment__header-and-content-wrapper">
                  <div
                    className={`feed-comments__comment__commenter-order-number-and-timestamp-wrapper${!comment.content
                      ? ' feed-comments__comment__commenter-order-number-and-timestamp-wrapper--no-text'
                      : ''
                      }${comment.isAdminComment
                        ? ' feed-comments__comment__commenter-order-number-and-timestamp-wrapper--admin-comment'
                        : ''
                      }`}
                  >
                    <div className="feed-comments__comment__commenter-order-number">
                      {comment.isAdminComment ? (
                        <div className="feed-comments__comment__commenter-order-number__jaakko-comment">
                          <img
                            src="/assets/naama5.png?v=1"
                            alt="Jaakko"
                            height="35px"
                            className="feed-comments__comment__commenter-order-number__jaakko-comment__jaakko-head"
                            loading="lazy"
                          />
                          <span>{shortAddress}</span>
                        </div>
                      ) : (
                        <>
                          <LocationIcon />
                          {commentAddress}
                        </>
                      )}
                    </div>
                    <div className="feed-comments__comment__timestamp">
                      {getTimeAgo(comment.timestamp)}
                    </div>
                  </div>
                  <div
                    className={`feed-comments__comment__content${comment.images && comment.images.length > 0
                      ? ' feed-comments__comment__content--with-image'
                      : ''
                      }`}
                  >
                    {
                      <div
                        className={`feed-comments__comment__content__text
                                    ${comment.images && comment.images.length > 0
                            ? ' feed-comments__comment__content__text--with-image'
                            : ''
                          }${!comment.content
                            ? ' feed-comments__comment__content__text--no-text'
                            : ''
                          }${comment.isAdminComment &&
                            comment.images &&
                            comment.images.length > 0
                            ? ' feed-comments__comment__content__text--admin-comment-with-image'
                            : ''
                          }`}
                      >
                        <Linkify
                          options={{
                            attributes: {
                              rel: 'noopener noreferrer',
                              target: '_blank',
                            },
                          }}
                        >
                          {DOMPurify.sanitize(comment.content)}
                        </Linkify>
                      </div>
                    }
                    {comment.images && comment.images.length > 0 && (
                      <div
                        className={`feed-comments__comment__content__image-wrapper${comment.isAdminComment
                          ? ' feed-comments__comment__content__image-wrapper--admin-comment-with-image'
                          : ''
                          }`}
                      >
                        <img
                          src={comment.images[0].thumbnailUrl}
                          alt="Comment"
                          loading="lazy"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="feed-comments__comment__footer">
                  <div className="feed-comments__comment__footer__vote-controls">
                    <button
                      className="feed-comments__comment__footer__vote-controls__upvote"
                      disabled={comment.userVote === 'up'}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showCommentControlsMenu) {
                          setShowCommentControlsMenu(null);
                        }
                        if (!isLoggedIn) {
                          setLoginModalVisible(true);
                        } else if (comment.userVote === 'down') {
                          handleRemoveUpvote(comment._id);
                        } else {
                          handleUpvote(comment._id);
                        }
                      }}
                    >
                      <DownArrowAlternativeIcon />
                    </button>
                    <div className="feed-comments__comment__footer__vote-controls__vote-count">
                      {(comment.upvoteCount || 0) - (comment.downvoteCount || 0)}
                    </div>
                    <button
                      className="feed-comments__comment__footer__vote-controls__downvote"
                      disabled={comment.userVote === 'down'}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showCommentControlsMenu) {
                          setShowCommentControlsMenu(null);
                        }
                        if (!isLoggedIn) {
                          setLoginModalVisible(true);
                        } else if (comment.userVote === 'up') {
                          handleRemoveDownvote(comment._id);
                        } else {
                          handleDownvote(comment._id);
                        }
                      }}
                    >
                      <DownArrowAlternativeIcon />
                    </button>
                  </div>
                  {showCommentControlsMenu === comment._id && (
                    <div className="feed-comments__comment__footer__menu-wrapper">
                      <div
                        className="feed-comments__comment__footer__menu"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <button
                          disabled={isLoggedIn && !comment.isUserOwnComment}
                          className={`feed-comments__comment__footer__menu__remove-comment-button${!isLoggedIn
                            ? ' feed-comments__comment__footer__menu__remove-comment-button--disabled'
                            : ''
                            }`}
                          ref={commentControlsRemoveCommentRef}
                          onClick={() => {
                            if (!isLoggedIn) {
                              setLoginModalVisible(true);
                            } else if (comment.isUserOwnComment) {
                              handleRemoveComment(comment._id);
                            }
                          }}
                        >
                          <TrashIcon color="#202125" />
                        </button>
                      </div>
                    </div>
                  )}
                  {!comment.isAdminComment && (
                    <button
                      className="feed-comments__comment__footer__menu__show-menu-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (showCommentControlsMenu === comment._id) {
                          setShowCommentControlsMenu(null);
                        } else {
                          setShowCommentControlsMenu(comment._id);
                        }
                      }}
                      ref={commentControlsMenuButtonRef}
                    >
                      <MiniMenuIcon color="#FFF" size={16} />
                    </button>
                  )}
                </div>
              </div>
            );
          })}

          {/* Show loading indicator at the bottom during infinite scroll */}
          {isLoadingMore && (
            <div className="feed-comments__loading-more">
              <LoadingSpinner />
            </div>
          )}
        </>
      ) : (
        <div className="feed-comments__empty-state">
          <div className="feed-comments__empty-state__content">
            <p className="feed-comments__empty-state__title">No activities yet</p>
            <p className="feed-comments__empty-state__message">
              There are no activities in this country yet.
              Be the first one to express interest in buying or selling - and start the conversation!
            </p>
          </div>
        </div>
      ))}
      {(isRefreshingFeed || isLoadingFeed) && (
        <div className="feed-comments__loading-spinner-overlay">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default FeedComments;
