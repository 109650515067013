import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  BuildingWithConvertedAddresses,
} from '../utils/types';
import { getCookie } from '../utils/utils';
import TagManager from 'react-gtm-module';
import { CouldSellIcon } from './Icons/CouldSellIcon';
import { CouldBuyIcon } from './Icons/CouldBuyIcon';
import './Report.scss';
import QuestionMarkIcon from './Icons/QuestionMarkIcon';
import LoadingSpinner from '../common/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import { OffMarketToastMessage } from './BuildingModal';
import OffMarketBuyAndSellDetailsModal, { PropertyDetails } from './OffMarketBuyAndSellDetailsModal';

type Props = {
  selectedBuilding: BuildingWithConvertedAddresses;
  isLoggedIn: boolean;
  isGlobal?: boolean;
  setLoginModalVisible: (visible: boolean) => void;
  refreshFeed: () => void;
  fetchComments: () => void;
  setNotificationsSubscriptionsToggle: (toggle: boolean) => void;
  setSlideToastIn: (toast: OffMarketToastMessage | null) => void;
  fetchConversations: () => void;
};

const OffMarketBuyAndSell = (props: Props) => {
  const {
    selectedBuilding,
    isLoggedIn,
    isGlobal,
    setLoginModalVisible,
    refreshFeed,
    fetchComments,
    setNotificationsSubscriptionsToggle,
    setSlideToastIn,
    fetchConversations,
  } = props;
  const { t } = useTranslation();
  const [couldSellSelected, setCouldSellSelected] = useState<boolean>(false);
  const [couldBuySelected, setCouldBuySelected] = useState<boolean>(false);
  const [showOffMarketButtonsInfo, setShowOffMarketButtonsInfo] =
    useState<boolean>(false);
  const [offMarketSellerCount, setOffMarketSellerCount] = useState<number>(0);
  const [offMarketBuyerCount, setOffMarketBuyerCount] = useState<number>(0);
  const [
    isLoadingOffMarketSellWillingness,
    setIsLoadingOffMarketSellWillingness,
  ] = useState<boolean>(false);
  const [
    isLoadingOffMarketBuyWillingness,
    setIsLoadingOffMarketBuyWillingness,
  ] = useState<boolean>(false);
  const offMarketInfoButtonRef = useRef<HTMLButtonElement | null>(null);
  const offMarketInfoModalRef = useRef<HTMLDivElement | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<'buy' | 'sell' | null>(null);

  const fetchOffMarketWillingness = () => {
    setIsLoadingOffMarketSellWillingness(true);
    setIsLoadingOffMarketBuyWillingness(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/off-market-willingness?buildingId=${selectedBuilding.id}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setOffMarketSellerCount(data.could_sell_count);
        setOffMarketBuyerCount(data.could_buy_count);
        // If the user has selected the could sell option, set the state to true
        setCouldSellSelected(data.could_sell_selected);
        // If the user has selected the could buy option, set the state to true
        setCouldBuySelected(data.could_buy_selected);
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoadingOffMarketSellWillingness(false);
        setIsLoadingOffMarketBuyWillingness(false);
      });
  };

  const handleOnClickCouldSell = (propertyDetails?: PropertyDetails) => {
    setIsLoadingOffMarketSellWillingness(true);
    fetch(`${process.env.REACT_APP_API_URL}/off-market-willingness`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      body: JSON.stringify({
        could_sell: !couldSellSelected,
        selected_building: selectedBuilding,
        property_description: propertyDetails?.description,
        property_size: propertyDetails?.size,
        property_rooms: propertyDetails?.rooms,
        property_floor: propertyDetails?.floor,
        property_has_elevator: propertyDetails?.hasElevator,
        property_has_balcony: propertyDetails?.hasBalcony,
        property_renovation_status: propertyDetails?.renovationStatus
      }),
    })
      .then(async (res) => {
        if (!res.ok) {
          // This will throw an error and go to the catch block
          return res.json().then(err => Promise.reject(err));
        }
        return res.json()
      })
      .then(
        (data: {
          could_sell_count: number;
          could_sell_selected: boolean;
          subscribed: boolean;
        }) => {
          setOffMarketSellerCount(data.could_sell_count);
          setIsLoadingOffMarketSellWillingness(false);
          setCouldSellSelected(data.could_sell_selected);
          setNotificationsSubscriptionsToggle(data.subscribed);
          fetchComments();
          refreshFeed();
          if (data.could_sell_selected && offMarketBuyerCount === 0) {
            setSlideToastIn('COULD_SELL_NO_BUYERS');
          } else if (data.could_sell_selected && offMarketBuyerCount > 0) {
            fetchConversations();
            setSlideToastIn('COULD_SELL_AND_BUYERS');
          }
        },
      )
      .catch((error) => {
        if (error.error_code === 'USER_SELLER_AND_BUYER') {
          setSlideToastIn('USER_SELLER_AND_BUYER');
        }
        setIsLoadingOffMarketSellWillingness(false);
        console.error('Error:', error);
      });
  };

  const handleOnClickCouldBuy = (propertyDetails?: PropertyDetails) => {
    setIsLoadingOffMarketBuyWillingness(true);
    fetch(`${process.env.REACT_APP_API_URL}/off-market-willingness`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      body: JSON.stringify({
        could_buy: !couldBuySelected,
        selected_building: selectedBuilding,
        property_description: propertyDetails?.description,
        property_size: propertyDetails?.size,
        property_rooms: propertyDetails?.rooms,
        property_floor: propertyDetails?.floor,
        property_has_elevator: propertyDetails?.hasElevator,
        property_has_balcony: propertyDetails?.hasBalcony,
        property_renovation_status: propertyDetails?.renovationStatus
      }),
    })
      .then(async (res) => {
        if (!res.ok) {
          // This will throw an error and go to the catch block
          return res.json().then(err => Promise.reject(err));
        }
        return res.json()
      })
      .then(
        (data: {
          could_buy_count: number;
          could_buy_selected: boolean;
          subscribed: boolean;
        }) => {
          setOffMarketBuyerCount(data.could_buy_count);
          setIsLoadingOffMarketBuyWillingness(false);
          setCouldBuySelected(data.could_buy_selected);
          setNotificationsSubscriptionsToggle(data.subscribed);
          fetchComments();
          refreshFeed();
          if (data.could_buy_selected && offMarketSellerCount === 0) {
            setSlideToastIn('COULD_BUY_NO_SELLERS');
          } else if (data.could_buy_selected && offMarketSellerCount > 0) {
            fetchConversations();
            setSlideToastIn('COULD_BUY_AND_SELLERS');
          }
        },
      )
      .catch((error) => {
        if (error.error_code === 'USER_SELLER_AND_BUYER') {
          setSlideToastIn('USER_SELLER_AND_BUYER');
        }
        setIsLoadingOffMarketBuyWillingness(false);
        console.error('Error:', error);
      });
  };

  const handleCouldSellClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'off_market_could_sell',
        category: 'User Interaction',
        action: 'Click',
        label: 'Off Market Could Sell',
        id: selectedBuilding.id,
        name: selectedBuilding.properties.name,
      },
    });

    if (!isLoggedIn) {
      setLoginModalVisible(true);
    } else if (couldSellSelected) {
      handleOnClickCouldSell();
    } else {
      setCurrentAction('sell');
      setModalOpen(true);
    }
  };

  const handleCouldBuyClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'off_market_could_buy',
        category: 'User Interaction',
        action: 'Click',
        label: 'Off Market Could Buy',
        id: selectedBuilding.id,
        name: selectedBuilding.properties.name,
      },
    });

    if (!isLoggedIn) {
      setLoginModalVisible(true);
    } else if (couldBuySelected) {
      handleOnClickCouldBuy();
    } else {
      setCurrentAction('buy');
      setModalOpen(true);
    }
  };

  const handleModalConfirm = (propertyDetails?: PropertyDetails) => {
    if (currentAction === 'sell') {
      handleOnClickCouldSell(propertyDetails);
    } else if (currentAction === 'buy') {
      handleOnClickCouldBuy(propertyDetails);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        offMarketInfoButtonRef.current &&
        !offMarketInfoButtonRef.current.contains(event.target as Node) &&
        offMarketInfoModalRef.current &&
        !offMarketInfoModalRef.current.contains(event.target as Node)
      ) {
        setShowOffMarketButtonsInfo(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [offMarketInfoButtonRef, offMarketInfoModalRef]);

  useEffect(() => {
    fetchOffMarketWillingness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding]);

  return (
    <div className={`report__off-market-buy-and-sell${isGlobal ? ' report__off-market-buy-and-sell--global' : ''}`}>
      <div className="report__off-market-buy-and-sell__buttons">
        <button
          disabled={couldBuySelected && !couldSellSelected}
          className={`report__off-market-buy-and-sell__buttons__button${couldSellSelected
            ? ' report__off-market-buy-and-sell__buttons__button--selected'
            : ''
            }`}
          onClick={handleCouldSellClick}
        >
          <CouldSellIcon selected={couldSellSelected} />
          <div className="report__off-market-buy-and-sell__buttons__button__text-and-count">
            <div className="report__off-market-buy-and-sell__buttons__button__text">
              <Trans
                i18nKey="building.offMarket.couldSell"
              />
            </div>
            <div className="report__off-market-buy-and-sell__buttons__button__count">
              {isLoadingOffMarketSellWillingness ? (
                <LoadingSpinner className="report__off-market-buy-and-sell__buttons__button__count__spinner" />
              ) : (
                `(${t('building.offMarket.count', { count: offMarketSellerCount || 0 })})`
              )}
            </div>
          </div>
        </button>
        <button
          disabled={couldSellSelected && !couldBuySelected}
          className={`report__off-market-buy-and-sell__buttons__button${couldBuySelected
            ? ' report__off-market-buy-and-sell__buttons__button--selected'
            : ''
            }`}
          onClick={handleCouldBuyClick}
        >
          <CouldBuyIcon selected={couldBuySelected} />
          <div className="report__off-market-buy-and-sell__buttons__button__text-and-count">
            <div className="report__off-market-buy-and-sell__buttons__button__text">
              <Trans
                i18nKey="building.offMarket.couldBuy"
              />
            </div>
            <div className="report__off-market-buy-and-sell__buttons__button__count">
              {isLoadingOffMarketBuyWillingness ? (
                <LoadingSpinner className="report__off-market-buy-and-sell__buttons__button__count__spinner" />
              ) : (
                `(${t('building.offMarket.count', { count: offMarketBuyerCount || 0 })})`
              )}
            </div>
          </div>
        </button>
      </div>
      {showOffMarketButtonsInfo && (
        <div className="report__off-market-buy-and-sell__info-modal-wrapper">
          <div
            className="report__off-market-buy-and-sell__info-modal"
            ref={offMarketInfoModalRef}
          >
            <div className="report__off-market-buy-and-sell__info-modal__text">
              <Trans i18nKey="building.offMarket.infoModalText">
                Could you sell or buy in this property? Express your interest by clicking the button - easier than ever! We connect you with interested parties anonymously and without obligations. Your email will only be shared if you decide to open a conversation or respond to the inquiry you receive. You can also remove your interest at any time.
                <Link to="/FAQ">Read more...</Link>
              </Trans>
            </div>
          </div>
        </div>
      )}
      <button
        className="report__off-market-buy-and-sell__info-button"
        onClick={() =>
          setShowOffMarketButtonsInfo(!showOffMarketButtonsInfo)
        }
        ref={offMarketInfoButtonRef}
      >
        <Trans i18nKey="building.offMarket.infoButton" />
        <QuestionMarkIcon color="#FFF" />
      </button>
      <OffMarketBuyAndSellDetailsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleModalConfirm}
        actionType={currentAction || 'buy'}
        isLoading={
          (currentAction === 'sell' && isLoadingOffMarketSellWillingness) ||
          (currentAction === 'buy' && isLoadingOffMarketBuyWillingness)
        }
      />
    </div>
  );
};

export default OffMarketBuyAndSell;
