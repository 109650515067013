import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row } from 'react-bootstrap';
import TagManager from 'react-gtm-module';
import SearchInput from '../SearchInput/SearchInput';
import MainMenu from '../MainMenu/MainMenu';
import NavbarSignupLogin from '../NavbarSignupLogin/NavbarSignupLogin';
import {
  BuildingWithConvertedAddresses,
  Comment,
  LatLng,
  OffMarketWillingnessEvent as OffMarketWillingnessEventType,
} from '../utils/types';
import SignupLoginModal from '../SignupLoginModal/SignupLoginModal';
import BuildingMap from '../HouseMap/BuildingMap';
import PageMetaTags from '../BuildingModal/PageMetaTags';
import FeedIcon from './FeedIcon';
import MapIcon from './MapIcon';
import LeftArrow from './LeftArrow';
import LoadingSpinner from '../common/LoadingSpinner';
import RefreshIcon from './RefreshIcon';
import FeedComments from '../FeedComments/FeedComments';
import '../../App.scss';
import './AppContent.scss';
import '../FeedComments/FeedComments.scss';
import { getCookie } from '../utils/utils';
import { Trans, useTranslation } from 'react-i18next';
import NavbarLanguageSelector from '../NavbarLanguageSelector/NavbarLanguageSelector';
import BuildingModal from '../BuildingModal/BuildingModal';
import BuildingModalGlobal from '../BuildingModal/BuildingModalGlobal';
import { getUserCountry } from './getUserCountry';
import NavbarMessages from '../NavbarMessages/NavbarMessages';
import MessagesModal from '../MessagesModal/MessagesModal';

const AppContent = () => {
  const { t } = useTranslation();
  const [reportVisible, setReportVisible] = useState(false);
  const [selectedBuilding, setSelectedBuilding] =
    useState<BuildingWithConvertedAddresses | null>(null);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isRefreshingRef = useRef(false);
  const [expiresAt, setExpiresAt] = useState<number | null>(null);
  const [triggeredFromSearch, setTriggeredFromSearch] = useState(false);
  const [mapCenter, setMapCenter] = useState<LatLng>({
    lat: 60.168495304773955,
    lng: 24.92855807961784,
  });
  const [feedViewActive, setFeedViewActive] = useState(false);
  const [feedComments, setFeedComments] = useState<Comment[]>([]);
  const [feedPagination, setFeedPagination] = useState({
    offset: 0,
    limit: 20,
    total: 0,
    hasMore: true
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [showFeed, setShowFeed] = useState(true);
  const [isRefreshingFeed, setIsRefreshingFeed] = useState(false);
  const [showImageModal, setShowImageModal] = useState<string | null>(null);
  const [countryOfFeedContent, setCountryOfFeedContent] = useState<string | null>("Finland");
  const [lastMapBoundaryBoxCenter, setLastMapBoundaryBoxCenter] = useState<LatLng | null>(null);
  const [lastZoomLevel, setLastZoomLevel] = useState<number | null>(null);
  const [isLoadingFeed, setIsLoadingFeed] = useState(false);
  const [messagesModalVisible, setMessagesModalVisible] = useState(false);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const handleToggleView = useCallback(() => {
    // TODO: A more elegant solution needed, now feed is polled on mobile before it is shown.
    // Show feed is by default true, but it is only used on desktop.
    // No need to keep polling on mobile if feed is not shown.
    setShowFeed(false);
    setFeedViewActive((prev) => !prev);
  }, []);

  // Ensure refreshToken is stable and doesn't change on every render
  const refreshToken = useCallback(async () => {
    if (isRefreshingRef.current) return;
    isRefreshingRef.current = true;
    try {
      const csrfToken = getCookie('csrf_refresh_token');
      if (!csrfToken) {
        console.error('CSRF token not found');
        setIsLoggedIn(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/refresh-token`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': csrfToken,
          },
        },
      );
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        const newExpiresAt = Date.now() + data.expires_in * 1000;
        setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      setIsLoggedIn(false);
    } finally {
      isRefreshingRef.current = false;
    }
  }, []);

  // Effect to schedule token refresh when expiresAt changes
  useEffect(() => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }

    if (expiresAt != null) {
      const minRefreshInterval = 60 * 1000; // 60 seconds in milliseconds
      const timeUntilExpiry = expiresAt - Date.now();

      if (timeUntilExpiry > minRefreshInterval) {
        const refreshTime = timeUntilExpiry - minRefreshInterval;
        refreshTimeoutRef.current = setTimeout(() => {
          refreshToken();
        }, refreshTime);
      } else if (timeUntilExpiry > 0) {
        console.warn('Token expires soon; refreshing token immediately.');
        refreshToken();
      } else {
        console.warn('Token has expired; user will be logged out.');
        setIsLoggedIn(false);
      }
    }

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [expiresAt, refreshToken]); // Only depends on expiresAt

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/check-login-status`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
          },
        );
        const data = await response.json();
        setIsLoggedIn(data.isLoggedIn);
        if (data.isLoggedIn && data.expires_in) {
          const newExpiresAt = Date.now() + data.expires_in * 1000;
          setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();

    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, []);

  const toggleReportVisible = useCallback(() => {
    setReportVisible((prev) => !prev);
  }, []);

  const selectBuilding = useCallback(
    (building) => {
      if (building.centroid) {
        setMapCenter({
          lat: building.centroid.lat,
          lng: building.centroid.lng,
        });
      }
      setSelectedBuilding(building);
      toggleReportVisible();
    },
    [setMapCenter, setSelectedBuilding, toggleReportVisible],
  );

  // Modify handleLoginSuccess to accept expires_in and set expiresAt
  const handleLoginSuccess = useCallback((expiresIn: number) => {
    setIsLoggedIn(true);
    const newExpiresAt = Date.now() + expiresIn * 1000;
    setExpiresAt(newExpiresAt); // Update expiresAt to schedule next refresh
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userCoordinates: LatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setMapCenter(userCoordinates);
        getUserCountry(userCoordinates).then((country) => {
          setCountryOfFeedContent(country);
        });
      },
      (error) => {
        console.error('Error getting user location:', error);
        getUserCountry().then((country) => {
          setCountryOfFeedContent(country);
        });
      },
      {
        timeout: 5000,
        maximumAge: 60000
      }
    );

    // Check for 'building-id' in the URL on component mount
    const params = new URLSearchParams(window.location.search);
    const buildingId = params.get('building-id');
    if (buildingId) {
      // Fetch the building data using the buildingId
      fetch(
        `${process.env.REACT_APP_API_URL}/report?building-id=${buildingId}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.data) {
            setSelectedBuilding(data.data);
            setMapCenter({
              lat: data.data.centroid.lat,
              lng: data.data.centroid.lng,
            });
            setReportVisible(true);
          } else {
            console.error('No data found for the given building ID');
          }
        })
        .catch((error) => {
          console.error('Error fetching building by ID:', error);
        });
    }
  }, []);

  // Update the URL when the report visibility or selected building changes
  useEffect(() => {
    const url = new URL(window.location.href);
    if (selectedBuilding && reportVisible) {
      // Add 'building-id' to the URL
      url.searchParams.set('building-id', selectedBuilding.id);
      window.history.pushState({}, '', url.toString());
    } else {
      // Remove 'building-id' from the URL
      url.searchParams.delete('building-id');
      window.history.pushState({}, '', url.toString());
    }
  }, [selectedBuilding, reportVisible]);

  const fetchFeed = useCallback(async (loadMore = false) => {
    try {
      const offset = loadMore ? feedPagination.offset + feedPagination.limit : 0;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/feed?country=${countryOfFeedContent}&offset=${offset}&limit=${feedPagination.limit}`, 
        {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      });
      const data = await response.json();
      
      if (loadMore) {
        setFeedComments(prev => [...prev, ...data.items]);
      } else {
        setFeedComments(data.items);
      }
      
      setFeedPagination({
        offset: loadMore ? feedPagination.offset + feedPagination.limit : 0,
        limit: data.pagination.limit,
        total: data.pagination.total,
        hasMore: data.pagination.has_more
      });
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryOfFeedContent, feedPagination.limit]);

  const handleLoadMoreFeed = useCallback(async () => {
    if (!feedPagination.hasMore || isLoadingMore) return;
    
    setIsLoadingMore(true);
    try {
      const offset = feedPagination.offset + feedPagination.limit;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/feed?country=${countryOfFeedContent}&offset=${offset}&limit=${feedPagination.limit}`, 
        {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      });
      const data = await response.json();
      
      // Only update state if we actually got new items
      if (data.items && data.items.length > 0) {
        setFeedComments(prev => [...prev, ...data.items]);
        setFeedPagination({
          offset: offset,
          limit: data.pagination.limit,
          total: data.pagination.total,
          hasMore: data.pagination.has_more
        });
      } else if (!data.pagination.has_more) {
        // Handle case with no more items
        setFeedPagination(prev => ({
          ...prev,
          hasMore: false
        }));
      }
    } catch (error) {
      console.error('Error loading more feed items:', error);
    } finally {
      setIsLoadingMore(false);
    }
  }, [feedPagination.hasMore, feedPagination.limit, feedPagination.offset, countryOfFeedContent, isLoadingMore]);

  const handleRefreshFeedClick = useCallback(async () => {
    setIsRefreshingFeed(true);
    await fetchFeed(false);
    setIsRefreshingFeed(false);
  }, [fetchFeed]);

  useEffect(() => {
    if (feedViewActive || showFeed) {
      setIsLoadingFeed(true);
      // Use an async IIFE to allow await
      (async () => {
        await fetchFeed(false);
        setIsLoadingFeed(false);
      })();
    }
  }, [feedViewActive, showFeed, fetchFeed]);

  useEffect(() => {
    if (feedViewActive || showFeed) {
      const intervalId = setInterval(() => {
        // Don't refresh if we're currently loading more items
        if (!isLoadingMore) {
          fetchFeed(false);
        }
      }, 30000); // 30 seconds in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [feedViewActive, showFeed, fetchFeed, isLoadingMore]);

  const handleFeedCommentClick = useCallback(
    async (comment: Comment | OffMarketWillingnessEventType) => {
      try {
        // If you need to fetch building data from an API:
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/report?building-id=${comment.buildingId}`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
          },
        );
        const data = await response.json();
        // "data.data" (or however you structure your response) is your building
        const building = data.data;

        // 1) Center the map to the building's centroid
        if (building.centroid) {
          setMapCenter({
            lat: building.centroid.lat,
            lng: building.centroid.lng,
          });
          setTriggeredFromSearch(true);
        }

        // 2) "Select" the building to open the report
        selectBuilding(building);
      } catch (error) {
        console.error('Error fetching building from comment:', error);
      }
    },
    [selectBuilding, setMapCenter, setTriggeredFromSearch],
  );

  const handleShowSignupLoginModal = useCallback(() => {
    setLoginModalVisible(true);
    TagManager.dataLayer({
      dataLayer: {
        event: 'open_signup_login_modal',
        category: 'User Interaction',
        action: 'Click',
        label: 'Open Signup Login Modal',
      },
    });
  }, [setLoginModalVisible]);

  const toggleMessagesModal = useCallback(() => {
    setMessagesModalVisible(prev => {
      // If we're opening the modal (prev is false, will become true)
      if (!prev) {
        // setNewMessagesCount(0);
        // Here we'd also call an API to mark messages as read
      } else {
        setSelectedConversation(null);
      }
      return !prev;
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'toggle_messages_modal',
        category: 'User Interaction',
        action: 'Click',
        label: messagesModalVisible ? 'Close Messages Modal' : 'Open Messages Modal',
      },
    });
  }, [messagesModalVisible]);

  const fetchConversations = useCallback(async () => {
    if (!isLoggedIn) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/conversations`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch conversations');
      }

      const data = await response.json();

      // Transform the data to match your component's expected format
      const formattedConversations = data.map((conv: any) => {
        // Validate timestamps before creating Date objects
        let lastMessageTimestamp;
        try {
          lastMessageTimestamp = new Date(conv.lastMessage.timestamp);
          // Check if the date is valid
          if (isNaN(lastMessageTimestamp.getTime())) {
            console.warn('Invalid lastMessage timestamp in conversation:', conv);
            lastMessageTimestamp = new Date(); // Fallback to current date
          }
        } catch (error) {
          console.error('Error parsing lastMessage timestamp:', error, conv);
          lastMessageTimestamp = new Date(); // Fallback to current date
        }

        let endedAt;
        if (conv.endedAt) {
          try {
            endedAt = new Date(conv.endedAt);
            // Check if the date is valid
            if (isNaN(endedAt.getTime())) {
              console.warn('Invalid endedAt timestamp in conversation:', conv);
              endedAt = undefined;
            }
          } catch (error) {
            console.error('Error parsing endedAt timestamp:', error, conv);
            endedAt = undefined;
          }
        }

        return {
          id: conv.id,
          otherUser: {
            name: conv.otherUser.name,
            avatar: conv.otherUser.avatar,
          },
          lastMessage: {
            text: conv.lastMessage.text,
            timestamp: lastMessageTimestamp,
            firstMessage: conv.lastMessage.firstMessage,
          },
          buildingInfo: {
            name: conv.buildingInfo.name,
            address: conv.buildingInfo.address,
          },
          unread: conv.unread,
          isEnded: conv.isEnded || false,
          endedByUserId: conv.endedByUserId,
          endedAt: endedAt,
          otherUserHasSentMessage: conv.otherUserHasSentMessage || false,
        };
      });

      setConversations(formattedConversations);

      // Calculate new messages count
      const unreadCount = formattedConversations.reduce((acc, conv) => acc + conv.unread, 0);
      setNewMessagesCount(unreadCount);

    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  }, [isLoggedIn]);

  // Fetch conversations when logged in
  useEffect(() => {
    if (isLoggedIn) {
      fetchConversations();

      // Set up polling for new messages (every 30 seconds)
      const intervalId = setInterval(() => {
        fetchConversations();
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn, fetchConversations]);

  return (
    <div className="app-content-wrap">
      <PageMetaTags
        title={t('metaTags.title')}
        description={t('metaTags.description')}
        ogTitle={t('metaTags.ogTitle')}
        ogDescription={t('metaTags.ogDescription')}
        ogImage="https://www.jaakko.com/jaakko_logo.png"
        ogUrl="https://www.jaakko.com"
      />
      <div
        className="app-content"
        key={feedViewActive ? 'feed-active' : 'feed-inactive'}
      >
        <div
          className={`custom-navbar ${reportVisible ? 'custom-navbar--building-view-open' : ''}
          ${showImageModal ? 'custom-navbar--image-modal-open' : ''}
          ${messagesModalVisible ? 'custom-navbar--messages-modal-open' : ''}
          `}
        >
          <div className="custom-navbar__section custom-navbar__section--logo">
            <a href="/">
              <img
                className="custom-navbar__logo"
                src="/assets/logo_only.png"
                alt="Jaakko logo"
                height="36"
              />
            </a>
          </div>
          <div className="custom-navbar__section__controls">
            <NavbarLanguageSelector />
            <NavbarMessages
              toggleMessagesModal={toggleMessagesModal}
              newMessagesCount={newMessagesCount}
              isLoggedIn={isLoggedIn}
              setLoginModalVisible={setLoginModalVisible}
            />
            <NavbarSignupLogin
              toggleLoginModal={handleShowSignupLoginModal}
              isLoggedIn={isLoggedIn}
            />
          </div>
          <div className="custom-navbar__section__main-menu">
            <MainMenu />
          </div>
        </div>
        {loginModalVisible && (
          <SignupLoginModal
            toggleSignupLoginModalVisible={() => setLoginModalVisible(false)}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
            onLoginSuccess={handleLoginSuccess}
          />
        )}
        <div
          key={feedViewActive ? 'feed-active' : 'feed-inactive'}
          className="app-content-wrapper"
        >
          <Row
            className="feed-and-map-wrapper"
            key={feedViewActive ? 'feed-active' : 'feed-inactive'}
          >
            {feedViewActive ? (
              <FeedComments
                isLoadingFeed={isLoadingFeed}
                feedComments={feedComments}
                handleFeedCommentClick={handleFeedCommentClick}
                handleRefreshFeedClick={handleRefreshFeedClick}
                isRefreshingFeed={isRefreshingFeed || !countryOfFeedContent}
                isLoggedIn={isLoggedIn}
                setLoginModalVisible={setLoginModalVisible}
                handleLoadMoreFeed={handleLoadMoreFeed}
                isLoadingMore={isLoadingMore}
                hasMoreFeed={feedPagination.hasMore}
              />
            ) : (
              <>
                <div
                  className={`feed-comments ${showFeed ? 'feed-comments--show' : ''
                    }`}
                >
                  <div className="feed-comments__comments-list__controls">
                    <div className="feed-comments__comments-list__controls__title">
                      <Trans i18nKey="feed.title" />
                    </div>
                    <button
                      onClick={handleRefreshFeedClick}
                      className="feed-comments__comments-list__controls__refresh-button"
                    >
                      {isRefreshingFeed || isLoadingFeed ? <LoadingSpinner /> : <RefreshIcon />}
                    </button>
                  </div>
                  <FeedComments
                    isLoadingFeed={isLoadingFeed}
                    feedComments={feedComments}
                    handleFeedCommentClick={handleFeedCommentClick}
                    handleRefreshFeedClick={handleRefreshFeedClick}
                    isRefreshingFeed={isRefreshingFeed || !countryOfFeedContent}
                    isLoggedIn={isLoggedIn}
                    setLoginModalVisible={setLoginModalVisible}
                    handleLoadMoreFeed={handleLoadMoreFeed}
                    isLoadingMore={isLoadingMore}
                    hasMoreFeed={feedPagination.hasMore}
                  />
                  <div className="feed-comments__close-button-wrapper">
                    <button
                      className="feed-comments__close-button-wrapper__close-button"
                      onClick={() => setShowFeed(false)}
                    >
                      <div className="feed-comments__close-button-wrapper__close-button__text">
                        <Trans i18nKey="feed.button.hideFeed" />
                      </div>
                      <div className="feed-comments__close-button-wrapper__close-button__icon">
                        <LeftArrow />
                      </div>
                    </button>
                  </div>
                </div>
                <BuildingMap
                  feedIsOpen={showFeed}
                  mobileFeedIsOpen={feedViewActive}
                  mapCenter={mapCenter}
                  selectedBuilding={selectedBuilding}
                  triggeredFromSearch={triggeredFromSearch}
                  countryOfFeedContent={countryOfFeedContent}
                  lastMapBoundaryBoxCenter={lastMapBoundaryBoxCenter}
                  lastZoomLevel={lastZoomLevel}
                  setShowFeed={setShowFeed}
                  selectBuilding={selectBuilding}
                  setTriggeredFromSearch={setTriggeredFromSearch}
                  setCountryOfFeedContent={setCountryOfFeedContent}
                  setLastMapBoundaryBoxCenter={setLastMapBoundaryBoxCenter}
                  setLastZoomLevel={setLastZoomLevel}
                />
              </>
            )}
          </Row>
        </div>
        {!feedViewActive && (
          <SearchInput
            setMapCenter={setMapCenter}
            setTriggeredFromSearch={setTriggeredFromSearch}
            setCountryOfFeedContent={setCountryOfFeedContent}
            countryOfFeedContent={countryOfFeedContent}
            feedHidden={!showFeed}
          />
        )}
        {selectedBuilding && reportVisible && (
          selectedBuilding.addresses[0].country !== 'Finland' ?
            <BuildingModalGlobal
              isLoggedIn={isLoggedIn}
              selectedBuilding={selectedBuilding}
              showImageModal={showImageModal}
              countryOfFeedContent={countryOfFeedContent}
              messagesModalVisible={messagesModalVisible}
              toggleReportVisible={toggleReportVisible}
              setSelectedBuilding={setSelectedBuilding}
              setLoginModalVisible={setLoginModalVisible}
              refreshFeed={handleRefreshFeedClick}
              setShowImageModal={setShowImageModal}
              setCountryOfFeedContent={setCountryOfFeedContent}
              fetchConversations={fetchConversations}
            /> :
            <BuildingModal
              isLoggedIn={isLoggedIn}
              selectedBuilding={selectedBuilding}
              showImageModal={showImageModal}
              countryOfFeedContent={countryOfFeedContent}
              messagesModalVisible={messagesModalVisible}
              toggleReportVisible={toggleReportVisible}
              setSelectedBuilding={setSelectedBuilding}
              setLoginModalVisible={setLoginModalVisible}
              refreshFeed={handleRefreshFeedClick}
              setShowImageModal={setShowImageModal}
              setCountryOfFeedContent={setCountryOfFeedContent}
              fetchConversations={fetchConversations}
            />
        )}
        <button className="map-feed-toggle-button" onClick={handleToggleView}>
          {feedViewActive ? (
            <>
              <div className="map-feed-toggle-button__map-text">
                <Trans i18nKey="map.showMap" />
              </div>
              <MapIcon />
            </>
          ) : (
            <>
              <div className="map-feed-toggle-button__feed-text">
                <Trans i18nKey="map.showFeed" />
              </div>
              <FeedIcon />
            </>
          )}
        </button>
        {isLoggedIn && messagesModalVisible && (
          <MessagesModal
            isVisible={messagesModalVisible}
            toggleMessagesModal={toggleMessagesModal}
            conversations={conversations}
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            refreshConversations={fetchConversations}
          />
        )}
      </div>
    </div>
  );
};

export default AppContent;
