import React, { useRef, useEffect } from 'react';
import './Conversation.scss';
import { Trans, useTranslation } from 'react-i18next';
import ChatUserAvatar from './ChatUserAvatar';

type ConversationProps = {
  conversationId: string;
  conversation: {
    id: string;
    otherUser: {
      name: string;
      avatar: string;
    };
    buildingInfo: {
      name: string;
      address: string;
    };
    isEnded?: boolean;
    endedAt?: Date;
  };
  messages: {
    id: string;
    sender: 'self' | 'other';
    text: string;
    timestamp: Date;
  }[];
  isLoading: boolean;
  newMessage: string;
  otherUserHasSentMessage: boolean;
  setNewMessage: (message: string) => void;
  sendMessage: () => void;
  goBack: () => void;
};

const Conversation: React.FC<ConversationProps> = ({
  conversationId,
  conversation,
  messages,
  isLoading,
  newMessage,
  setNewMessage,
  sendMessage,
  goBack,
  otherUserHasSentMessage
}) => {
  const { t, i18n } = useTranslation();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const otherUserName = otherUserHasSentMessage ? conversation.otherUser.name : t("messages.anonymousUser");
  const thisUserHasSentMessage = messages.some(message => message.sender === 'self' && message.text !== 'INTERESTED_BUYER' && message.text !== 'INTERESTED_BUYER');

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Handle send on Enter key
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  // Format timestamp to display time in i18n language format
  const formatMessageTime = (timestamp: Date) => {
    // Check if timestamp is valid
    if (!timestamp || isNaN(timestamp.getTime())) {
      console.warn('Invalid timestamp detected:', timestamp);
      return '';
    }

    const userLocale = i18n.language || 'en-GB';
    return new Intl.DateTimeFormat(userLocale, {
      hour: '2-digit',
      minute: '2-digit'
    }).format(timestamp);
  };

  // Filter out the first message and handle the case when there's only one message
  const displayMessages = messages.length <= 1 ? [] : messages.slice(1);

  // Group messages by date (only for messages we'll display)
  const groupedMessages = displayMessages.reduce((groups: any, message) => {
    // Ensure timestamp is valid
    if (!message.timestamp || isNaN(new Date(message.timestamp).getTime())) {
      console.warn('Invalid message timestamp detected:', message);
      return groups;
    }

    const date = new Date(message.timestamp).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  // Format date for date separators based on i18n language
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      console.warn('Invalid date string detected:', dateStr);
      return '';
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Get locale from i18n
    const userLocale = i18n.language || 'en-GB';

    if (date.toDateString() === today.toDateString()) {
      return t('messages.today');
    } else if (date.toDateString() === yesterday.toDateString()) {
      return t('messages.yesterday');
    } else {
      return new Intl.DateTimeFormat(userLocale, {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      }).format(date);
    }
  };

  // Format the ended date if available
  const formatEndedDate = (date?: Date) => {
    if (!date || isNaN(date.getTime())) {
      return '';
    }

    const userLocale = i18n.language || 'en-GB';
    return new Intl.DateTimeFormat(userLocale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div className="conversation">
      <div className="conversation__header">
        <button className="conversation__back" onClick={goBack}>
          <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ display: 'block', fill: 'none', height: '16px', width: '16px', stroke: 'currentcolor', strokeWidth: 4, overflow: 'visible' }}>
            <path d="m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932"></path>
          </svg>
        </button>
        <div className="conversation__user">
          <div className="conversation__user-avatar">
            {conversation.otherUser.avatar ? (
              <img src={conversation.otherUser.avatar} alt={otherUserName} />
            ) : (
              <ChatUserAvatar name={otherUserName} />
            )}
          </div>
          <div className="conversation__user-info">
            <h3>{otherUserName}</h3>
            <p>{conversation.buildingInfo.address}</p>
          </div>
        </div>
      </div>

      <div className="conversation__messages">
        {isLoading ? (
          <div className="conversation__loading">
            <div className="conversation__loading-spinner"></div>
          </div>
        ) : (
          <>
            {/* Welcome message when there are no messages to display */}
            <div className="conversation__welcome-message">
              <div className="conversation__welcome-icon">
                {/*                   <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" style={{ display: 'block', height: '32px', width: '32px', fill: 'currentcolor' }}>
                    <path d="M16 0c8.833 0 16 7.167 16 16s-7.167 16-16 16S0 24.833 0 16 7.167 0 16 0zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5zm0 10.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm-6.5 0c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm13 0c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm-6.5 9c4.694 0 8.5 1.343 8.5 3s-3.806 3-8.5 3-8.5-1.343-8.5-3 3.806-3 8.5-3z"></path>
                  </svg> */}
              </div>
              <h4 className="conversation__welcome-title">
                <Trans i18nKey="messages.welcomeTitle" />
              </h4>
              <p className="conversation__welcome-text">
                {
                  otherUserHasSentMessage ? (
                    <Trans i18nKey="messages.welcomeText" values={{ email: otherUserName }} />
                  ) : (
                    <Trans i18nKey="messages.welcomeTextOtherUserHasNotSentMessage" values={{ email: otherUserName }} />
                  )
                }
              </p>
              {
                !thisUserHasSentMessage && (
                  <p>
                    <Trans i18nKey="messages.welcomeTextEmailDisclaimer" />
                  </p>
                )
              }
              <div className="conversation__welcome-tips">
                <p>
                  <Trans i18nKey="messages.welcomeTips" />
                </p>
                <ul>
                  <li>
                    <Trans i18nKey="messages.tipPolite" />
                  </li>
                  <li>
                    <Trans i18nKey="messages.tipCaution" />
                  </li>
                  <li>
                    <Trans i18nKey="messages.tipDetails" />
                  </li>
                </ul>
              </div>
            </div>

            {/* Display messages grouped by date */}
            {(() => {
              try {
                return Object.keys(groupedMessages).map(date => (
                  <div key={date} className="conversation__message-group">
                    <div className="conversation__date-separator">
                      <span>{formatDate(date)}</span>
                    </div>

                    {groupedMessages[date].map((message: any) => (
                      <div
                        key={message.id}
                        className={`conversation__message conversation__message--${message.sender}`}
                      >
                        {message.sender === 'other' && (
                          <div className="conversation__message-avatar">
                            {conversation.otherUser.avatar ? (
                              <img src={conversation.otherUser.avatar} alt={conversation.otherUser.name} />
                            ) : (
                              <ChatUserAvatar name={conversation.otherUser.name} height={16} width={16} />
                            )}
                          </div>
                        )}
                        <div className="conversation__message-content">
                          <div className="conversation__message-bubble">
                            {message.text}
                          </div>
                          <div className="conversation__message-time">
                            {formatMessageTime(message.timestamp)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ));
              } catch (error) {
                console.error('Error rendering messages:', error);
                return (
                  <div className="conversation__error-message">
                    <p>There was an error displaying messages. Please try refreshing the page.</p>
                  </div>
                );
              }
            })()}

            {/* Show ended conversation message if the conversation is ended */}
            {conversation.isEnded && (
              <div className="conversation__ended-message">
                <p>
                  <Trans i18nKey="messages.chatEndedMessage">
                    This conversation has been ended. No new messages can be sent.
                  </Trans>
                </p>
                {conversation.endedAt && (
                  <span className="conversation__ended-date">
                    {formatEndedDate(conversation.endedAt)}
                  </span>
                )}
              </div>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className={`conversation__input ${conversation.isEnded ? 'conversation__input--disabled' : ''}`}>
        <textarea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={conversation.isEnded ? t('messages.chatEndedPlaceholder') : t('messages.writePlaceholder')}
          rows={1}
          disabled={conversation.isEnded}
        />
        <button
          className="conversation__send-button"
          onClick={sendMessage}
          disabled={!newMessage.trim() || conversation.isEnded}
        >
          <Trans i18nKey="messages.send">Send</Trans>
        </button>
      </div>
    </div>
  );
};

export default Conversation; 