import React from 'react';
import PlotOwnershipIcon from './Icons/PlotOwnershipIcon';
import RedemptionClauseIcon from './Icons/RedemptionClauseIcon';
import { ArticlesOfAssociationBaseInfo } from '../utils/types';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

type Props = {
  analyzedArticlesOfAssociationBaseInfo: ArticlesOfAssociationBaseInfo | null;
};

const PlotOwnershipAndRedemptionClause: React.FC<Props> = ({
  analyzedArticlesOfAssociationBaseInfo,
}) => {
  const plotOwnership =
    analyzedArticlesOfAssociationBaseInfo?.ownership_of_the_plot.includes(
      'rented',
    )
      ? t('building.generalInfo.plotOwnership.rented')
      : t('building.generalInfo.plotOwnership.owned');
  const hasApartmentRedemptionClause =
    analyzedArticlesOfAssociationBaseInfo?.housing_company_or_shareholders_has_apartment_redemption_clause
      ? t('building.generalInfo.redemptionClause.yes')
      : t('building.generalInfo.redemptionClause.no');

  return (
    <div className="report__property__plot-ownership-and-redemption-clause-wrapper">
      <div className="report__property__plot-ownership-wrapper">
        <div className="report__property__plot-ownership">
          <PlotOwnershipIcon
            plotIsOwn={
              !analyzedArticlesOfAssociationBaseInfo
                ? null
                : !analyzedArticlesOfAssociationBaseInfo?.ownership_of_the_plot.includes(
                  'rented',
                )
                  ? 'yes'
                  : 'no'
            }
          />
        </div>
        <div className="report__property__additional-info">
          <div className="report__property__general-info__title">
            <Trans
              i18nKey="building.generalInfo.plotOwnership.title"
            >
              Plot
            </Trans>
          </div>
          <span
            className={`report__property__general-info__name${analyzedArticlesOfAssociationBaseInfo
              ? ''
              : ' report__property__general-info__name--blurred'
              }`}
          >
            {analyzedArticlesOfAssociationBaseInfo ? plotOwnership : '-'}
          </span>
        </div>
      </div>
      <div className="report__property__redemption-clause-wrapper">
        <div className="report__property__redemption-clause">
          <RedemptionClauseIcon
            hasRedemptionClause={
              !analyzedArticlesOfAssociationBaseInfo
                ? null
                : analyzedArticlesOfAssociationBaseInfo.housing_company_or_shareholders_has_apartment_redemption_clause
                  ? 'no'
                  : 'yes'
            }
          />
        </div>
        <div className="report__property__additional-info">
          <div className="report__property__general-info__title">
            <Trans
              i18nKey="building.generalInfo.redemptionClause.title"
            >
              Redemption Clause
            </Trans>
          </div>
          <span
            className={`report__property__general-info__name${analyzedArticlesOfAssociationBaseInfo
              ? ''
              : ' report__property__general-info__name--blurred'
              }`}
          >
            {analyzedArticlesOfAssociationBaseInfo
              ? hasApartmentRedemptionClause
              : '-'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlotOwnershipAndRedemptionClause;
