import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon } from '../common/CloseIcon';
import { Trans, useTranslation } from 'react-i18next';
import './OffMarketBuyAndSellDetailsModal.scss'; // We'll create this stylesheet
import ExpanderArrow from './ExpanderArrow';
import { CSSTransition } from 'react-transition-group';
import OffMarketSellIcon from '../FeedComments/OffMarketSellIcon';
import OffMarketBuyIcon from '../FeedComments/OffMarketBuyIcon';

type OffMarketAction = 'buy' | 'sell';

export interface PropertyDetails {
  description?: string;
  size?: number;
  rooms?: string;
  floor?: number;
  hasElevator?: boolean;
  hasBalcony?: boolean;
  renovationStatus?: string;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (propertyDetails?: PropertyDetails) => void;
  actionType: OffMarketAction;
  isLoading: boolean;
};

const OffMarketBuyAndSellDetailsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  actionType,
  isLoading
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const [rooms, setRooms] = useState<string>('');
  const [floor, setFloor] = useState<string>('');
  const [hasElevator, setHasElevator] = useState<boolean>(false);
  const [hasBalcony, setHasBalcony] = useState<boolean>(false);
  const [renovationStatus, setRenovationStatus] = useState<string>('');
  const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false);

  if (!isOpen) return null;

  const handleSubmit = () => {
    const propertyDetails: PropertyDetails = {};

    if (description) propertyDetails.description = description;
    if (size) propertyDetails.size = Number(size);
    if (rooms) propertyDetails.rooms = rooms;
    if (floor) propertyDetails.floor = Number(floor);
    propertyDetails.hasElevator = hasElevator;
    propertyDetails.hasBalcony = hasBalcony;
    if (renovationStatus) propertyDetails.renovationStatus = renovationStatus;

    setDescription('');
    setSize('');
    setRooms('');
    setFloor('');
    setHasElevator(false);
    setHasBalcony(false);
    setRenovationStatus('');

    onConfirm(propertyDetails);
  };

  const toggleDetailsExpansion = () => {
    setIsDetailsExpanded(!isDetailsExpanded);
  };

  // Use createPortal to render outside the parent component hierarchy
  return ReactDOM.createPortal(
    <div className="off-market-modal-overlay">
      <div className="off-market-modal">
        <div className="off-market-modal__close-button-wrapper">
          <button
            className="off-market-modal__close-button"
            onClick={
              () => {
                setDescription('');
                setSize('');
                setRooms('');
                setFloor('');
                setHasElevator(false);
                setHasBalcony(false);
                setRenovationStatus('');
                setIsDetailsExpanded(false);
                onClose();
              }
            }
            disabled={isLoading}
          >
            <CloseIcon size={20} />
          </button>
        </div>
        <div className="off-market-modal__header">
          <h2 className="off-market-modal__title">
            {actionType === 'sell' ? (
              <OffMarketSellIcon
                width="26px"
                height="26px"
                className="off-market-modal__sell-icon"
              />
            ) : (
              <OffMarketBuyIcon
                width="26px"
                height="26px"
                className="off-market-modal__buy-icon"
              />
            )}
            <Trans
              i18nKey={`building.offMarket.modal.${actionType}.title`}
            />
          </h2>
        </div>

        <div className="off-market-modal__content">
          <p className="off-market-modal__description">
            <Trans
              i18nKey={`building.offMarket.modal.${actionType}.description`}
            />
          </p>

          <div className="off-market-modal__form">
            {/*             <h3 className="off-market-modal__form-title">
              <Trans i18nKey="building.offMarket.modal.detailsOptional" />
            </h3> */}

            <div className="off-market-modal__expander">
              <button
                onClick={toggleDetailsExpansion}
                className="off-market-modal__expander-button"
              >
                <Trans i18nKey="building.offMarket.modal.additionalDetails" />
                <ExpanderArrow isExpanded={isDetailsExpanded} />
              </button>
            </div>

            <CSSTransition
              in={isDetailsExpanded}
              timeout={300}
              classNames="details-expand"
              unmountOnExit
            >
              <div className="off-market-modal__additional-details">

                <div className="off-market-modal__form-field">
                  <label htmlFor="property-description">
                    <Trans i18nKey="building.offMarket.modal.description" />
                  </label>
                  <textarea
                    id="property-description"
                    className="off-market-modal__textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder={actionType === 'buy' ? t('building.offMarket.modal.buyingInterestDescriptionPlaceholder') : t('building.offMarket.modal.sellingInterestDescriptionPlaceholder')}
                    rows={3}
                    maxLength={450}
                  />
                  <span className="off-market-modal__textarea-counter">{description.length} / 450</span>
                </div>
                <div className="off-market-modal__form-row">
                  <div className="off-market-modal__form-field off-market-modal__form-field--half">
                    <label htmlFor="property-size">
                      {actionType === 'buy' ? <Trans i18nKey="building.offMarket.modal.sizeClass" /> : <Trans i18nKey="building.offMarket.modal.size" />}
                    </label>
                    <input
                      id="property-size"
                      type="number"
                      className="off-market-modal__input"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                      placeholder={t('building.offMarket.modal.sizePlaceholder')}
                      min="0"
                    />
                  </div>

                  <div className="off-market-modal__form-field off-market-modal__form-field--half">
                    <label htmlFor="property-rooms">
                      <Trans i18nKey="building.offMarket.modal.roomsLabel" />
                    </label>
                    <select
                      id="property-rooms"
                      className="off-market-modal__select"
                      value={rooms}
                      onChange={(e) => setRooms(e.target.value)}
                    >
                      <option value="">
                        {t('building.offMarket.modal.selectRooms')}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5+">5+</option>
                    </select>
                  </div>
                </div>

                <div className="off-market-modal__form-row">
                  <div className="off-market-modal__form-field off-market-modal__form-field--half">
                    <label htmlFor="property-floor">
                      <Trans i18nKey="building.offMarket.modal.floor" />
                    </label>
                    <input
                      id="property-floor"
                      type="number"
                      className="off-market-modal__input"
                      value={floor}
                      onChange={(e) => setFloor(e.target.value)}
                      placeholder={t('building.offMarket.modal.floorPlaceholder')}
                      min="0"
                    />
                  </div>

                  <div className="off-market-modal__form-field off-market-modal__form-field--half">
                    <label htmlFor="property-renovation">
                      <Trans i18nKey="building.offMarket.modal.renovation" />
                    </label>
                    <select
                      id="property-renovation"
                      className="off-market-modal__select"
                      value={renovationStatus}
                      onChange={(e) => setRenovationStatus(e.target.value)}
                    >
                      <option value="">
                        {t('building.offMarket.modal.selectRenovation')}
                      </option>
                      <option value="recently_renovated">
                        {t('building.offMarket.modal.recentlyRenovated')}
                      </option>
                      <option value="good_condition">
                        {t('building.offMarket.modal.goodCondition')}
                      </option>
                      <option value="needs_renovation">
                        {t('building.offMarket.modal.needsRenovation')}
                      </option>
                    </select>
                  </div>
                </div>

                <div className="off-market-modal__form-row off-market-modal__form-row--checkboxes">
                  <div className="off-market-modal__form-field off-market-modal__form-field--checkbox">
                    <input
                      id="property-elevator"
                      type="checkbox"
                      checked={hasElevator}
                      onChange={(e) => setHasElevator(e.target.checked)}
                    />
                    <label htmlFor="property-elevator">
                      <Trans i18nKey="building.offMarket.modal.hasElevator" />
                    </label>
                  </div>

                  <div className="off-market-modal__form-field off-market-modal__form-field--checkbox">
                    <input
                      id="property-balcony"
                      type="checkbox"
                      checked={hasBalcony}
                      onChange={(e) => setHasBalcony(e.target.checked)}
                    />
                    <label htmlFor="property-balcony">
                      <Trans i18nKey="building.offMarket.modal.hasBalcony" />
                    </label>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>

        <div className="off-market-modal__footer">
          <button
            className="off-market-modal__button off-market-modal__button--cancel"
            onClick={
              () => {
                setDescription('');
                setSize('');
                setRooms('');
                setFloor('');
                setHasElevator(false);
                setHasBalcony(false);
                setRenovationStatus('');
                setIsDetailsExpanded(false);
                onClose();
              }
            }
            disabled={isLoading}
          >
            <Trans i18nKey="building.offMarket.modal.cancel" />
          </button>
          <button
            className="off-market-modal__button off-market-modal__button--confirm"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="off-market-modal__button-spinner"></div>
            ) : (
              <Trans i18nKey="building.offMarket.modal.confirm" />
            )}
          </button>
        </div>
      </div>
    </div>,
    document.body // Render directly to the body element
  );
};

export default OffMarketBuyAndSellDetailsModal;