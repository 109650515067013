export const en = {
  "common": {
    "showMore": "Show more",
    "showLess": "Show less"
  },
  "feed": {
    "title": "Feed",
    "button": {
      "showFeed": "Feed",
      "hideFeed": "Hide"
    },
    "likeBuildingEvent": {
      "text": "User liked this building!"
    },
    "offMarketWillingnessEvent": {
      "sell": {
        "text": "Owner willing to sell!"
      },
      "buy": {
        "text": "Interested buyer!"
      }
    }
  },
  "map": {
    "showMap": "Show map",
    "showFeed": "Show feed"
  },
  "navbar": {
    "login": "Login",
    "loggedIn": "Logged in",
    "messages": "Messages"
  },
  "searchInput": {
    "placeholder": "Find Property, Buy, Sell, Explore"
  },
  "mainMenu": {
    "whatIsJaakko": "What is Jaakko.com?",
    "contact": "Contact",
    "termsConditions": "Terms & Conditions",
    "privacyPolicy": "Privacy Policy",
    "cookies": "Cookies"
  },
  "timeAgo": {
    "justNow": "Now",
    "minutes": "{{count}} min",
    "hours": "{{count}} h",
    "days": "{{count}} d"
  },
  "building": {
    "controls": {
      "follow": "Follow",
      "share": "Share",
      "like": "Like"
    },
    "generalInfo": {
      "property": "Property",
      "redemptionClause": {
        "title": "Redemption clause",
        "yes": "Yes",
        "no": "No"
      },
      "plotOwnership": {
        "title": "Plot",
        "owned": "Own",
        "rented": "Rented"
      }
    },
    "apartments": {
      "title": "Flats",
      "units": {
        "count": "{{count}} units",
        "countEmpty": "- units"
      },
      "roomCount": "{{count}} room",
      "roomCount_one": "{{count}} room",
      "roomCount_other": "{{count}} rooms",
      "fourPlusRooms": "4+ rooms"
    },
    "offMarket": {
      "couldSell": "Could sell",
      "couldBuy": "Could buy",
      "count_one": "{{count}} user",
      "count_other": "{{count}} users",
      "infoButton": "What are these",
      "infoModalText": "Could you sell or would you like to buy here? Express your interest with just one click – easier than ever! We connect you with the other party anonymously and with no commitments. Conversations open directly in the Messages section, and your email address is only shared if you choose to send a message or reply to an inquiry. You can remove your interest at any time. <1>Read more...</1>",
      "toast": {
        "couldSellNoBuyers": "Great! When a potential buyer is found, you will receive an email.",
        "couldSellAndBuyers": "Also buyers are interested, you can now discuss directly.",
        "couldBuyNoSellers": "Great! When a potential seller is found, you will receive an email.",
        "couldBuyAndSellers": "Also sellers are interested, you can now discuss directly.",
        "userSellerAndBuyer": "You can not be both a seller and a buyer at the same time."
      },
      "modal": {
        "sell": {
          "title": "Confirm Selling Interest",
          "description": "Great! By confirming your choice, we'll anonymously connect you with interested buyers. You can remove your selling interest at any time."
        },
        "buy": {
          "title": "Confirm Buying Interest",
          "description": "Great! By confirming your choice, we'll anonymously connect you with interested sellers. You can remove your buying interest at any time."
        },
        "cancel": "Cancel",
        "confirm": "Confirm",
        "detailsOptional": "Apartment Details (Optional)",
        "description": "Description",
        "buyingInterestDescriptionPlaceholder": "Add details about the apartments you're interested in...",
        "sellingInterestDescriptionPlaceholder": "Add details about the apartment you could sell...",
        "additionalDetails": "Additional details (Optional)",
        "size": "Size (m²)",
        "sizePlaceholder": "e.g. 65",
        "sizeClass": "Preferred size (m²)",
        "roomsLabel": "Number of rooms",
        "selectRooms": "Select rooms",
        "rooms": "Room",
        "rooms_one": "Room",
        "rooms_other": "Rooms",
        "floor": "Floor",
        "floorPlaceholder": "e.g. 3",
        "renovation": "Renovation status",
        "selectRenovation": "Select status",
        "recentlyRenovated": "Recently renovated",
        "goodCondition": "Good condition",
        "needsRenovation": "Needs renovation",
        "hasElevator": "Has elevator",
        "hasBalcony": "Has balcony/terrace"
      }
    },
    "housingCompanyHoldings": {
      "title": "Housing company holdings",
      "apartments_one": "Flat",
      "apartments_other": "Flats",
      "commercialSpaces_one": "Commercial space",
      "commercialSpaces_other": "Commercial spaces",
      "units": ""
    },
    "jaakko": {
      "sendFeedback": "Please send feedback to"
    },
    "housingCompanyInfo": {
      "title": "Housing company"
    },
    "comments": {
      "title": "Talk",
      "emptyState": "<0>What would you like to know or share about this property?</0><1>Start an anonymous discussion! 🥳</1>",
      "placeholder": "Share your thoughts about this property, add a photo or ask a question.",
      "send": "Send",
      "back": "Back",
      "pickPhoto": "Pick photo",
      "takePhoto": "Take photo",
      "commenterOrderNumberFirst": "First."
    },
    "filesDropBox": {
      "title": "Upload articles of association, financial statement, maintenance needs assessment or other related documents."
    },
    "documentDropzone": {
      "clickToUpload": "<0>Select file</0> <2>or drag and drop</2>",
      "termsAgreement": "By uploading documents, you agree to comply with our <1>terms of service</1>, and acknowledge that you have read and accept Jaakkocom Oy's <3>privacy policy</3>.",
      "buttonText": "Analyze",
      "loading": {
        "title": "Analyzing documents...",
        "text": "This may take a few minutes..."
      }
    },
    "buildingInfoBox": {
      "title": "Housing company"
    },
    "financialsInfoBox": {
      "title": "Financials"
    },
    "renovationsInfoBox": {
      "title": "Renovations"
    },
    "maintenanceNeedsAssessmentPlaceHolderText": "Upload a maintenance needs assessment or PTS plan, to get an analysis",
    "articlesOfAssociationPlaceHolderText": "Upload an articles of association, to get an analysis",
    "financialStatementPlaceHolderText": "Upload a financial statement or budget, to get an analysis",
    "streetView": {
      "title": "Street View"
    }
  },
  "mainMenuPages": {
    "faq": {
      "title": "Frequently asked questions",
      "whatIsJaakko": {
        "title": "What is Jaakko.com?",
        "paragraphs": {
          "first": "Jaakko.com is a new service that connects buyers and sellers to each other easier than ever, without obligations. If you don't find an interesting property on the current services or consider selling without the complicated and time-consuming process, Jaakko.com offers a simple and efficient way for a new kind of property interaction",
          "second": "Users of Jaakko.com can also discuss, share information and photos, and ask questions about any building. Through discussions, you can learn more about different properties and get valuable insights from other users.",
        }
      },
      "sellingAndBuying": {
        "title": "Selling and buying through Jaakko.com",
        "howItWorks": {
          "title": "How it works",
          "bulletPoints": {
            "express": "Select a property from the map and express your interest in buying or selling with just one click.",
            "connect": "When there's mutual interest, you'll be notified via email, and a direct chat will open within Jaakko.com.",
            "anonymous": "Everything is anonymous at first – your email address will only be shared if you choose to respond to the other party's message or initiate contact yourself.",
            "information": "You can start the conversation by sharing what interests you, such as \"Could sell a 2-room apartment\" or \"Looking for a family apartment in the building\".",
            "removeinterest": "You can remove your interest at any time with one click, and you will no longer receive new contact requests.",
            "removechat": "You can delete any conversation at any time, after which you will no longer receive messages from that party."
          },
        },
        "safety": {
          "title": "Please keep safety and good manners in mind:",
          "bulletPoints": {
            "first": "Jaakko.com is designed for connecting people – the service does not participate in actual transactions or verify the identity of the parties involved.",
            "second": "Always verify the other party's identity before making any decisions or payments. This can be done by meeting in person or requesting official documents.",
            "third": "If needed, consult experts such as real estate agents or lawyers to ensure a safe transaction.",
            "fourth": "Messages must not contain offensive, illegal, harassing, or false statements, nor any content that violates laws or good practices.",
            "fifth": "Jaakko.com reserves the right to remove conversations and users who violate the service rules or good manners.",
            "sixth": "By following these guidelines, you help ensure that using the service is safe and enjoyable for everyone."
          }
        },
      },
      "benefits": {
        "title": "What benefits does a Jaakko.com account offer?",
        "paragraphs": {
          "first": "You can browse buildings without a Jaakko.com account, but with a Jaakko.com account you get additional features:",
          "second": "Creating a Jaakko.com account is free and takes only a minute!"
        },
        "bulletPoints": {
          "first": "Express your interest in selling or buying in any building.",
          "second": "Participate in discussions and share experiences.",
          "third": "Request help with documents – Jaakko helps interpret housing company documents.",
          "fourth": "Follow interesting properties and receive notifications of new information.",
          "fifth": "Share and like interesting properties."
        }
      },
      "howToParticipate": {
        "title": "How do I participate in discussions?",
        "paragraph": "Discussing and sharing photos in the service is anonymous, but requires a Jaakko.com account. Discussions should follow good practices, and not mention your own or other people's names or contact information. Also, discussions should not contain offensive, illegal, disruptive, or false content, or any content that violates laws or good practices."
      },
      "documentAnalysis": {
        "title": "Jaakko also provides document analysis – how does that work?",
        "paragraphs": {
          "first": "When you upload documents about a housing company, Jaakko analyzes them and provides a summary of the important things, such as the housing company's finances, ownership structure, and renovation history. The analysis is done in real time and the results are available in a few minutes.",
          "second": "Jaakko's analysis uses generative AI, which allows for cost-effective and unbiased analysis. Although errors may occur, the analysis provides excellent support in the decision-making process. The user is ultimately responsible for verifying important information. Jaakko's goal is to highlight all important factors so that they can be asked for more information."
        }
      },
      "typesOfRealEstate": {
        "title": "What types of properties does Jaakko cover?",
        "paragraph": "The map-based interface allows for exploring buildings, discussions, and the expression of interest in buying and selling anywhere in the world. The document analysis is currently only supported in Finland."
      },
      "payments": {
        "title": "Payments",
        "paragraph": "The basic functions of the service are free of charge, but in the future charges may be made for additional services. The free use of the service does not obligate to future payments."
      },
      "feedback": {
        "title": "How can I give feedback?",
        "paragraph": "All feedback is welcome. You can send feedback to support@jaakko.com. We are constantly developing the service and value your feedback."
      }
    },
    "contactUs": {
      "title": "Contact us",
      "customerSupport": "Customer support:",
      "businessId": "Business ID:",
      "country": "Finland"
    }
  },
  "signupLoginModal": {
    "loginOrSignup": "Login or sign up",
    "continueWithGoogle": "Continue with Google",
    "orContinueWithEmail": "or continue with email",
    "loginOrSignupButton": "Continue",
    "login": "Login",
    "passwordPlaceholder": "Password",
    "loginButton": "Continue",
    "logout": "Logout",
    "logoutButton": "Continue",
    "emailPlaceholder": "Email",
    "infoBox": {
      "title": "Start using Jaakko",
      "bulletPoints": {
        "first": "Put your property on silent sale or easily express your interest in buying with just one click",
        "second": "Participate in discussions about interesting housing companies",
        "third": "Receive emails about events in the buildings you follow"
      }
    },
    "registerText": {
      "first": "By signing up, you create a Jaakko account and agree to follow our ",
      "second": "terms of service",
      "third": ", and acknowledge that you have read and accept Jaakkocom Oy's ",
      "fourth": "privacy policy."
    },
    "passwordRequirements": {
      "length": "Password must be at least 8 characters long.",
      "uppercase": "Password must contain at least one uppercase letter.",
      "lowercase": "Password must contain at least one lowercase letter.",
      "number": "Password must contain at least one number."
    },
    "checkPassword": "Check password.",
    "unexpectedError": "Oops! Something went wrong.",
    "signup": {
      "title": "Sign up",
      "accountExists": "It looks like you already have an account. Please login.",
      "signupButton": "Continue"
    },
    "emailVerification": {
      "title": "Verify your email address",
      "firstParagraph": "Enter the verification code",
      "secondParagraph": "Enter the verification code, which was sent to {{email}} (if you do not see the email, check your spam folder)",
      "codePlaceholder": "Verification code"
    },
    "forgotPassword": {
      "linkText": "Forgot password?",
      "title": "Reset password",
      "subtitle": "Enter your email address and we'll send you a link to reset your password.",
      "submitMessage": "If an account with that email exists, a password reset link has been sent.",
      "sendLinkButton": "Send Reset Link",
      "backToLogin": "Back to Login"
    },
    "resetPasswordPage": {
      "title": "Set a new password",
      "newPasswordPlaceholder": "New password",
      "confirmPasswordPlaceholder": "Confirm new password",
      "submitButton": "Set New Password",
      "passwordMismatch": "Passwords do not match.",
      "successMessage": "Your password has been reset successfully. You can now log in with your new password.",
      "invalidLink": "Invalid or expired password reset link.",
      "tokenMissing": "Password reset token is missing.",
      "goToLogin": "Go to app"
    }
  },
  "hints": {
    "clickBuilding": "Click any building",
    "zoomInToSeeHouseNumbers": "Zoom in to see house numbers",
    "expressInterest": "Express interest in selling or buying",
  },
  "metaTags": {
    "title": "Jaakko.com | Property Map & Silent Sale",
    "description": "Are you looking to sell your property or buy a new one? Whether you're listing your property for a silent sale or searching for your dream home, it's now easier than ever.",
    "ogTitle": "Jaakko.com | Property Map & Silent Sale",
    "ogDescription": "Are you looking to sell your property or buy a new one? Whether you're listing your property for a silent sale or searching for your dream home, it's now easier than ever.",
    "building": {
      "title": "Property {{address}} - Jaakko.com",
      "description": "Explore {{address}}. Share your interest in selling or buying, or participate in discussions.",
      "ogTitle": "Explore interesting property on Jaakko.com",
      "ogDescription": "Explore {{address}}. Share your interest in selling or buying, or participate in discussions."
    }
  },
  "shareButton": {
    "title": "Explore interesting property on Jaakko.com",
    "text": "Explore {{address}}. Share your interest in selling or buying, or participate in discussions.",
    "linkCopied": "Link copied to clipboard.",
    "linkCopiedError": "Link copy failed. Please copy the link manually."
  },
  "messages": {
    "title": "Messages",
    "empty": "When you start a conversation with buyers or sellers, you will find all messages here.",
    "send": "Send",
    "tabs": {
      "inbox": "Inbox",
      "all": "All",
      "support": "Support"
    },
    "firstMessage": "Start of your conversation.",
    "welcomeTitle": "Start of your conversation",
    "welcomeText": "This is the beginning of your conversation with {{ email }}. You can discuss details about the apartment and arrange viewings.",
    "welcomeTextOtherUserHasNotSentMessage": "This is the beginning of your conversation with the other user. You can discuss details about the apartment and arrange viewings.",
    "welcomeTextEmailDisclaimer": "Note that your email address will be shared with the other party if you start a conversation or respond to messages you receive. Likewise, you will receive the other party's email address when you send your first message.",
    "welcomeTips": "Tips for a successful negotiation:",
    "tipPolite": "Be polite and respectful in your communications",
    "tipCaution": "Exercise caution before sharing personal contact information",
    "tipDetails": "You can end the conversation at any time, after which the other person's messages will no longer reach you",
    "writePlaceholder": "Write a message...",
    "chatEndedPlaceholder": "This conversation has ended",
    "chatEndedMessage": "This conversation has been ended. No new messages can be sent.",
    "chatEnded": "Chat ended",
    "endChat": "End chat",
    "endChatConfirmTitle": "End chat",
    "endChatConfirmText": "Are you sure you want to end this conversation? Once ended, neither you nor the other person will be able to send messages in this chat again.",
    "confirm": "End chat",
    "cancel": "Cancel",
    "ending": "Ending",
    "today": "Today",
    "yesterday": "Yesterday",
    "anonymousUser": "Anonymous user"
  }
};
