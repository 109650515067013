import React, { useEffect, useRef, useState } from 'react';

import LoadingExistingReportSkeleton from './LoadingExistingReportSkeleton';
import {
  BuildingWithConvertedAddresses,
  Comment,
} from '../utils/types';
import { fetchJaakkoComment } from './Report.fetchExistingReport';
import BuildingIconAndName from './BuildingIconAndName';
import HeartIcon from './Icons/HeartIcon';
import { getCookie } from '../utils/utils';
import { CSSTransition } from 'react-transition-group';
import ExpanderArrow from './ExpanderArrow';
import PageMetaTags from './PageMetaTags';
import TagManager from 'react-gtm-module';
import { CloseIcon } from '../common/CloseIcon';
import Comments from './Comments/Comments';
import './BuildingModalGlobal.scss';
import LoadingSpinner from '../common/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import StreetView from './StreetView/StreetView';
import OffMarketBuyAndSell from './OffMarketBuyAndSell';

type Props = {
  selectedBuilding: BuildingWithConvertedAddresses;
  isLoggedIn: boolean;
  showImageModal: string | null;
  countryOfFeedContent: string;
  messagesModalVisible: boolean;
  toggleReportVisible: () => void;
  setSelectedBuilding: (
    building: BuildingWithConvertedAddresses | null,
  ) => void;
  setLoginModalVisible: (visible: boolean) => void;
  refreshFeed: () => void;
  setShowImageModal: (imageUrl: string | null) => void;
  setCountryOfFeedContent: (country: string) => void;
  fetchConversations: () => void;
};

type OffMarketToastMessage =
  | 'COULD_SELL_NO_BUYERS'
  | 'COULD_SELL_AND_BUYERS'
  | 'COULD_BUY_NO_SELLERS'
  | 'COULD_BUY_AND_SELLERS'
  | 'USER_SELLER_AND_BUYER';

const BuildingModalGlobal = (props: Props) => {
  const {
    selectedBuilding,
    isLoggedIn,
    showImageModal,
    countryOfFeedContent,
    messagesModalVisible,
    toggleReportVisible,
    setSelectedBuilding,
    setLoginModalVisible,
    refreshFeed,
    setShowImageModal,
    setCountryOfFeedContent,
    fetchConversations,
  } = props;
  const { t, i18n } = useTranslation();
  const [isLoadingBuilding, setIsLoadingBuilding] =
    useState<boolean>(true);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [userHasLiked, setUserHasLiked] = useState<boolean>(false);
  const [emptyReport, setEmptyReport] = useState<any>(null);
  const [isLoadingEmptyReport, setIsLoadingEmptyReport] =
    useState<boolean>(false);
  const [
    notificationsSubscriptionsToggle,
    setNotificationsSubscriptionsToggle,
  ] = useState<boolean>(false);
  const [slideToastIn, setSlideToastIn] =
    useState<OffMarketToastMessage | null>(null);
  const [isSubmittingLike, setIsSubmittingLike] = useState<boolean>(false);
  const reportRef = useRef<HTMLDivElement | null>(null);

  const currentUrl = window.location.href;
  const addressObject =
    selectedBuilding.addresses && selectedBuilding.addresses.length
      ? selectedBuilding.addresses[0]
      : selectedBuilding.properties &&
        selectedBuilding.properties.addresses &&
        selectedBuilding.properties.addresses.length
        ? selectedBuilding.properties.addresses[0]
        : '';
  const addressString =
    typeof addressObject === 'string'
      ? ''
      : `${addressObject.street}${addressObject.houseNumber === 'Unknown'
        ? ''
        : ' ' + addressObject.houseNumber
      }, ${addressObject.city}`;
  const titleAddressString =
    typeof addressObject === 'string'
      ? ''
      : `${addressObject.street}${addressObject.houseNumber === 'Unknown'
        ? ''
        : ' ' + addressObject.houseNumber
      }, ${addressObject.city} `;

  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(true);
  const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);

  const [isStreetViewExpanded, setIsStreetViewExpanded] = useState<boolean>(true);

  const toggleCommentsExpansion = () => {
    setIsCommentsExpanded((prevState) => !prevState);
  };

  const toggleStreetViewExpansion = () => {
    setIsStreetViewExpanded((prevState) => !prevState);
  };

  const likeBuilding = () => {
    setIsSubmittingLike(true);
    fetch(`${process.env.REACT_APP_API_URL}/like-building`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      body: JSON.stringify({
        like: !userHasLiked,
        selected_building: selectedBuilding,
      }),
    })
      .then((res) => res.json())
      .then((data: { like_count: number; user_has_liked: boolean }) => {
        setLikeCount(data.like_count);
        setUserHasLiked(data.user_has_liked);
        refreshFeed();
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsSubmittingLike(false);
      });
  };

  const fetchBuilding = () => {
    setIsLoadingBuilding(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/building?id=${selectedBuilding.id}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        // Check if data and properties are defined
        if (data && data.properties) {
          setLikeCount(data.properties.like_count || 0);
          setUserHasLiked(data.properties.user_has_liked || false);
        } else {
          console.warn('Building data or properties are undefined.');
          setLikeCount(0); // Set default value
          setUserHasLiked(false); // Set default value
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setIsLoadingBuilding(false);
      });
  };

  const fetchComments = async (isVoteRefresh: boolean = false) => {
    if (!isVoteRefresh) {
      setIsLoadingComments(true);
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/comments?building-id=${selectedBuilding.id}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      },
    )
      .then((res) => res.json())
      .then((comments) => {
        // Handle the building data as needed
        if (Array.isArray(comments)) {
          setComments(comments);
        }
        setIsLoadingComments(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoadingComments(false);
      });
  };

  useEffect(() => {
    fetchComments();
    fetchJaakkoComment({
      selectedBuilding,
      setEmptyReport,
      setIsLoadingEmptyReport,
      language: i18n.language,
    });
    fetchBuilding();
    if (selectedBuilding && selectedBuilding.addresses[0].country !== countryOfFeedContent) {
      setCountryOfFeedContent(selectedBuilding.addresses[0].country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuilding]);

  useEffect(() => {
    if (slideToastIn) {
      // Hide the toast component after 5 seconds
      const toastTimer = setTimeout(() => {
        setSlideToastIn(null);
      }, 7000);

      // Cleanup in case the component unmounts during the timeout
      return () => clearTimeout(toastTimer);
    }
  }, [slideToastIn]);

  const handleShareButtonClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t('shareButton.title'),
          text: t('shareButton.text', { address: addressString }),
          url: currentUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      // Use the modern Clipboard API
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          alert(t('shareButton.linkCopied'));
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
          alert(t('shareButton.linkCopiedError'));
        });
    } else {
      // Fallback approach for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = currentUrl;

      // Avoid scrolling to bottom
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          alert(t('shareButton.linkCopied'));
        } else {
          alert(t('shareButton.linkCopiedError'));
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        alert(t('shareButton.linkCopiedError'));
      }

      document.body.removeChild(textArea);
    }
  };

  const handleToggleSubscribeToNotifications = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/toggle-subscribe-to-notifications`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify({
          selected_building: selectedBuilding,
        }),
      },
    )
      .then((res) => res.json())
      .then((data) => {
        setNotificationsSubscriptionsToggle(data.is_subscribed);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetch(
        `${process.env.REACT_APP_API_URL}/is-subscribed?building-id=${selectedBuilding.id}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          },
        },
      )
        .then((res) => res.json())
        .then((data) => {
          setNotificationsSubscriptionsToggle(data.is_subscribed);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [isLoggedIn, selectedBuilding]);

  // Add useEffect to handle browser history and back button
  useEffect(() => {
    // Push state when modal is opened
    const historyState = { modalOpen: true };
    const modalTitle = `Building - ${titleAddressString}`;

    // Add entry to browser history when modal is opened
    window.history.pushState(historyState, modalTitle, window.location.href);

    // Handler for back button (popstate event)
    const handlePopState = () => {
      // Close the modal when back button is pressed
      toggleReportVisible();
      setSelectedBuilding(null);
    };

    // Add event listener for back button
    window.addEventListener('popstate', handlePopState);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [toggleReportVisible, setSelectedBuilding, titleAddressString]);

  return (
    <div
      className="building-modal-global-container"
      key={messagesModalVisible ? 'messages-modal-open' : 'building-modal-open'}
    >
      <PageMetaTags
        title={t('metaTags.building.title', { address: titleAddressString })}
        description={t('metaTags.building.description', { address: addressString })}
        ogTitle={t('metaTags.building.ogTitle')}
        ogDescription={t('metaTags.building.ogDescription', { address: addressString })}
        ogImage={`https://www.jaakko.com/jaakko_logo.png`}
        ogUrl={currentUrl}
      />
      <div
        className="building-modal-global"
        ref={reportRef}
        key={messagesModalVisible ? 'messages-modal-open' : 'building-modal-open'}
      >
        <div className="building-modal-global__close-wrapper">
          <button
            onClick={() => {
              toggleReportVisible();
              setSelectedBuilding(null);
            }}
            className="building-modal-global__close-wrapper__close-button"
          >
            <CloseIcon size={20} />
          </button>
        </div>
        {isLoadingBuilding ? (
          <LoadingExistingReportSkeleton />
        ) : (
          <>
            <div className="building-modal-global__property-wrapper">
              <div className="building-modal-global__controls">
                <div className="building-modal-global__controls__notifications">
                  <label
                    className={`toggle-switch${!isLoggedIn ? ' toggle-switch--disabled' : ''
                      }`}
                  >
                    <input
                      type="checkbox"
                      checked={notificationsSubscriptionsToggle}
                      onChange={() => {
                        TagManager.dataLayer({
                          dataLayer: {
                            event: 'building_subscribed',
                            category: 'User Interaction',
                            action: 'Click',
                            label: 'Building Subscribed',
                            id: selectedBuilding.id,
                            name: selectedBuilding.properties.name,
                          },
                        });
                        if (isLoggedIn) {
                          handleToggleSubscribeToNotifications();
                        } else {
                          setLoginModalVisible(true);
                        }
                      }}
                    />
                    <span className="slider" />
                  </label>
                  <div
                    className="building-modal-global__controls__share-button__share-text"
                    onClick={() => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'building_subscribed',
                          category: 'User Interaction',
                          action: 'Click',
                          label: 'Building Subscribed',
                          id: selectedBuilding.id,
                          name: selectedBuilding.properties.name,
                        },
                      });
                      if (isLoggedIn) {
                        handleToggleSubscribeToNotifications();
                      } else {
                        setLoginModalVisible(true);
                      }
                    }}
                  >
                    <Trans i18nKey="building.controls.follow" />
                  </div>
                </div>
                <button
                  className="building-modal-global__controls__share-button"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'building_shared',
                        category: 'User Interaction',
                        action: 'Click',
                        label: 'Building Shared',
                        id: selectedBuilding.id,
                        name: selectedBuilding.properties.name,
                      },
                    });
                    handleShareButtonClick();
                  }}
                >
                  <div className="report__controls__share-button__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0,0,256,256"
                      width="30px"
                      height="30px"
                      fillRule="nonzero"
                    >
                      <g
                        fill="#b7b7b7"
                        fillRule="nonzero"
                        stroke="none"
                        strokeWidth="1"
                        strokeLinecap="butt"
                        strokeLinejoin="miter"
                        strokeMiterlimit="10"
                        strokeDasharray=""
                        strokeDashoffset="0"
                        fontFamily="none"
                        fontWeight="none"
                        fontSize="none"
                        textAnchor="none"
                        style={{ mixBlendMode: 'normal' }}
                      >
                        <g transform="scale(8.53333,8.53333)">
                          <path d="M23,3c-2.20914,0 -4,1.79086 -4,4c0.00178,0.28117 0.03321,0.56136 0.09375,0.83594l-9.08203,4.54102c-0.75785,-0.87251 -1.85604,-1.3746 -3.01172,-1.37695c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c1.15606,-0.0013 2.25501,-0.5027 3.01367,-1.375l9.07617,4.53906c-0.05923,0.27472 -0.08934,0.55491 -0.08984,0.83594c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4c-1.15606,0.0013 -2.25501,0.5027 -3.01367,1.375l-9.07617,-4.53906c0.05923,-0.27472 0.08934,-0.55491 0.08984,-0.83594c-0.00192,-0.28051 -0.03334,-0.56005 -0.09375,-0.83398l9.08203,-4.54102c0.75821,0.87178 1.85635,1.37313 3.01172,1.375c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="building-modal-global__controls__share-button__share-text">
                    <Trans i18nKey="building.controls.share" />
                  </div>
                </button>
                <button
                  disabled={isSubmittingLike}
                  className="building-modal-global__controls__like-button"
                  onClick={() => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'building_liked',
                        category: 'User Interaction',
                        action: 'Click',
                        label: 'Building Liked',
                        id: selectedBuilding.id,
                        name: selectedBuilding.properties.name,
                      },
                    });
                    if (isLoggedIn) {
                      likeBuilding();
                    } else {
                      setLoginModalVisible(true);
                    }
                  }}
                >
                  <div className="building-modal-global__controls__like-button__icon-and-count">
                    <HeartIcon
                      className="building-modal-global__controls__like-button__icon-and-count__icon"
                      fillColor={userHasLiked ? '#FF5E5E' : '#FF8E8E'}
                    />
                    <div
                      className={`building-modal-global__controls__like-button__icon-and-count__like-count`}
                    >
                      {isSubmittingLike ? (
                        <LoadingSpinner className="building-modal-global__controls__like-button__icon-and-count__loading-spinner" />
                      ) : (
                        likeCount || 0
                      )}
                    </div>
                  </div>
                  <div className="building-modal-global__controls__like-button__like-text">
                    <Trans i18nKey="building.controls.like" />
                  </div>
                </button>
              </div>
              <div className="building-modal-global__horizontal-line report__horizontal-line--controls" />
              <div className="building-modal-global__property building-modal-global__property--global">
                <BuildingIconAndName
                  selectedBuilding={selectedBuilding}
                  isGlobal
                />
              </div>
            </div>
            <div className="building-modal-global__horizontal-line" />
            <div className="building-modal-global__owned-apartments-and-commercial-spaces-wrapper">
              <div className="building-modal-global__quantitative_content">
                <div className="building-modal-global__quantitative_content__apartment-counts-and-off-market-buttons">
                  <OffMarketBuyAndSell
                    isGlobal
                    selectedBuilding={selectedBuilding}
                    isLoggedIn={isLoggedIn}
                    setNotificationsSubscriptionsToggle={
                      setNotificationsSubscriptionsToggle
                    }
                    setLoginModalVisible={setLoginModalVisible}
                    refreshFeed={refreshFeed}
                    fetchComments={fetchComments}
                    setSlideToastIn={setSlideToastIn}
                    fetchConversations={fetchConversations}
                  />
                </div>
              </div>
            </div>
            <div className="building-modal-global__horizontal-line building-modal-global__horizontal-line--buy-and-sell" />
            <div className="building-modal-global__jaakko-wrapper">
              <div className="building-modal-global__jaakko-head">
                <img src="/assets/jaakko_head.png" alt="Jaakko" height="64px" />
              </div>
              <div className="building-modal-global__jaakko-text-wrapper">
                <div className="building-modal-global__content__title">Jaakko</div>
                <div className="building-modal-global__jaakko-talking">
                  {isLoadingEmptyReport ? (
                    <span className="jaakko-talking-loading-dots">
                      <span></span>
                    </span>
                  ) : emptyReport && emptyReport.analyysi ? (
                    <>
                      <div className="building-modal-global__jaakko-talking__text__first">
                        {`${emptyReport.analyysi}`}
                        <br />
                        <br />
                        <Trans
                          i18nKey="building.jaakko.sendFeedback"
                        />
                        :{' '}
                        <a
                          className="building-modal-global__jaakko-talking__link"
                          href="mailto:support@jaakko.com"
                        >
                          support@jaakko.com
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="building-modal-global__jaakko-talking__text__first">
                        Alla taloyhtiön dokumentteihin perustuvia havaintojani
                        taloyhtiöstä. Mikäli tiedot ovat vajaat, voit ladata
                        lisää dokumentteja yllä.
                      </div>
                      <div className="building-modal-global__jaakko-talking__text">
                        Olen beta-vaiheessa, joten tietoni ovat vielä rajalliset
                        ja saatan tehdä virheitä. Tarkistathan siis tärkeät
                        asiat aina vielä erikseen.
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="building-modal-global__horizontal-line" />
            <div className="building-modal-global__content__section__expander">
              <button
                onClick={toggleStreetViewExpansion}
                className="building-modal-global__content__section__expander__button"
              >
                <Trans
                  i18nKey="building.streetView.title"
                />
                <ExpanderArrow isExpanded={isStreetViewExpanded} />
              </button>
            </div>
            <CSSTransition
              in={isStreetViewExpanded}
              timeout={300}
              classNames="dropzone"
              unmountOnExit
            >
              <StreetView selectedBuilding={selectedBuilding} />
            </CSSTransition>
            <div className="building-modal-global__horizontal-line" />
            <div className="building-modal-global__content__section__expander">
              <button
                onClick={toggleCommentsExpansion}
                className="building-modal-global__content__section__expander__button"
              >
                <Trans
                  i18nKey="building.comments.title"
                />
                <ExpanderArrow isExpanded={isCommentsExpanded} />
              </button>
            </div>
            <CSSTransition
              in={isCommentsExpanded}
              timeout={300}
              classNames="dropzone"
              unmountOnExit
            >
              <Comments
                selectedBuilding={selectedBuilding}
                isLoggedIn={isLoggedIn}
                showImageModal={showImageModal}
                setLoginModalVisible={setLoginModalVisible}
                refreshFeed={refreshFeed}
                setShowImageModal={setShowImageModal}
                isLoadingComments={isLoadingComments}
                comments={comments}
                setComments={setComments}
                fetchComments={fetchComments}
                setNotificationsSubscriptionsToggle={
                  setNotificationsSubscriptionsToggle
                }
              />
            </CSSTransition>
          </>
        )}
      </div>
      <div
        className={`building-modal-global__off-market-toast-component ${slideToastIn ? 'building-modal-global__off-market-toast-component--slide-in' : ''
          }`}
      >
        <div className="building-modal-global__off-market-toast-component__text">
          <div className="building-modal-global__off-market-toast-component__text__content">
            <div className="building-modal-global__off-market-toast-component__text__description">
              {slideToastIn === 'COULD_SELL_NO_BUYERS' &&
                <Trans i18nKey="building.offMarket.toast.couldSellNoBuyers" />}
              {slideToastIn === 'COULD_SELL_AND_BUYERS' &&
                <Trans i18nKey="building.offMarket.toast.couldSellAndBuyers" />}
              {slideToastIn === 'COULD_BUY_NO_SELLERS' &&
                <Trans i18nKey="building.offMarket.toast.couldBuyNoSellers" />}
              {slideToastIn === 'COULD_BUY_AND_SELLERS' &&
                <Trans i18nKey="building.offMarket.toast.couldBuyAndSellers" />}
              {slideToastIn === 'USER_SELLER_AND_BUYER' &&
                <Trans i18nKey="building.offMarket.toast.userSellerAndBuyer" />}
            </div>
          </div>
        </div>
        <button
          className="building-modal-global__off-market-toast-component__close-button"
          onClick={() => setSlideToastIn(null)}
        >
          <CloseIcon size={24} color="#fff" />
        </button>
      </div>
    </div>
  );
};

export default BuildingModalGlobal;
