import React from 'react';
import './NavbarSignupLogin.scss';
import UserIcon from './UserIcon';
import { Trans } from 'react-i18next';

type NavbarSignupLoginProps = {
  toggleLoginModal: () => void;
  isLoggedIn: boolean;
};

const NavbarSignupLogin = ({
  toggleLoginModal,
  isLoggedIn,
}: NavbarSignupLoginProps) => (
  <div className="navbar-signup-login-wrapper"
    onClick={toggleLoginModal}
  >
    <div className="navbar-signup-login" >
      <UserIcon />
      <div className={`navbar-signup-login__text`}>
        {isLoggedIn ? (
          <span
            className="navbar-signup-login__text--logged-in"
          >
            <Trans i18nKey="navbar.loggedIn">
              Logged in
            </Trans>
          </span>
        ) : (
          <span>
            <Trans i18nKey="navbar.login">
              Login
            </Trans>
          </span>
        )}
      </div>
    </div>
  </div>
);

export default NavbarSignupLogin;
